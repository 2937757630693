import type { NitroFetchOptions } from 'nitropack'

const legislations = ['eidas', 'zertes'] as const
const identTypes = ['video', 'self'] as const

const identificationRepository = (fetch: CustomFetch) => {
  const repository = {
    async getCoverageInfo(srId: string) {
      const { covered: isCovered } = await fetch<{ covered: boolean }>(`/v1/direct/user/identification/payment/${srId}`)

      return { isCovered }
    },
  }

  for (const legislation of legislations) {
    for (const identType of identTypes) {
      const baseName = `${capitalize(legislation)}${capitalize(identType)}Ident`
      const url = getIdentPath(legislation, identType, true)

      repository[`create${baseName}`] = (srid?: string): Promise<{ identUrl: string }> => {
        const options: Pick<NitroFetchOptions<string>, 'method' | 'body'> = { method: 'POST' }
        if (srid) options.body = objectToSnakeCase({ signatureRequestId: srid })

        return fetch(url, options)
      }

      repository[`get${baseName}Info`] = () => fetch(url)

      repository[`delete${baseName}`] = (email: string) => fetch(url, { method: 'DELETE', body: { email } })
    }
  }

  return repository as IdentificationRepository
}

export default identificationRepository
