<!--
  component is used by legacy businesses:
  - old-world sme businesses
  - legacy trial

  this component will eventually be removed
  when all businesses are migrated to the new
  pricing system
 -->

<template>
  <div class="business-billing">
    <div class="mb-10">
      <skr-heading level="1">{{ $t('business.billing_autoinvoicing.title') }}</skr-heading>
      <skr-heading level="2">{{ $t('business.billing_autoinvoicing.subtitle') }}</skr-heading>
    </div>
    <v-row>
      <v-col
        v-if="showDueCards"
        class="v-col-12"
        :class="{ 'pr-4 v-col-lg-6': $vuetify.display.lgAndUp, 'pb-4': $vuetify.display.mdAndDown }"
      >
        <v-skeleton-loader v-if="!invoiceAmount" height="170" class="bubi__card" type="article, actions" />
        <card v-else class="bubi__card">
          <div class="mb-2">
            <strong>{{ invoiceAmountHeader }}</strong>
          </div>
          <div class="mb-2 | bubi__amount">
            {{ invoiceAmount }}
          </div>
          <p class="bubi__subline">
            {{ dynamicTranslations.vat[isVatIncl ? 'incl' : 'excl'] }}
          </p>
        </card>
      </v-col>
      <v-col
        v-if="showDueCards"
        class="v-col-12"
        :class="{ 'pr-4 v-col-lg-6': $vuetify.display.lgAndUp, 'pb-4': $vuetify.display.mdAndDown }"
        order-xs2
        order-lg1
      >
        <v-skeleton-loader v-if="!dueDate" class="bubi__card" height="170" type="article, actions" />
        <card v-else class="bubi__card">
          <div class="mb-2">
            <strong>{{ dueDateHeader }}</strong>
          </div>
          <div class="mb-2 | bubi__amount">
            {{ dueDate }}
          </div>
        </card>
      </v-col>
    </v-row>

    <div class="mt-6 mb-2 mx-6 font-weight-bold text-skribbleu">
      {{
        isBusinessInTrial
          ? $t('business.billing.trialing.free_signatures')
          : $t('business.billing_autoinvoicing.signatures')
      }}
    </div>
    <v-skeleton-loader
      v-if="typeof remainingSignatures === 'undefined'"
      class="bubi__card"
      height="70"
      type="article"
    />
    <card v-else>
      <v-row :class="{ 'd-flex flex-wrap': $vuetify.display.xs }" class="align-center">
        <v-col :class="{ 'mr-5': $vuetify.display.smAndUp }">
          <strong>
            {{ $t('business.billing_autoinvoicing.signatures_left') }}
          </strong>
        </v-col>
        <v-col v-if="!isNaN(percentage)" :class="{ 'v-col-12': $vuetify.display.xs }">
          <v-progress-linear height="4" bg-color="var(--black)" color="success" :model-value="percentage" />
        </v-col>
        <v-col :class="{ 'v-col-12': $vuetify.display.xs, 'ml-5 text-right': $vuetify.display.smAndUp }">
          <p>
            <span class="remaining-signatures">{{ remainingSignatures }}</span>
            {{
              $t('business.billing_autoinvoicing.x_of_y_signatures', {
                0: '',
                1: totalSignatures,
              })
            }}
          </p>
        </v-col>
      </v-row>
    </card>

    <div class="mt-6 mb-2 mx-6 font-weight-bold text-skribbleu">
      {{ $t('business.billing_autoinvoicing.plan.title') }}
    </div>
    <v-skeleton-loader v-if="!subscriptionPanelContent.title" class="bubi__card" height="70" type="article" />
    <v-expansion-panels v-else v-model="subscriptionPanel">
      <v-expansion-panel>
        <v-expansion-panel-title>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12" sm="3">
                <strong class="pr-4">
                  {{ $t('business.billing_autoinvoicing.skribble_business') }}
                </strong>
              </v-col>
              <v-col cols="12" sm="9">
                <div class="d-flex flex-wrap align-center justify-space-between">
                  <div class="pr-2">
                    {{ subscriptionPlan }}
                  </div>
                  <div v-if="defaultPaymentMethod && !isBusinessInTrial" class="pr-2">
                    {{ invoiceAmount }}
                  </div>
                  <div v-if="subscriptionCancelledAtPeriodEnd" class="cancelation-message text-red">
                    {{
                      $t('business.billing_autoinvoicing.cancellation_planned', {
                        0: dueDate,
                      })
                    }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <template #actions>
            <div class="v-ep__svg-wrapper">
              <v-icon :class="{ show: subscriptionPanel === 0 }">custom:clear</v-icon>
              <v-icon :class="{ show: subscriptionPanel !== 0 }">custom:pencil</v-icon>
            </div>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-form @submit.prevent>
            <div class="d-flex flex-column justify-space-between">
              <p class="whitespace-pre-wrap">
                <strong>{{ subscriptionPanelContent.title }}</strong>
              </p>
              <!-- p block can get deleted 30 days after the i-upgrade-directly release -->
              <span>
                {{ subscriptionPanelContent.text }}
                <span>{{ subscriptionPanelContent.prompt }}</span>
              </span>
              <div class="d-flex align-center justify-space-between">
                <v-btn class="mt-4" variant="outlined" size="x-large" color="danger" @click="subscriptionPanelAction()">
                  {{ subscriptionPanelContent.action }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog v-model="showCancelTrialDialog" max-width="640">
      <v-card>
        <v-card-title>
          {{ $t('business.billing_autoinvoicing.cancel_trial_dialog.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :class="{ 'v-col-6': $vuetify.display.smAndUp, 'v-col-12': $vuetify.display.xs }">
              <p>
                {{ $t('business.billing_autoinvoicing.cancel_trial_dialog.description') }}
              </p>
              <skr-checkbox
                v-model="confirmCancelTrial"
                :label="$t('business.billing_autoinvoicing.cancel_subscription_dialog.checkbox')"
                color="error"
              />
            </v-col>
            <v-col v-if="$vuetify.display.smAndUp" class="v-col-6">
              <responsive-image source="delete-skribble" :width="565" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            size="x-large"
            :disabled="loading"
            color="info"
            variant="outlined"
            @click="
              () => {
                showCancelTrialDialog = false
                confirmCancelTrial = false
              }
            "
          >
            {{ $t('business.billing_autoinvoicing.cancel_dialogs.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            size="x-large"
            color="error"
            variant="elevated"
            :loading="loading"
            :disabled="!confirmCancelTrial || loading"
            @click="cancelTrial()"
          >
            {{ $t('business.billing_autoinvoicing.cancel_dialogs_trial.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCancelSubscriptionDialog" max-width="640">
      <v-card>
        <v-card-title>
          {{ $t('business.billing_autoinvoicing.cancel_subscription_dialog.title') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :class="{ 'v-col-6': $vuetify.display.smAndUp, 'v-col-12': $vuetify.display.xs }">
              <p>
                {{ $t('business.billing_autoinvoicing.cancel_subscription_dialog.description') }}
              </p>
              <p>
                <strong>{{
                  $t('business.billing_autoinvoicing.cancel_subscription_dialog.hint_revoke', { 0: dueDate })
                }}</strong>
              </p>
              <skr-checkbox
                v-model="confirmCancelSubscription"
                :label="$t('business.billing_autoinvoicing.cancel_subscription_dialog.checkbox')"
                color="error"
              />
            </v-col>
            <v-col v-if="$vuetify.display.smAndUp" class="v-col-6">
              <responsive-image source="delete-skribble" :width="565" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            size="x-large"
            :disabled="loading"
            color="info"
            variant="outlined"
            @click="
              () => {
                showCancelSubscriptionDialog = false
                confirmCancelSubscription = false
              }
            "
          >
            {{ $t('business.billing_autoinvoicing.cancel_dialogs.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            size="x-large"
            color="error"
            variant="elevated"
            :loading="loading"
            :disabled="!confirmCancelSubscription"
            @click="cancelSubscription()"
          >
            {{ $t('business.billing_autoinvoicing.cancel_dialogs_subscr.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import type { UnwrapNestedRefs } from 'vue'
import type { LocaleMessages, TranslateResult } from 'vue-i18n'

import Card from '@/components/Card.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'
import SkrHeading from '@/components/SkrHeading.vue'

interface PanelContent {
  title: TranslateResult
  prompt: TranslateResult
  action: TranslateResult
  text?: TranslateResult
}

export default defineComponent({
  components: {
    SkrCheckbox,
    SkrHeading,
    Card,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
      admin: {
        subNavType: 'business-billing',
      },
      accessControl: {
        roles: ['admin'],
      },
    })

    const { formatDate } = useDate()

    const userStore = useUserStore()
    const widgetStore = useWidgetStore()
    const legacyBiz = useLegacyBusinessStore()

    const updateData = async () => {
      await userStore.loadUserBusinessData()
      await userStore.fetchUser()
    }

    // For backwards compatibility, we fake the old business data structure
    const businessStore = useBusinessStore()
    const businessData = businessStore as unknown as UnwrapNestedRefs<Camelized<BusinessState>>

    const billing = computed(() => businessData.billing)

    const trialEndDisplay = computed(() => {
      if (!businessData.trialEnd) return ''
      return formatDate(new Date(businessData.trialEnd), 'd LLLL yyyy')
    })

    // This does not seem to exist anymore, but we try to keep everything as it was before
    const defaultPaymentMethod = computed(() => legacyBiz.defaultPaymentMethod)

    const getBilling = () => legacyBiz.getBilling(businessStore.id)

    onMounted(() => {
      void getBilling()
    })

    return {
      billing,
      isBusinessInTrial: businessStore.isTrialing,
      trialEndDisplay,
      defaultPaymentMethod,
      updateData,
      widgetStore,
      cancelBusinessTrial: businessStore.cancelTrial,
      getBilling,
      cancel: () => legacyBiz.cancelBizSubscription(businessStore.id),
      reactivate: () => legacyBiz.reactivateBizSubscription(businessStore.id),
    }
  },
  data() {
    return {
      loading: false,
      filters: {
        date: {
          menuOpen: false,
          value: '',
        },
      },
      subscriptionPanel: undefined,
      showCancelTrialDialog: false,
      confirmCancelTrial: false,
      showCancelSubscriptionDialog: false,
      confirmCancelSubscription: false,
    }
  },
  head() {
    return {
      title: 'Business - Billing',
    }
  },
  computed: {
    dynamicTranslations(): LocaleMessages {
      return {
        cycle: {
          monthly: this.$t('business.billing_autoinvoicing.plan.monthly'),
          title: this.$t('business.billing_autoinvoicing.plan.title'),
          yearly: this.$t('business.billing_autoinvoicing.plan.yearly'),
        },
        vat: {
          incl: this.$t('business.billing_autoinvoicing.invoice_amount_trial_subtext_incl_vat'),
          excl: this.$t('business.billing_autoinvoicing.invoice_amount_trial_subtext_excl_vat'),
        },
      }
    },
    subscriptionCancelledAtPeriodEnd(): boolean {
      return Boolean(this.billing?.cancelAtPeriodEnd && !this.loading)
    },
    isVatIncl(): boolean {
      return this.billing.taxExempt === 'none'
    },
    invoiceAmountHeader(): TranslateResult {
      if (this.isBusinessInTrial) return this.$t('business.billing_autoinvoicing.next_invoice_amount')
      return this.$t('business.billing_autoinvoicing.next_invoice_amount')
    },
    invoiceAmount(): string {
      const billing = this.billing as BusinessBilling | undefined
      const amountDue = billing?.next_invoice?.amount_due
      if (amountDue === null || amountDue === undefined) return ''
      const locale = this.$i18n.locale
      const amount = amountDue && amountDue / 100
      const currency = billing?.next_invoice.currency
      const localisedAmount = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      }).format(amount)
      return localisedAmount
    },
    dueDateHeader(): TranslateResult {
      if (this.subscriptionCancelledAtPeriodEnd) return this.$t('business.billing_autoinvoicing.end_date')
      return this.$t('business.billing_autoinvoicing.due_date')
    },
    dueDate(): string {
      if (!this.billing?.nextInvoice?.period_end) return ''
      const locale = this.$i18n.locale
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }

      const dueDate = new Date(this.billing.nextInvoice.period_end * 1000).toLocaleDateString(locale, options)

      return dueDate
    },
    remainingSignatures(): number {
      return this.billing?.includedSignatures?.remaining ?? 0
    },
    totalSignatures(): number {
      return this.billing?.includedSignatures?.total ?? 0
    },
    percentage(): number {
      return (this.remainingSignatures / this.totalSignatures) * 100
    },
    subscriptionPlan(): TranslateResult {
      if (this.isBusinessInTrial) return this.$t('global.free_30_days_trial')
      const cycle = (this.billing as BusinessBilling | undefined)?.price_plan?.billing_cycle
      const cycleText = cycle ? String(this.dynamicTranslations.cycle[cycle]) : ''
      return cycleText
    },
    subscriptionPanelContent(): PanelContent {
      // Case: In trial and NO payment method has been provided. This is the "normal" trial case.
      if (this.isBusinessInTrial && !this.defaultPaymentMethod)
        return {
          title: this.$t('business.free_trial.runs_until', {
            dueDate: this.trialEndDisplay,
          }),
          prompt: this.$t('business.billing.delete_skribble_business_no_pm_prompt'),
          action: this.$t('business.billing.subscription_panel.cancel_trial_action'),
        }
      // Case: business cancelled
      else if (this.subscriptionCancelledAtPeriodEnd)
        return {
          title: this.$t('business.billing_autoinvoicing.subscription_panel.revoke_deletion_title', {
            0: this.dueDate,
          }),
          prompt: this.$t('business.billing_autoinvoicing.subscription_panel.revoke_deletion_prompt', {
            0: this.dueDate,
          }),
          action: this.$t('business.billing_autoinvoicing.subscription_panel.revoke_deletion_action'),
        }
      // Case: After trial.
      else
        return {
          title: this.$t('business.billing_autoinvoicing.subscription_panel.cancel_plan_title', { 0: this.dueDate }),
          text: this.$t('business.billing_autoinvoicing.subscription_panel.cancel_plan_text', { 0: this.dueDate }),
          prompt: this.$t('business.billing_autoinvoicing.subscription_panel.cancel_plan_prompt', { 0: this.dueDate }),
          action: this.$t('business.billing_autoinvoicing.subscription_panel.cancel_plan_action'),
        }
    },
    showDueCards(): boolean {
      return !this.isBusinessInTrial || !!this.defaultPaymentMethod
    },
  },
  methods: {
    async cancelTrial(): Promise<void> {
      this.loading = true
      this.subscriptionPanel = undefined
      try {
        this.loading = true
        await this.cancelBusinessTrial()
        await this.updateData()
        await navigateTo({ name: 'index' })
        this.widgetStore.setDialog('business/DialogTransactionalCsat', {})
      } catch (error) {
        throw new Error(`Cancel business trial failed:  ${error}`)
      } finally {
        this.loading = false
      }
    },
    async cancelSubscription(): Promise<void> {
      this.loading = true
      try {
        await this.cancel()
        await this.getBilling()

        this.subscriptionPanel = undefined
        this.showCancelSubscriptionDialog = false
        this.confirmCancelSubscription = false
        this.loading = false
        this.widgetStore.createSnackbar({
          message: this.$t('business.billing_autoinvoicing.snackbar.subscription_cancellation_confirmation'),
        })
      } catch {
        this.loading = false
      }
    },
    async reactivateSubscription(): Promise<void> {
      this.loading = true
      this.subscriptionPanel = undefined
      try {
        await this.reactivate()
        await this.getBilling()
        this.loading = false
        this.widgetStore.createSnackbar({
          message: this.$t('business.billing_autoinvoicing.snackbar.subscription_reactivation_confirmation'),
        })
      } catch {
        this.loading = false
      }
    },
    subscriptionPanelAction(): void {
      if (this.isBusinessInTrial) this.showCancelTrialDialog = true
      else if (this.subscriptionCancelledAtPeriodEnd) void this.reactivateSubscription()
      else this.showCancelSubscriptionDialog = true
    },
  },
})
</script>

<style lang="sass">
.business-billing
  .spaced-panel-header
    display: flex
    justify-content: space-between
    flex-wrap: wrap

    .cancelation-message
      width: 100%

  .overlined
    padding-top: 16px
    border-top: 1px solid $c-border

  .v-progress-linear
    border-radius: 4px

  .remaining-signatures
    font-weight: bold
    color: $c-success

.bubi
  &__card
    +miw(lg)
      height: 100%

    .card__body
      +miw(lg)
        height: 100%

  &__amount
    font-size: 2.125rem
    font-weight: bold
    color: $c-skribbleu

  &__subline
    font-size: 0.875rem
</style>
