/*
 * checks if the user is member of a business
 * otherwise redirects all /business/* paths to /business (registration steps)
 */

export default defineNuxtRouteMiddleware(to => {
  const {
    $i18n: { t },
  } = useNuxtApp()

  // routes available if user is NOT member of business
  const routesForNonBusinessMembers = [
    'business', // This will now be redirected by default
    'business-register',
    'business-signup',
    'business-signup-mail-sent',
    'business-signup-steps',
  ]

  // As the base route component was removed, we need to check the corresponding path instead
  if (/\/business\/?$/.test(to.path)) {
    // Set the appropriate name to not mess up existing logic
    to.name = 'business'
  }

  // The `/business/members/activity` page was removed because it was a duplicate of
  // `business/activity`, so we navigateTo there to be safe
  if (to.path === '/business/members/activity') {
    return navigateTo('/business/activity')
  }

  const userStore = useUserStore()
  const widgetStore = useWidgetStore()

  // if route is part of business routes
  if (to.name && to.name.startsWith('business')) {
    // if user IS business admin
    if (userStore.isBusinessAdmin) {
      // if route is for NON business members send to profile
      if (routesForNonBusinessMembers.includes(to.name)) {
        return navigateTo('/business/profile')
      }
    } else if (userStore.isBusinessMember) {
      // if user IS a member, but NOT admin redirect back to regular skribble
      widgetStore.createSnackbar({
        message: t('business.not_allowed'),
      })
      return navigateTo('/')
    } else if (!routesForNonBusinessMembers.includes(to.name)) {
      // if user is NOT business member and route is NOT available for non business members send to biz registration screen
      return navigateTo('/business/register')
    } else if (to.name === 'business') {
      // For regular users, redirect directly to the registration screen
      return navigateTo('/business/register')
    }
  }
})
