<template>
  <div class="mb-10">
    <skr-heading level="1">{{ $t('signature_standards.title') }}</skr-heading>
    <skr-heading level="2">{{ $t('signature_standards.text') }}</skr-heading>
  </div>

  <profile-status-bar />

  <!-- user need to have a verified email address
  and may not be in demo mode -->
  <template v-if="user.emailVerified && user.highestSignatureQuality !== 'demo'">
    <div class="mt-6 mb-2 mx-6 font-weight-bold text-skribbleu" data-cy="standardsListTitle">
      {{ $t('signature_standards.list.title') }}
    </div>
    <v-expansion-panels v-model="panelQuality" class="sist__expansion">
      <v-expansion-panel
        v-for="(_, quality, index) in signatureStandardsData"
        :key="`q${quality}`"
        :data-cy="`standardsPanelContent${quality.toUpperCase()}`"
      >
        <v-expansion-panel-title>
          <v-container class="pa-0">
            <v-row align="center">
              <v-col cols="12" sm="3" class="pr-4 d-flex align-center">
                <nuxt-img
                  width="34"
                  height="34"
                  :src="`/img/badge-${quality}-${lang}.png`"
                  :class="[
                    'mr-4',
                    {
                      'ongoing-ident': ongoingIdentification && quality === 'qes',
                    },
                  ]"
                />
                <strong
                  :class="{
                    'ongoing-ident': ongoingIdentification && quality === 'qes',
                  }"
                >
                  {{ dynamicTranslations.signatureStandardsLabel[quality] }}
                </strong>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                align-self="center"
                :class="[
                  'pl-15',
                  {
                    'ongoing-ident': ongoingIdentification && quality === 'qes',
                    'pt-0': $vuetify.display.xs,
                  },
                ]"
              >
                <span>
                  {{ dynamicTranslations.signatureStandardsText[quality] }}
                </span>
                <!-- we show the legislation in case of qes -->
                <template v-if="quality === 'qes'">
                  {{ legislation }}
                </template>
              </v-col>
            </v-row>
          </v-container>
          <template #actions>
            <div class="v-ep__svg-wrapper">
              <v-icon :class="{ show: panelQuality === index }">custom:clear</v-icon>
              <v-icon :class="{ show: panelQuality !== index }">custom:keyboard_arrow_down</v-icon>
            </div>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-container class="pa-0 pl-12">
            <v-row
              v-for="(value, key) in signatureStandardsData?.[quality]"
              :key="`${quality}${key}`"
              class="sist-sq__row"
              align="center"
            >
              <v-col cols="10" :class="{ 'pb-0': $vuetify.display.xs }">
                <v-row
                  :class="{
                    'flex-column': $vuetify.display.xs,
                    'align-center': $vuetify.display.smAndUp,
                  }"
                >
                  <v-col
                    :class="{
                      'pb-0': $vuetify.display.xs,
                      'col-5': $vuetify.display.smAndUp,
                    }"
                  >
                    <div class="font-weight-bold">
                      {{ dynamicTranslations.signatureStandardsKey[key] }}
                    </div>
                  </v-col>
                  <v-col
                    :class="{
                      'pt-0': $vuetify.display.xs,
                      'col-7': $vuetify.display.smAndUp,
                    }"
                  >
                    {{ value }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" :class="$vuetify.display.xs ? 'py-0' : 'pa-2'">
                <v-tooltip v-if="key === 'mobile'" location="top" content-class="bg-info">
                  <template #activator="{ props }">
                    <v-btn
                      variant="text"
                      icon
                      color="primary"
                      :ripple="false"
                      :to="{
                        name: 'profile-signature-standards-update',
                      }"
                      class="float-right"
                      data-cy="standardsEditButton"
                      v-bind="props"
                    >
                      <v-icon color="text">custom:pencil</v-icon>
                    </v-btn>
                  </template>
                  {{ dynamicTranslations.signatureStandardsTooltip[key] }}
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>

          <!-- QES Panel Content if there is an ongoing VideoIdent process -->
          <div v-if="ongoingIdentification && quality === 'qes'" :class="{ 'pl-12': $vuetify.display.smAndUp }">
            <ongoing-video-ident-panel v-if="ongoingVideoIdent" />
            <ongoing-self-ident-panel v-else-if="eidasOngoingSelfIdent" />
            <ongoing-auto-ident-panel v-else-if="zertesOngoingAutoIdent" />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- show increase-legal-weight CTA in case user does not have QES
          and hasn't started a videoident -->
    <card v-if="user.highestSignatureQuality !== 'qes' && !ongoingIdentification" class="mt-8">
      <v-row align="center">
        <v-col cols="12" md="8">
          <strong class="d-block">{{ $t('signature_standards.increase.help.title') }}</strong>
          <p v-if="user.highestSignatureQuality === 'aes'">
            {{ $t('signature_standards.increase.help.text_for_aes') }}
          </p>
          <p v-else>
            {{ $t('signature_standards.increase.help.text') }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <skr-button
            data-cy="increase_standard_button"
            size="xl"
            block
            :to="{ name: 'profile-signature-standards-update' }"
          >
            {{ $t('signature_standards.increase.help.start') }}
          </skr-button>
        </v-col>
      </v-row>
    </card>
  </template>
  <template v-else-if="user.highestSignatureQuality !== 'demo'">
    <!-- this user needs to verify their email first -->
    <card data-cy="standardsVerifyEmailCard">
      <v-row align="center">
        <v-col cols="12" md="8">
          <strong class="d-block">{{ $t('signature_standards.unverified_mail.help.title') }}</strong>
          <p>
            {{ $t('signature_standards.unverified_mail.help.text') }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <skr-button size="xl" :to="{ name: 'profile' }" block>
            {{ $t('signature_standards.unverified_mail.help.cta') }}
          </skr-button>
        </v-col>
      </v-row>
    </card>
  </template>
  <i18n-t class="ma-6" keypath="signature_standards.increase.help.smallprint.text" tag="p">
    <template #link_text>
      <!--
        Important: Make sure that there is no newline between the key and
        the closing anchor tag. The newline would be interpreted as a whitespace
        by the browser. This would be visible as a space inside the link.
      -->
      <a :href="String($t('signature_standards.increase.help.smallprint.link_href'))">{{
        $t('signature_standards.increase.help.smallprint.link_text')
      }}</a>
    </template>
  </i18n-t>
</template>

<script lang="ts">
import type { CountryCode } from 'libphonenumber-js/min'
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'

import SkrButton from '@/components/button/SkrButton.vue'
import Card from '@/components/Card.vue'
import OngoingAutoIdentPanel from '@/components/OngoingAutoIdentPanel.vue'
import OngoingSelfIdentPanel from '@/components/OngoingSelfIdentPanel.vue'
import OngoingVideoIdentPanel from '@/components/OngoingVideoIdentPanel.vue'
import ProfileStatusBar from '@/components/ProfileStatusBar.vue'
import SkrHeading from '@/components/SkrHeading.vue'

const SKRIBBLE = 'Skribble'
const DEMO = 'Demo'
const SWISSCOM = 'Swisscom'
const GLOBALSIGN = 'GlobalSign'
const ATRUST = 'A-Trust'

export default defineComponent({
  components: {
    Card,
    ProfileStatusBar,
    OngoingVideoIdentPanel,
    OngoingSelfIdentPanel,
    OngoingAutoIdentPanel,
    SkrButton,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'main',
      main: {
        subNavType: 'profile',
      },
      accessControl: {
        roles: ['user'],
      },
    })

    const businessStore = useBusinessStore()
    const { language } = storeToRefs(useLanguageStore())
    const userStore = useUserStore()
    const identStore = useIdentStore()

    const {
      eidasOngoingIdent,
      eidasOngoingVideoIdent,
      eidasOngoingSelfIdent,
      zertesOngoingIdent,
      zertesOngoingVideoIdent,
      zertesOngoingAutoIdent,
    } = storeToRefs(identStore)

    const businessName = computed(() => businessStore.name)

    const getIdentInfo = async () => {
      await Promise.all([identStore.eidasGetAllIdentInfo(), identStore.zertesGetAllIdentInfo()])
    }

    onBeforeMount(() => {
      void getIdentInfo()
    })

    return {
      eidasOngoingIdent,
      eidasOngoingVideoIdent,
      eidasOngoingSelfIdent,
      zertesOngoingIdent,
      zertesOngoingVideoIdent,
      zertesOngoingAutoIdent,
      businessName,
      lang: language,
      user: userStore,
    }
  },
  data() {
    return {
      panelQuality: undefined as number | undefined,
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards',
    }
  },
  computed: {
    formattedMobile(): string {
      return this.formatMobile(
        this.user.attributes.mobile?.[0] ?? '',
        this.user.attributes.country?.[0].toUpperCase() as CountryCode
      )
    },
    dynamicTranslations() {
      return {
        signatureStandardsLabel: {
          ses: this.$t('signature_standards.ses.label'),
          aes: this.$t('signature_standards.aes.label'),
          qes: this.$t('signature_standards.qes.label'),
        },
        signatureStandardsText: {
          ses: this.$t('signature_standards.ses.text'),
          aes: this.$t('signature_standards.aes.text'),
          qes: this.$t('signature_standards.qes.text'),
        },
        signatureStandardsTooltip: {
          mobile: this.$t('signature_standards.mobile_tooltip'),
        },
        signatureStandardsKey: {
          verified: this.$t('signature_standards.verified'),
          certificate: this.$t('signature_standards.certificate'),
          globalsign: this.$t('signature_standards.globalsign'),
          mobile: this.$t('signature_standards.mobile'),
        },
      }
    },
    ongoingIdentification(): boolean {
      return this.eidasOngoingIdent || this.zertesOngoingIdent
    },
    ongoingVideoIdent(): boolean {
      return this.eidasOngoingVideoIdent || this.zertesOngoingVideoIdent
    },
    legislation(): string | null {
      if (this.user.highestSignatureQuality === 'qes') {
        if (this.user.signatureQualities?.qes?.eidas) {
          if (this.user.signatureQualities?.qes?.zertes) {
            return this.$t('signature_standards.qes.ch_eu')
          } else {
            return this.$t('signature_standards.qes.eu')
          }
        } else {
          return this.$t('signature_standards.qes.ch')
        }
      } else {
        return null
      }
    },
    signatureStandardsData(): SignatureQualities | null {
      const qualityData: SignatureQualities = {}

      const ses = this.user.signatureQualities?.ses?.any
      if (ses) {
        // ses is hardcoded to swisscom
        qualityData.ses = {
          verified: SKRIBBLE,
          certificate: SWISSCOM,
        }
      }

      // They need at least AES but QES is also good to sign AES with
      const aes = this.user.signatureQualities?.aes?.any
      if (aes) {
        qualityData.aes = this.mapProvider(aes.provider)
      }

      if (this.user.highestSignatureQuality === 'qes') {
        const qes = this.user.signatureQualities?.qes
        // default "" "should" never be the case, it's just to be type safe.
        // TODO: Remove as soon as this will be defined in the backend
        const getProvider = (): string => {
          // AIS_AND_ATRUST doesn't exist in bbq - special case if user has both providers for qes
          if (qes && qes.zertes?.provider === 'AIS' && qes.eidas?.provider === 'ATRUST') return 'AIS_AND_ATRUST'
          if (qes && qes.zertes?.provider === qes.eidas?.provider) return qes.zertes?.provider || ''
          if (qes && !qes.zertes?.provider && qes.eidas?.provider) return qes.eidas?.provider || ''
          if (qes && qes.zertes?.provider && !qes.eidas?.provider) return qes.zertes?.provider || ''
          return ''
        }
        qualityData.qes = this.mapProvider(getProvider())
      }

      if (this.ongoingIdentification) {
        // That user is in the process of being identified
        qualityData.qes = {}
      }

      // return null if the object is still empty here
      if (Object.keys(qualityData).length === 0) {
        return null
      } else {
        return qualityData
      }
    },
  },
  watch: {
    ongoingIdentification() {
      // If there is an ongoing videoident, open the QES panel by default
      const qesPanelIndex = !this.signatureStandardsData ? -1 : Object.keys(this.signatureStandardsData).length - 1
      this.panelQuality = qesPanelIndex
    },
  },
  mounted() {
    if (this.ongoingIdentification) {
      const qesPanelIndex = !this.signatureStandardsData ? -1 : Object.keys(this.signatureStandardsData).length - 1
      this.panelQuality = qesPanelIndex
    }
  },
  methods: {
    formatMobile(mobile: string, country: CountryCode): string {
      const mobileNumber = parsePhoneNumberFromString(`+${mobile}`, country)
      if (mobileNumber) {
        return mobileNumber.formatInternational()
      } else {
        return ''
      }
    },
    mapProvider(provider: string) {
      if (provider === 'GLOBALSIGN') {
        return {
          verified: this.businessName,
          certificate: GLOBALSIGN,
        }
      }
      if (provider === 'AIS_FASTTRACK') {
        // Next is a Swiss phone number
        return {
          verified: this.$t('signature_standards.mobile_carrier'),
          certificate: SWISSCOM,
          mobile: this.formattedMobile,
        }
      }
      if (provider === 'AIS') {
        // This user was verified for QES and is in the RA DB
        return {
          verified: SWISSCOM,
          certificate: SWISSCOM,
          mobile: this.formattedMobile,
        }
      }
      if (provider === 'AIS_AND_ATRUST') {
        // Doesn't exist in bbq - special case if user has both providers for qes
        return {
          verified: `${SWISSCOM} (${this.$t('signature_standards.ch_law') as string})
          ${this.$t('global.signature_standards.and') as string}
          ${ATRUST} (${this.$t('signature_standards.eu_law') as string})`,
          certificate: `${SWISSCOM} ${this.$t('global.signature_standards.and') as string} ${ATRUST}`,
          mobile: this.formattedMobile,
        }
      }
      if (provider === 'AIS_OR_GLOBALSIGN') {
        return {
          verified: SKRIBBLE,
          certificate: `${SWISSCOM} ${this.$t('global.signature_standards.or') as string} ${GLOBALSIGN}`,
        }
      }
      if (provider === 'ATRUST') {
        return {
          verified: ATRUST,
          certificate: ATRUST,
          mobile: this.formattedMobile,
        }
      }
      if (provider === 'SELFSIGNED') {
        return {
          verified: SKRIBBLE,
          certificate: DEMO,
        }
      }
      if (provider === 'SKRIBBLE' || provider === 'SKRIBBLE_AES') {
        return {
          verified: SKRIBBLE,
          certificate: SWISSCOM,
          mobile: this.formattedMobile,
        }
      }
      // TODO: catch unknown provider
      return {}
    },
  },
})
</script>

<style lang="sass">
$sist-ns: sist

// signature-standards
.#{$sist-ns}
  &-sq__row
    min-height: 66px
    + .#{$sist-ns}-sq__row
      border-top: 1px solid $c-grey-light

  &__dot
    height: 16px
    width: 16px
    align-self: baseline
    margin-top: 4px

.signature-standards
  .ongoing-ident
    opacity: .5
</style>
