<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M3.6,16.1c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7V7c0-3.3-2.7-6-6-6s-6,2.7-6,6v1c0,1.9,0.9,3.9,2.3,5.3
    c-1.4,0.2-2.7,0.6-3.6,0.9c-1.6,0.5-2.7,2.1-2.7,3.8v4c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1h-9v-3
    C2.2,17.2,2.8,16.4,3.6,16.1z M6.2,8V7c0-2.2,1.8-4,4-4s4,1.8,4,4v1c0,2.3-1.8,5-4,5S6.2,10.3,6.2,8z"
    />
    <path
      d="M23.5,16.3l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.3,1.3h-2.4c-1.7,0-3.2,1.3-3.2,3v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3
    c0-0.6,0.5-1,1.2-1h2.4L19,19.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3C23.8,17.3,23.8,16.7,23.5,16.3z
    "
    />
  </svg>
</template>
