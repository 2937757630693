<script lang="ts" setup>
type HeadingLevel = '1' | '2' | '3'

defineProps<{
  level: HeadingLevel
  semantic?: boolean
  dataCy?: string
}>()
</script>

<template>
  <template v-if="semantic">
    <h1 v-if="level === '1'" class="heading1" :data-cy="dataCy">
      <slot />
    </h1>
    <h2 v-else-if="level === '2'" class="heading2" :data-cy="dataCy">
      <slot />
    </h2>
    <h3 v-else-if="level === '3'" class="heading3" :data-cy="dataCy">
      <slot />
    </h3>
  </template>
  <div v-else :class="`heading${level}`" :data-cy="dataCy">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.heading1 {
  font-size: $h1-font-size;
  font-weight: 700;
  letter-spacing: $h1-letter-spacing;
  line-height: 1.2;
  color: $c-text-alt;
  margin-bottom: 0.75rem;
  @include max-width(sm) {
    font-size: $h1-font-size-mobile;
    line-height: 1.0666;
  }
}

.heading2 {
  font-size: $h2-font-size;
  margin-bottom: 0.5rem;
  @include max-width(sm) {
    font-size: calc($h2-font-size - 2px);
  }
}

.heading3 {
  font-size: $h3-font-size;
  margin-bottom: 0.25rem;
  @include max-width(sm) {
    font-size: calc($h3-font-size - 4px);
  }
}
</style>
