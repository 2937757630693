<script setup></script>

<template>
  <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1020_263)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0.999512C0 0.447227 0.447715 -0.000488281 1 -0.000488281H14.0286C14.5809 -0.000488281 15.0286 0.447227 15.0286 0.999512V16.1995C15.0286 16.7518 14.5809 17.1995 14.0286 17.1995H1C0.447715 17.1995 0 16.7518 0 16.1995V0.999512ZM2 1.99951V15.1995H13.0286V1.99951H2ZM17.7605 7.05665C18.3128 7.05665 18.7605 7.50437 18.7605 8.05665V19.9995C18.7605 20.5518 18.3128 20.9995 17.7605 20.9995H8.05697C7.50468 20.9995 7.05697 20.5518 7.05697 19.9995C7.05697 19.4472 7.50468 18.9995 8.05697 18.9995H16.7605V8.05665C16.7605 7.50437 17.2083 7.05665 17.7605 7.05665ZM4.03999 4.47394C3.56029 4.47394 3.17141 4.86281 3.17141 5.34251C3.17141 5.82221 3.56029 6.21108 4.03999 6.21108H11.0903C11.57 6.21108 11.9589 5.82221 11.9589 5.34251C11.9589 4.86281 11.57 4.47394 11.0903 4.47394H4.03999ZM3.17144 8.59965C3.17144 8.11995 3.56031 7.73108 4.04001 7.73108H11.0904C11.5701 7.73108 11.9589 8.11995 11.9589 8.59965C11.9589 9.07935 11.5701 9.46822 11.0904 9.46822H4.04001C3.56031 9.46822 3.17144 9.07935 3.17144 8.59965ZM4.04001 10.9879C3.56031 10.9879 3.17144 11.3768 3.17144 11.8565C3.17144 12.3362 3.56031 12.7251 4.04001 12.7251H11.0904C11.5701 12.7251 11.9589 12.3362 11.9589 11.8565C11.9589 11.3768 11.5701 10.9879 11.0904 10.9879H4.04001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1020_263">
        <rect width="19" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
