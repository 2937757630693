<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M24,22.2l-1.1-5.3c0-0.2-0.1-0.4-0.3-0.5l-4.3-4.3l5-5c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7L18,0.3c-0.4-0.4-1-0.4-1.4,0l-5,5l-5-5c-0.4-0.4-1-0.4-1.4,0L0.9,4.5c-0.4,0.4-0.4,1,0,1.4l5,5l-5.6,5.6c-0.4,0.4-0.4,1,0,1.4l5.4,5.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5.6-5.6L17,22c0.1,0.1,0.3,0.2,0.5,0.3l5.3,1.1c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.3C23.9,22.9,24,22.5,24,22.2z M3,5.2l2.8-2.8l4.3,4.3L7.3,9.5L3,5.2z M6.4,21.3l-4-4l2.2-2.2l1,1c0.2,0.2,0.5,0.3,0.7,0.3S6.8,16.2,7,16c0.4-0.4,0.4-1,0-1.4l-1-1L7.7,12c0.1,0,0.1,0,0.2-0.1c0,0.1,0.1,0.2,0.2,0.3l2.5,2.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-2.5-2.5c-0.1-0.1-0.3-0.2-0.4-0.2l1.8-1.8l1,1C12,9.9,12.3,10,12.6,10s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-0.8-0.8c0.1-0.1,0.2-0.3,0.2-0.4l2-2l2.2,2.2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-2.2-2.2l1.2-1.2l4,4L6.4,21.3z M18.2,20.4l-4-4l2.8-2.8l4,4l0.7,3.5L18.2,20.4z"
    />
  </svg>
</template>
