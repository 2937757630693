<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M4.2,9.4c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6C6.9,10.6,5.7,9.4,4.2,9.4z M4.2,12.8
  c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S4.7,12.8,4.2,12.8z M19.8,9.4c-1.4,0-2.6,1.2-2.6,2.6
  c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6C22.4,10.6,21.2,9.4,19.8,9.4z M19.8,12.8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8
  s0.8,0.4,0.8,0.8S20.2,12.8,19.8,12.8z M12,9.4c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
  C14.6,10.6,13.4,9.4,12,9.4z M12,12.8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S12.5,12.8,12,12.8z"
    />
  </svg>
</template>
