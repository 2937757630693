import destr from 'destr'

type AuthEventType = 'logout' | 'surveySession' | 'session'

interface AuthEvent<T = AuthEventType> {
  type: T
  data: T extends 'session' ? { token: string } : undefined
}

export default defineNuxtPlugin({
  name: 'auth',
  setup() {
    const authStore = useAuthStore()
    const userStore = useUserStore()

    const broadcast = new BroadcastChannel('auth')

    const auth = {
      signalLogout() {
        broadcast.postMessage(JSON.stringify({ type: 'logout' }))
      },
      surveySession() {
        // Trigger a survey for all active sessions to send their info, if available
        broadcast.postMessage(JSON.stringify({ type: 'surveySession' }))
      },
      signalSession() {
        const accessToken = destr<{ value: string; expiration: number } | null>(sessionStorage.getItem('accessToken'))
        // Signal the session to the other tabs
        if (accessToken) {
          broadcast.postMessage(JSON.stringify({ type: 'session', data: { token: accessToken.value } }))
        }
      },
    }

    const handleSessionUpdate = (event: AuthEvent) => {
      // Prevent the same tab from reloading
      if (authStore.token.value === event.data!.token) return
      authStore.storeToken(event.data!.token)
      window.location.reload()
    }

    broadcast.addEventListener('message', (event: MessageEvent<string>) => {
      if (!event.data) return

      const parsedEvent = destr<AuthEvent>(event.data)

      switch (parsedEvent.type) {
        case 'surveySession':
          auth.signalSession()
          break
        case 'session':
          handleSessionUpdate(parsedEvent)
          break
        case 'logout':
          if (userStore.id) {
            userStore.clearUser()
            window.location.reload()
          }
          break
      }
    })

    return {
      provide: {
        auth,
      },
    }
  },
})
