<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17.4493756,7.23243421 C17.5164605,6.74615237 17.2206633,6.28308072 16.7512424,6.13950367 C11.3666002,4.49255747 8.11353865,6.43890891 6.90036794,10.0340619 C5.88643607,13.0387835 6.32631985,16.0658607 8.93498609,17.4589366 C11.1548995,18.6444113 13.9060028,17.8640529 15.6248672,15.9846626 C16.2720313,17.3876657 17.3937327,18.1445509 19.352203,17.99181 C22.3416736,17.7586615 23.7020046,15.1477262 23.9510437,11.2953107 C24.3995508,6.89103784 21.8769704,2.6822219 17.7497882,0.991385774 C12.6235041,-1.09252724 6.46720693,0.241025185 3.32770635,3.8961884 C-0.535183635,8.39304882 -1.3644418,15.8030473 2.59599538,20.3653907 C6.28957516,24.6199374 12.9901787,24.8290453 18.0318826,22.8987621 C18.5476573,22.7012909 18.8056933,22.1230908 18.6082221,21.6073161 C18.4107509,21.0915414 17.8325508,20.8335054 17.3167761,21.0309765 C12.9719084,22.6944668 7.10763517,22.5114586 4.1062943,19.0542854 C0.868066051,15.323912 1.57514237,9.00569333 4.84484845,5.19936905 C7.41120689,2.21148653 12.649194,1.07685476 16.9940987,2.84312173 C20.2984825,4.19687536 22.3201993,7.57002205 21.9582563,11.1296418 C21.762964,14.1401606 20.8627751,15.8679274 19.1966954,15.9978648 C17.8461214,16.103196 17.3083484,15.3528539 17.0170521,13.6297234 L16.9832434,13.4182708 C17.0108476,11.3486898 17.1665203,9.28278486 17.4493756,7.23243421 Z M9.87710347,15.6947329 C8.34833331,14.8783414 8.05579727,12.8652434 8.79538339,10.6735277 C9.62199853,8.22390709 11.5954171,6.91605403 15.355583,7.82897657 C15.1726928,9.34226817 15.0562308,10.8628332 15.0065242,12.3862814 L14.9847135,13.3006611 C14.1454291,15.4444581 11.6293221,16.63045 9.87710347,15.6947329 Z"
      fill-rule="nonzero"
    />
    />
  </svg>
</template>
