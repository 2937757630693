<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M15.4,11H13V8.6c0-0.6-0.4-1-1-1s-1,0.4-1,1V11H8.6c-0.6,0-1,0.4-1,1s0.4,1,1,1H11v2.4c0,0.6,0.4,1,1,1s1-0.4,1-1V13h2.4
  c0.6,0,1-0.4,1-1S16,11,15.4,11z M12,3c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S17,3,12,3z M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7
  S15.9,19,12,19z"
    />
  </svg>
</template>
