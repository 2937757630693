<template>
  <v-main>
    <toolbar-base :title="$t('direct_sign.identification.ch_ident.details.header')" app />
    <v-container fluid class="content">
      <v-row>
        <v-col cols="12" md="5">
          <heading
            class="pb-6"
            :default-padding="false"
            :title="$t('direct_sign.identification.ch_ident.details.title')"
            :subtitle="$t('direct_sign.identification.ch_ident.details.subtitle')"
          />
          <v-form v-model="name.valid" @submit.prevent>
            <v-text-field
              v-model="name.first"
              autocomplete="given-name"
              hide-details
              :label="$t('signup.step.2.label_first_name')"
              name="fname"
              variant="filled"
              :rules="[() => Boolean(name.first) || '']"
              class="mb-5"
            />
            <v-text-field
              v-model="name.last"
              autocomplete="family-name"
              :label="$t('signup.step.2.label_last_name')"
              name="lname"
              variant="filled"
              :rules="[() => Boolean(name.last) || '']"
            />
            <v-btn
              :block="true"
              :disabled="!name.valid"
              :loading="isLoading"
              color="info"
              size="x-large"
              @click="storeName"
            >
              {{ $t('global.button.next') }}
            </v-btn>
            <div class="text-center mt-6">
              <v-btn :block="true" class="flat-link" size="large" variant="text" @click="goBack">
                {{ $t('business.setup.billing.back') }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
        <v-col v-if="$vuetify.display.mdAndUp" cols="12" md="7" align-self="center">
          <responsive-image source="enter-name" :width="447" :height="364" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="ts">
import Heading from '@/components/Heading.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'

export default defineComponent({
  components: {
    ToolbarBase,
    Heading,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['nas'],
      },
    })

    const directSignStore = useDirectSignStore()
    const userStore = useUserStore()
    const widgetStore = useWidgetStore()

    const { t } = useI18n()

    const name = reactive({
      first: userStore.firstName,
      last: userStore.lastName,
      valid: Boolean(userStore.firstName) && Boolean(userStore.lastName),
    })

    onBeforeMount(() => {
      if (!directSignStore.directSignSession) {
        window.location.href = '/login?logged-out'
      }
    })

    const isLoading = ref(false)

    const { directUserRepository } = useApi()

    const storeName = async () => {
      isLoading.value = true
      await directUserRepository.update({
        firstName: name.first,
        lastName: name.last,
      })

      await userStore.fetchUser()

      if (name.valid) {
        await navigateTo({
          name: 'direct-sign-identification-zertes',
        })
      } else {
        widgetStore.createSnackbar({
          message: t('viewer.eid_data.snackbar.aes.message.failure'),
        })
      }
    }

    const goBack = () => {
      useRouter().back()
    }

    return {
      isLoading,
      storeName,
      goBack,
      user: userStore,
      name,
    }
  },
  head() {
    return {
      title: 'NAS Ident - ZertES - Personal Details',
    }
  },
})
</script>

<style lang="sass" scoped>
.content
  display: flex
  align-items: center
  height: 100%
  max-width: $site-width
  margin: 0 auto
</style>
