/*
 * Stores optionally supplied email in query parameter to prefill login form
 */

export default defineNuxtRouteMiddleware(to => {
  if (to.query.email) {
    const email = to.query.email.toString().toLowerCase()
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidEmail = pattern.test(email)

    // only consider valid emails to avoid security issues
    if (isValidEmail) {
      sessionStorage.setItem('loginEmail', email)
    }
  }
})
