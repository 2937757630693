<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M16,18.5c0.6,0,1-0.4,1-1v-7c0-0.6-0.4-1-1-1s-1,0.4-1,1v7C15,18.1,15.4,18.5,16,18.5z M12,18.5c0.6,0,1-0.4,1-1v-7
  c0-0.6-0.4-1-1-1s-1,0.4-1,1v7C11,18.1,11.4,18.5,12,18.5z M23,5.5h-6v-3c0-0.6-0.4-1-1-1H8c-0.6,0-1,0.4-1,1v3H1c-0.6,0-1,0.4-1,1
  s0.4,1,1,1h2v12c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-12h2c0.6,0,1-0.4,1-1S23.6,5.5,23,5.5z M9,3.5h6v2H9V3.5z M19,19.5
  c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-12h14V19.5z M8,18.5c0.6,0,1-0.4,1-1v-7c0-0.6-0.4-1-1-1s-1,0.4-1,1v7C7,18.1,7.4,18.5,8,18.5
  z"
    />
  </svg>
</template>
