<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M21,0H3C1.3,0,0,1.3,0,3v18c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V3C24,1.3,22.7,0,21,0z M18.1,9.5l-6.6,7
      c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.4,0-0.8-0.2-1.1-0.5L5.9,13c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l2.3,2.4l5.5-5.9
      c0.6-0.6,1.5-0.6,2.1-0.1C18.6,8,18.7,8.9,18.1,9.5z"
    />
  </svg>
</template>
