export function extractFilename(response: Response) {
  return response.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1]
}

export function downloadFile(blob: Blob, name: string) {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = name
  a.target = '_blank'
  a.rel = 'noopener'
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
