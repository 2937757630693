<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11,11H5c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6C12,11.4,11.6,11,11,11z M10,17H6v-4h4V17z M14,13
  c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4C14.4,12,14,12.4,14,13z M23,5h-8V3c0-1.7-1.3-3-3-3S9,1.3,9,3v2H1
  C0.4,5,0,5.4,0,6v17c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V6C24,5.4,23.6,5,23,5z M11,3c0-0.6,0.4-1,1-1s1,0.4,1,1v4h-2V3z M22,22H2V7
  h7v1c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V7h7V22z M19,16h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1S19.6,16,19,16z"
    />
  </svg>
</template>
