/*
 * Checks if the url points to view and has an exitURL query param and
 * stores exitURL for the current session. If errorURL and/or declineURL
 * are supplied as well, they get stored too.
 */

/**
 * Parses a URL and returns it if it is valid.
 *
 * javascript: is a bad time, can be used by malicious actors
 * but this can be obfuscated in too many ways for blacklisting
 * so instead we ensure that we have a valid URL.
 */
const parseUrl = (url: unknown) => {
  if (typeof url !== 'string') return ''

  const parsedUrl = URL.parse(url)
  if (parsedUrl && ['http:', 'https:'].includes(parsedUrl.protocol)) {
    return parsedUrl.href
  }

  return ''
}

export default defineNuxtRouteMiddleware(to => {
  if (to.name === 'view-srid' || to.name === 'view-id-userid') {
    if (to.query.exitURL || to.query.exiturl || to.query.exitUrl) {
      const exitUrlStore = useExitUrlStore()

      const signatureRequestId = to.params.srid || to.params.id

      if (typeof signatureRequestId === 'string') {
        exitUrlStore.lastSignatureRequestId = signatureRequestId
      }

      const exitUrl = parseUrl(to.query.exitURL || to.query.exiturl || to.query.exitUrl)
      exitUrlStore.exitUrl = exitUrl

      // exitURL can be overwritten with specific errorURL for error case
      const errorUrl = parseUrl(to.query.errorURL || to.query.errorurl || to.query.errorUrl) || exitUrl
      exitUrlStore.errorUrl = errorUrl

      // exitURL can be overwritten with specific declineURL for decline case
      const declineUrl = parseUrl(to.query.declineURL || to.query.declineurl || to.query.declineUrl) || exitUrl
      exitUrlStore.declineUrl = declineUrl

      // The duration until an automatic redirection takes place can be set by
      // a URL parameter 'redirectTimeout' in seconds which cannot be set lower
      // than 10s or bigger than 90s and defaults to 45 seconds. However, it
      // can be set to 0 to skip the success overlay altogether.
      let timeout = 45
      if (typeof to.query.redirectTimeout === 'string' || typeof to.query.redirecttimeout === 'string') {
        let tmpTimeout = parseInt(to.query.redirectTimeout?.toString() ?? to.query.redirecttimeout?.toString() ?? '')
        // Timeout needs to be a number otherwise we ignore it.
        if (!isNaN(tmpTimeout)) {
          if (tmpTimeout === 0) {
            timeout = 0
          } else {
            // Enforce our constraints.
            tmpTimeout = Math.max(tmpTimeout, 10)
            tmpTimeout = Math.min(tmpTimeout, 90)

            timeout = tmpTimeout
          }
        }
      }

      exitUrlStore.timeout = timeout

      // TODO: Seems like this was never used, should we remove it?
      exitUrlStore.shouldDeclineRedirect = Boolean(declineUrl && (to.query.declineredirect || to.query.declineRedirect))
    }
  }
})
