<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M21.9,17.3h-1.7v-1.7c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.7h-1.7c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.7V21c0,0.6,0.4,1,1,1s1-0.4,1-1
  v-1.7h1.7c0.6,0,1-0.4,1-1S22.5,17.3,21.9,17.3z M22.9,4.8c0-1.5-1.3-2.8-2.8-2.8H3.9C2.4,2,1.1,3.3,1.1,4.8v11.7
  c0,1.5,1.3,2.8,2.8,2.8h7.2c0.6,0,1-0.4,1-1s-0.4-1-1-1H3.9c-0.4,0-0.8-0.4-0.8-0.8v-4.4h18.8c0.6,0,1-0.4,1-1c0,0,0,0,0,0s0,0,0,0
  V4.8z M20.9,10.1H3.1V7.6h17.8V10.1z M20.9,5.6H3.1V4.8C3.1,4.4,3.5,4,3.9,4h16.2c0.4,0,0.8,0.4,0.8,0.8V5.6z"
    />
  </svg>
</template>
