<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3335 7.5H14.9585V1.125"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3335 7.5V22.875H3.3335V1.125H14.9585L21.3335 7.5Z"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7394 9.41295C12.0818 9.76368 12.6447 9.76707 12.9913 9.4205C13.3329 9.07885 13.3352 8.52566 12.9964 8.1812L10.3125 5.45259C9.9599 5.09408 9.3819 5.09408 9.02926 5.45259L6.34538 8.1812C6.00658 8.52566 6.00886 9.07885 6.3505 9.42049C6.69708 9.76707 7.26003 9.76368 7.60241 9.41295L8.70342 8.28509L8.70342 16.4656L7.60241 15.3377C7.26003 14.987 6.69708 14.9836 6.3505 15.3302C6.00886 15.6718 6.00658 16.225 6.34538 16.5695L9.02926 19.2981C9.3819 19.6566 9.95989 19.6566 10.3125 19.2981L12.9964 16.5695C13.3352 16.225 13.3329 15.6718 12.9913 15.3302C12.6447 14.9836 12.0818 14.987 11.7394 15.3377L10.5034 16.6038L10.5034 8.14684L11.7394 9.41295Z"
      fill="currentColor"
    />
  </svg>
</template>
