<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'

export interface Snackbar {
  message: string
  color?: string
  duration?: number
  indefinite?: boolean
  dismissible?: boolean
  action?: { text: string; handler: () => void }
}

const props = withDefaults(defineProps<Snackbar & { attach?: boolean }>(), { dismissible: true })

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { xs } = useDisplay()

const computedDuration = computed(() => {
  return props.indefinite ? -1 : (props.duration ?? 3000)
})

const onVisibilityChange = (isVisible: boolean) => {
  if (!isVisible) {
    emit('close')
  }
}

const onAction = () => {
  if (props.action) {
    props.action.handler()
  }
  emit('close')
}
</script>

<template>
  <v-snackbar
    :model-value="true"
    :attach="attach"
    :color="color"
    :timeout="computedDuration"
    :vertical="xs"
    class="snackbar"
    data-cy="snackbar"
    @update:model-value="onVisibilityChange"
  >
    <span class="font-weight-bold">{{ message }}</span>
    <template #actions>
      <skr-button v-if="action" type="text" color="white" @click="onAction">
        {{ action.text }}
      </skr-button>
      <skr-button
        v-else-if="dismissible"
        type="text"
        color="white"
        icon="custom:highlight_off"
        @click="emit('close')"
      />
    </template>
  </v-snackbar>
</template>
