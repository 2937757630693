<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 40 40"
    enable-background="new 0 0 40 40"
    xml:space="preserve"
  >
    <path
      d="M20,0C9,0,0,9,0,20c0,11,9,20,20,20s20-9,20-20C40,9,31,0,20,0z M27,21.6h-5.4V27c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
  v-5.4H13c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h5.4V13c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6v5.4H27c0.9,0,1.6,0.7,1.6,1.6
  S27.9,21.6,27,21.6z"
    />
  </svg>
</template>
