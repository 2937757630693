<template>
  <v-main>
    <toolbar-base :title="t('direct_sign.identification.self.toolbar_title')" app />
    <two-cols-block class="fill-height" split="5/7">
      <template #left>
        <div class="mb-12">
          <skr-heading level="1" semantic>{{ t('eu_ident.self.heading.title') }}</skr-heading>
        </div>
        <p class="mb-10">{{ t('eu_ident.self.requirements.intro') }}</p>
        <ul class="mb-10">
          <li>{{ t('eu_ident.self.requirements.item_1') }}</li>
          <li>{{ t('eu_ident.self.requirements.item_2') }}</li>
          <li>{{ t('eu_ident.self.requirements.item_3') }}</li>
        </ul>
        <p class="mb-10 font-weight-bold">
          {{ t('eu_ident.self.duration_text') }}
        </p>
        <skr-button-group size="xl" stacked>
          <skr-button
            data-cy="start_button"
            :loading="isLoading"
            :disabled="!identUrl"
            :href="identUrl"
            @click="openStatusPage"
            >{{ t('eu_ident.self.start') }}</skr-button
          >
          <skr-button data-cy="cancel_button" type="secondary" :disabled="isLoading" @click="onCancel">{{
            t('global.button.go_back')
          }}</skr-button>
        </skr-button-group>
      </template>
      <template v-if="$vuetify.display.mdAndUp" #right>
        <responsive-image source="self-ident" :width="723" :height="697" />
      </template>
    </two-cols-block>
  </v-main>
</template>

<script lang="ts">
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'
import SelfIdent from '@/pages/profile/signature-standards/eidas/self.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrButtonGroup,
    SkrHeading,
    TwoColsBlock,
    ToolbarBase,
    ResponsiveImage,
  },
  extends: SelfIdent,
  setup(props, ctx) {
    definePageMeta({
      accessControl: {
        roles: ['nas'],
      },
    })

    return {
      ...SelfIdent.setup(props, ctx),
    }
  },
})
</script>
