<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M16,14c-0.6,0-1,0.4-1,1v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3C17,14.4,16.6,14,16,14z M17,7.2V6c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.2
  c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3s3-1.3,3-3C19,8.7,18.2,7.6,17,7.2z M16,11c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
  S16.6,11,16,11z M8,10c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1S7,5.4,7,6v3C7,9.6,7.4,10,8,10z M8,11c-1.7,0-3,1.3-3,3
  c0,1.3,0.8,2.4,2,2.8V18c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.2c1.2-0.4,2-1.5,2-2.8C11,12.3,9.7,11,8,11z M8,15c-0.6,0-1-0.4-1-1
  s0.4-1,1-1s1,0.4,1,1S8.6,15,8,15z"
    />
  </svg>
</template>
