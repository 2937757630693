<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,18c0.3,0,0.5-0.1,0.7-0.3l5.3-5.3V16c0,0.6,0.4,1,1,1s1-0.4,1-1v-6c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.3-0.4-0.5-0.5
  C19.3,9,19.1,9,19,9h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6l-5.3,5.3c-0.4,0.4-0.4,1,0,1.4C11.5,17.9,11.7,18,12,18z M23,1H1
  C0.4,1,0,1.4,0,2v20c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V2C24,1.4,23.6,1,23,1z M22,21H2V7h20V21z M22,5H2V3h20V5z"
    />
  </svg>
</template>
