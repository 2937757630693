const sealRepository = (fetch: CustomFetch) => ({
  async getSealImage(businessId: string, accountName: string) {
    return fetch<Blob>(`/v1/businesses/${businessId}/seals/${accountName}/image`)
  },
  async setSealImage(businessId: string, accountName: string, image: { content: string; contentType: string }) {
    await fetch(`/v1/businesses/${businessId}/seals/${accountName}/image`, {
      method: 'POST',
      body: objectToSnakeCase(image),
    })
  },
  async deleteSealImage(businessId: string, accountName: string) {
    await fetch(`/v1/businesses/${businessId}/seals/${accountName}/image`, { method: 'DELETE' })
  },
  async getSealData(businessId: string, accountName: string) {
    return fetch<BusinessSealDetails>(`/v1/businesses/${businessId}/seals/${accountName}`)
  },
  async updateSealData(businessId: string, accountName: string, data: BusinessSealDetails) {
    return fetch<BusinessSealDetails>(`/v1/businesses/${businessId}/seals/${accountName}`, {
      method: 'PUT',
      body: objectToSnakeCase(data),
    })
  },
  async addSealMembers(businessId: string, accountName: string, memberIds: string[]) {
    await fetch(`/v1/businesses/${businessId}/seals/${accountName}/members`, {
      method: 'POST',
      body: { members: memberIds },
    })
  },
  async removeSealMembers(businessId: string, accountName: string, memberIds: string[]) {
    await fetch(`/v1/businesses/${businessId}/seals/${accountName}/members`, {
      method: 'DELETE',
      body: { members: memberIds },
    })
  },
})

export default sealRepository
