<script setup></script>

<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.883 3.7C12.7448 3.7 3.69999 12.7801 3.69999 24C3.69999 35.2199 12.7448 44.3 23.883 44.3C35.0212 44.3 44.0659 35.2199 44.0659 24C44.0659 12.7801 35.0212 3.7 23.883 3.7ZM0.299988 24C0.299988 10.9194 10.8499 0.300003 23.883 0.300003C36.916 0.300003 47.4659 10.9194 47.4659 24C47.4659 37.0806 36.916 47.7 23.883 47.7C10.8499 47.7 0.299988 37.0806 0.299988 24Z"
      fill="currentColor"
    />
    <path
      d="M14.9309 24C16.5789 24 17.9149 22.6569 17.9149 21C17.9149 19.3431 16.5789 18 14.9309 18C13.2828 18 11.9468 19.3431 11.9468 21C11.9468 22.6569 13.2828 24 14.9309 24Z"
      fill="currentColor"
    />
    <path
      d="M32.8351 24C34.4831 24 35.8191 22.6569 35.8191 21C35.8191 19.3431 34.4831 18 32.8351 18C31.187 18 29.851 19.3431 29.851 21C29.851 22.6569 31.187 24 32.8351 24Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.883 31.7C22.7539 31.7 21.6693 32.1508 20.8682 32.9562C20.0667 33.762 19.6149 34.8567 19.6149 36C19.6149 36.9389 18.8538 37.7 17.9149 37.7C16.976 37.7 16.2149 36.9389 16.2149 36C16.2149 33.9608 17.0206 32.0032 18.4576 30.5585C19.8949 29.1135 21.8463 28.3 23.883 28.3C25.9196 28.3 27.871 29.1135 29.3083 30.5585C30.7454 32.0032 31.551 33.9608 31.551 36C31.551 36.9389 30.7899 37.7 29.851 37.7C28.9122 37.7 28.151 36.9389 28.151 36C28.151 34.8567 27.6992 33.762 26.8978 32.9562C26.0966 32.1508 25.012 31.7 23.883 31.7Z"
      fill="currentColor"
    />
  </svg>
</template>
