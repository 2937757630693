<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 7.5H14.625V1.125"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 7.5V22.875H3V1.125H14.625L21 7.5Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.20661 12.2771C9.50554 11.9853 9.50843 11.5055 9.21304 11.2101L9.02889 11.0259C8.73771 10.7348 8.26621 10.7328 7.97263 11.0216L5.04341 13.9028C4.74465 14.1966 4.74465 14.6783 5.04341 14.9722L7.97263 17.8534C8.26621 18.1421 8.73771 18.1402 9.02889 17.849L9.21304 17.6649C9.50843 17.3695 9.50554 16.8897 9.20661 16.5979L7.95392 15.375H16.0457L14.793 16.5979C14.4941 16.8897 14.4912 17.3695 14.7866 17.6649L14.9707 17.849C15.2619 18.1402 15.7334 18.1421 16.027 17.8534L18.9562 14.9722C19.255 14.6783 19.255 14.1966 18.9562 13.9028L16.027 11.0216C15.7334 10.7328 15.2619 10.7348 14.9707 11.0259L14.7866 11.2101C14.4912 11.5055 14.4941 11.9853 14.793 12.2771L16.4298 13.875H7.56977L9.20661 12.2771Z"
      fill="currentColor"
    />
  </svg>
</template>
