<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.76611 9.90015C5.76611 9.35649 6.20683 8.91577 6.75049 8.91577H13.0506C13.5942 8.91577 14.0349 9.35649 14.0349 9.90015C14.0349 10.4438 13.5942 10.8845 13.0506 10.8845H6.75049C6.20683 10.8845 5.76611 10.4438 5.76611 9.90015Z"
      fill="currentColor"
    />
    <path
      d="M22.5001 22.5003L15.8398 15.8401"
      stroke="currentColor"
      stroke-width="1.96875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.90011 18.3002C14.5394 18.3002 18.3002 14.5394 18.3002 9.90011C18.3002 5.26086 14.5394 1.5 9.90011 1.5C5.26086 1.5 1.5 5.26086 1.5 9.90011C1.5 14.5394 5.26086 18.3002 9.90011 18.3002Z"
      stroke="currentColor"
      stroke-width="1.96875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
