<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12.6,7.7C12.6,7.7,12.6,7.7,12.6,7.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.7,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0l-2.9,2.9c-0.4,0.4-0.4,0.9,0,1.3s0.9,0.4,1.3,0l1.4-1.4v5.8c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-5.8l1.4,1.4c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3L12.6,7.7z M12,3.1c-4.9,0-8.9,4-8.9,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S16.9,3.1,12,3.1z M12,19.1c-3.9,0-7.1-3.2-7.1-7.1S8.1,4.9,12,4.9s7.1,3.2,7.1,7.1S15.9,19.1,12,19.1z"
    />
  </svg>
</template>
