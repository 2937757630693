<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17,11h-4V7c0-0.6-0.4-1-1-1s-1,0.4-1,1v4H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h4v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4h4
  c0.6,0,1-0.4,1-1S17.6,11,17,11z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12c6.6,0,12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12
  S6.5,2,12,2c5.5,0,10,4.5,10,10S17.5,22,12,22z"
    />
  </svg>
</template>
