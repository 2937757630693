<script setup></script>

<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5607 12.5607C11.9749 13.1464 11.0251 13.1464 10.4393 12.5607L0.439342 2.56066C-0.146446 1.97487 -0.146446 1.02513 0.439342 0.43934C1.02513 -0.146447 1.97487 -0.146447 2.56066 0.43934L12.5607 10.4393C13.1464 11.0251 13.1464 11.9749 12.5607 12.5607Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5607 0.43934C13.1464 1.02513 13.1464 1.97487 12.5607 2.56066L2.56066 12.5607C1.97487 13.1464 1.02513 13.1464 0.439342 12.5607C-0.146446 11.9749 -0.146446 11.0251 0.439342 10.4393L10.4393 0.43934C11.0251 -0.146447 11.9749 -0.146447 12.5607 0.43934Z"
      fill="currentColor"
    />
  </svg>
</template>
