<script lang="ts" setup>
import DialogControl from '@/components/DialogControl.vue'
import OverlayControl from '@/components/OverlayControl.vue'
import SnackbarControl from '@/components/snackbar/SnackbarControl.vue'

const { t } = useI18n()

const { mode } = useRuntimeConfig().public

const { activeMaintenance } = storeToRefs(useSystemStore())

useErrorHandling()

if (mode !== 'development') {
  useTracking()
}

const showTestSystemMessage = computed(() => {
  const hideMessage = localStorage.getItem('hide-staging-alert') !== null
  return mode !== 'production' && !hideMessage
})
</script>

<template>
  <v-app>
    <v-app-bar v-if="activeMaintenance" color="warning" app flat>
      <div class="d-flex justify-center flex-grow-1">
        <div class="d-flex align-center">
          <v-icon size="20">custom:warning</v-icon>
          <i18n-t class="text-body-2 ml-2" tag="span" keypath="maintenance.light.text">
            <template #link>
              <nuxt-link class="text-white" :href="activeMaintenance.url">{{
                t('maintenance.light.link_text')
              }}</nuxt-link>
            </template>
          </i18n-t>
        </div>
      </div>
    </v-app-bar>

    <snackbar-control />
    <dialog-control />
    <overlay-control />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <div v-if="showTestSystemMessage" class="skribble-staging">
      <div class="skribble-staging__message">Test System</div>
      <span class="skribble-staging__border" />
      <span class="skribble-staging__border" />
      <span class="skribble-staging__border" />
      <span class="skribble-staging__border" />
    </div>
  </v-app>
</template>

<style lang="sass">
.skribble-staging
  position: fixed
  isolation: isolate
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1010 // Above the Vuetify elements
  pointer-events: none

  &__message
    position: fixed
    top: -4px
    left: 50%
    padding: 10px 20px
    color: $c-danger
    font-weight: bold
    transform: translateX(-50%)
    pointer-events: none

  &__border
    position: fixed
    background: $c-danger
    pointer-events: none
    &:nth-child(odd)
      top: 0
      bottom: 0
      width: 4px
      height: 100vh
    &:nth-child(even)
      left: 0
      right: 0
      width: 100vw
      height: 4px
    &:nth-child(1)
      left: 0
    &:nth-child(2)
      top: 0
    &:nth-child(3)
      right: 0
    &:nth-child(4)
      bottom: 0
</style>
