<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11,14.5h2c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v1C10,14.1,10.4,14.5,11,14.5z M17,14.5h2
  c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v1C16,14.1,16.4,14.5,17,14.5z M11,19.5h2c0.6,0,1-0.4,1-1v-1
  c0-0.6-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1v1C10,19.1,10.4,19.5,11,19.5z M21,2.5h-2v-1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H7v-1
  c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H3c-1.7,0-3,1.3-3,3v15c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3v-15C24,3.8,22.7,2.5,21,2.5z M22,20.5
  c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1v-11h20V20.5z M22,7.5H2v-2c0-0.6,0.4-1,1-1h2c0,0.6,0.4,1,1,1s1-0.4,1-1h10c0,0.6,0.4,1,1,1
  s1-0.4,1-1h2c0.6,0,1,0.4,1,1V7.5z M5,14.5h2c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1v1C4,14.1,4.4,14.5,5,14.5z
   M5,19.5h2c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1H5c-0.6,0-1,0.4-1,1v1C4,19.1,4.4,19.5,5,19.5z"
    />
  </svg>
</template>
