export type SuggestionType = 'recent' | 'prefix' | 'suggest' | 'domain' | 'exact' | 'none'

interface EmailSuggestionsResponse {
  input: string
  type: SuggestionType
  values: string[]
}

const searchRepository = (fetch: CustomFetch) => ({
  async getEmailSuggestions(input: string) {
    return fetch<EmailSuggestionsResponse>(`/v1/email_input_autocomplete?input=${input}`)
  },
})

export default searchRepository
