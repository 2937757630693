/*
 * checks if the user is logged in
 * redirects to /login if not
 * unprotected routes have to be explicitly defined
 */

import cookies from 'js-cookie'

export default defineNuxtRouteMiddleware(async to => {
  // Prevent further authentication attempts in case the user has blocked cookies
  // Blocking cookies also blocks localStorage and sessionStorage, breaking our platform
  if (!navigator.cookieEnabled) return

  const { $sentry } = useNuxtApp()

  const authStore = useAuthStore()
  const userStore = useUserStore()

  const accessControl = {
    roles: new Set(to.meta.accessControl?.roles ?? []),
    isStrict: to.meta.accessControl?.isStrict ?? false,
  } satisfies { roles: Set<AppRole>; isStrict: boolean }

  try {
    if (accessControl.roles.size === 1 && accessControl.roles.has('nas')) {
      const directSignStore = useDirectSignStore()

      if (directSignStore.isDirectSign) {
        // We already have a direct sign session, no need to re-authenticate
        return
      }

      // NAS entry points will construct auth information from the URL parameters
      const { userid, id } = to.params

      if (typeof userid !== 'string' || typeof id !== 'string') {
        // Invalid direct sign parameters
        return navigateTo('/login')
      }

      const token = btoa(`${userid}:${id}`)
      authStore.storeToken(token, 'Basic')

      directSignStore.setDirectSign(userid, id)
      await userStore.loadUserBusinessData()
      await userStore.fetchUser()
      $sentry?.setUser({ id: userid })

      return
    }

    // SSO logins will hand the token via a x-access-token cookie. wave will pick
    // this cookie up, set the sessionStorage accordingly and then remove it
    // again to not having it sent to netlify.
    const ssoToken = cookies.get('x-access-token')
    if (ssoToken) {
      authStore.storeToken(ssoToken)
      cookies.remove('x-access-token')
    }

    // Certain integrations are in possession of a user's login credentials to
    // interact with our API in their name. An example being are eID+ from
    // Procivis. Those integrations can use a special API endpoint login/extended
    // to retrieve a short-lived token which in turn can be used as an URL
    // parameter `usertoken` to have this user also logged in on wave for as long
    // as the initial token is valid.
    const usertoken: string | undefined = to.query.usertoken as string | undefined
    if (usertoken) {
      authStore.storeToken(usertoken)
    }

    let userLoggedIn = false
    // First heuristic is to see if we have a user
    if (!userStore.id) {
      // Recover user from session storage, if not expired
      const token = authStore.getStoredToken()
      if (token) {
        try {
          await userStore.loadUserBusinessData()
          await userStore.fetchUser()
          userLoggedIn = true
          $sentry?.setUser({ id: userStore.id })
        } catch {
          // The token in storage is no longer valid
          authStore.removeStoredToken()
        }
      }
    } else {
      // We have a user in store which might still be logged in
      userLoggedIn = true
      $sentry?.setUser({ id: userStore.id })
    }

    const redirectPath = sessionStorage.getItem('redirectURL')

    if (userLoggedIn && redirectPath && to.name !== 'logout') {
      sessionStorage.removeItem('redirectURL')

      if (to.fullPath !== redirectPath) {
        return navigateTo(redirectPath)
      }
    }

    const userRoles = accessControl.isStrict ? new Set([userStore.role]) : userStore.accessRoles

    if (accessControl.roles.isDisjointFrom(userRoles)) {
      // Access mismatch, redirect to appropriate fallback
      if (userLoggedIn) {
        return navigateTo({ name: '404' })
      } else {
        if (to.name === 'login' || to.name === 'logout') {
          return
        }

        sessionStorage.setItem('redirectURL', to.fullPath)

        return navigateTo('/login')
      }
    }
  } catch (error) {
    const { logError } = useErrorHandling()

    logError('Failed to authenticate user', error)
  }
})
