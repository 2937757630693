<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7659_26073)">
      <path
        d="M21 11.25V20.25C21 20.7473 20.8025 21.2242 20.4508 21.5758C20.0992 21.9275 19.6223 22.125 19.125 22.125H4.875C4.37772 22.125 3.90081 21.9275 3.54917 21.5758C3.19754 21.2242 3 20.7473 3 20.25V11.25"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.25 5.25H0.75V9H23.25V5.25Z"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 3C5.25 2.40326 5.48705 1.83097 5.90901 1.40901C6.33097 0.987053 6.90326 0.75 7.5 0.75C10.8281 0.75 12 5.25 12 5.25H7.5C6.90326 5.25 6.33097 5.01295 5.90901 4.59099C5.48705 4.16903 5.25 3.59674 5.25 3Z"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 3C18.75 2.40326 18.5129 1.83097 18.091 1.40901C17.669 0.987053 17.0967 0.75 16.5 0.75C13.1719 0.75 12 5.25 12 5.25H16.5C17.0967 5.25 17.669 5.01295 18.091 4.59099C18.5129 4.16903 18.75 3.59674 18.75 3Z"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.25 5.25V22.125H9.75V5.25"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7659_26073">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
