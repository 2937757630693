<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12c6.6,0,12-5.4,12-12S18.6,0,12,0z M17,13h-4v4c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4H7
  c-0.6,0-1-0.4-1-1s0.4-1,1-1h4V7c0-0.6,0.4-1,1-1s1,0.4,1,1v4h4c0.6,0,1,0.4,1,1S17.6,13,17,13z"
    />
  </svg>
</template>
