<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.2,5.5l-1.7-1.7c-1-1-2.7-1-3.7,0L4.1,14.5c-0.1,0.1-0.2,0.3-0.3,0.5L3,19.8c-0.1,0.3,0.1,0.6,0.3,0.9
  C3.5,20.9,3.7,21,4,21c0.1,0,0.1,0,0.2,0L9,20.2c0.2,0,0.4-0.1,0.5-0.3L20.2,9.2C20.7,8.7,21,8,21,7.3C21,6.6,20.7,6,20.2,5.5z
   M8.3,18.3l-3.1,0.5l0.5-3.1l8.1-8.1l2.6,2.6L8.3,18.3z M18.8,7.8l-1,1l-2.6-2.6l1-1c0.2-0.2,0.6-0.2,0.8,0l1.7,1.7
  C18.9,7,19,7.2,19,7.3S18.9,7.6,18.8,7.8z"
    />
  </svg>
</template>
