<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M16,16c0,0.6,0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2
    h-2C16.4,15,16,15.4,16,16z M12,14c3.3,0,6-2.7,6-6V6c0-3.3-2.7-6-6-6S6,2.7,6,6v2C6,11.3,8.7,14,12,14z M8,6c0-2.2,1.8-4,4-4
    s4,1.8,4,4v2c0,2.2-1.8,4-4,4s-4-1.8-4-4V6z M23,22H2.1c0.5-2.3,2.5-4,4.9-4h5c0.6,0,1-0.4,1-1s-0.4-1-1-1H7c-3.9,0-7,3.1-7,7
    c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1S23.6,22,23,22z"
    />
  </svg>
</template>
