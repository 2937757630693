<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,14c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1s-1,0.4-1,1v6C11,13.6,11.4,14,12,14z M12,15.8c-0.7,0-1.2,0.6-1.2,1.2
  s0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2S12.7,15.8,12,15.8z M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11s11-4.9,11-11S18.1,1,12,1z M12,21
  c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S17,21,12,21z"
    />
  </svg>
</template>
