import type { PartialDeep } from 'type-fest'
import { destr } from 'destr'

const businessRepository = (fetch: CustomFetch) => ({
  async get(businessId: string) {
    return fetch<BusinessData>(`/v1/businesses/${businessId}`)
  },
  async getDefault() {
    return fetch<BusinessData>('/v2/businesses/user', { parseResponse: destr })
  },
  async create(registerData: RegisterBusinessPayload) {
    return fetch<Partial<BusinessData>>('/v2/businesses', {
      method: 'POST',
      body: objectToSnakeCase(registerData),
      parseResponse: destr,
    })
  },
  async delete(businessId: string) {
    await fetch(`/v2/businesses/${businessId}`, { method: 'DELETE' })
  },
  async revokeDeletion(businessId: string) {
    await fetch(`/v2/businesses/${businessId}/revoke_delete`, { method: 'POST' })
  },
  async cancelTrial(businessId: string) {
    await fetch(`/v1/businesses/${businessId}/cancel-trial`, { method: 'POST' })
  },
  async updateBillingAddress(businessId: string, addressData: Partial<Address> & { postal_code?: string }) {
    return fetch<Partial<BusinessData>>(`/v2/businesses/${businessId}/billing`, {
      method: 'PUT',
      body: addressData,
      parseResponse: destr,
    })
  },
  async getExtras(businessId: string) {
    return fetch<BusinessExtras[]>(`/v2/businesses/${businessId}/customextras`, { parseResponse: destr })
  },
  async getSettings(businessId: string) {
    // FIXME: Careful, the backend returns a nested object here
    const { settings } = await fetch<{ settings: BusinessPreferences }>(`/v1/businesses/${businessId}/settings`)
    return settings
  },
  async updateSettings(businessId: string, settings: PartialDeep<BusinessPreferences>) {
    // FIXME: Careful, the backend returns a nested object here
    const { settings: updatedSettings } = await fetch<{ settings: BusinessPreferences }>(
      `/v1/businesses/${businessId}/settings`,
      {
        method: 'PUT',
        body: objectToSnakeCase(settings),
        parseResponse: destr,
      }
    )

    return updatedSettings
  },
  // TODO: This should be a dedicated endpoint
  async getBranding(businessId: string) {
    const {
      name,
      settings: { companyBranding },
    } = await this.get(businessId)

    return {
      name,
      companyBranding: companyBranding ?? null,
    }
  },
  async getAdmins(businessId: string) {
    const { admins } = await fetch<{ admins: Admin[] }>(`/v1/businesses/${businessId}/admins`)
    return admins
  },
  async makeAdmins(businessId: string, memberIds: string[]) {
    return fetch(`/v1/businesses/${businessId}/change-to-admins`, {
      method: 'POST',
      body: { user_ids: memberIds },
    })
  },
  async makeAdmin(businessId: string, memberId: string) {
    return this.makeAdmins(businessId, [memberId])
  },
  async revokeAdmins(businessId: string, userIds: string[]) {
    return fetch(`/v1/businesses/${businessId}/change-to-members`, {
      method: 'POST',
      body: { user_ids: userIds },
    })
  },
  async revokeAdmin(businessId: string, userId: string) {
    return this.revokeAdmins(businessId, [userId])
  },
  async getMembers(businessId: string) {
    const { members } = await fetch<{ members: MemberItem[]; totalNumMembers: number }>(
      `/v1/businesses/${businessId}/members`
    )
    return members
  },
  async addMembers(businessId: string, userEmails: string[]) {
    return fetch<{
      business: BusinessData
      errors?: { key: string; message: string; variables: Record<string, string> }[]
    }>(`/v1/businesses/${businessId}/members`, {
      method: 'POST',
      body: { users: userEmails },
    })
  },
  async removeMembers(businessId: string, userIds: string[]) {
    return fetch(`/v1/businesses/${businessId}/remove-members`, {
      method: 'POST',
      body: { user_ids: userIds },
    })
  },
  async removeMember(businessId: string, memberId: string) {
    return this.removeMembers(businessId, [memberId])
  },
  async getInvitations(businessId: string) {
    const { invitedMembers } = await fetch<{ invitedMembers: { username: string; email: string }[] }>(
      `/v2/businesses/${businessId}/invitations`,
      { parseResponse: destr }
    )
    return invitedMembers
  },
  async getApiUsers(businessId: string) {
    const { apiUsers } = await fetch<{ apiUsers: APIKeyList }>(`/v1/businesses/${businessId}/apis`)
    return apiUsers
  },
  async createApiUser(businessId: string, data: { type: string; description: string }) {
    return fetch<{ user: APIKeyCreateUser; status?: string }>(`/v1/businesses/${businessId}/apis`, {
      method: 'POST',
      body: data,
    })
  },
  async updateApiUser(businessId: string, data: { username: string; description: string }) {
    return fetch<{ user: APIKeyUpdate }>(`/v1/businesses/${businessId}/apis/${data.username}`, {
      method: 'PUT',
      body: { description: data.description },
    })
  },
  async deleteApiUser(businessId: string, username: string) {
    return fetch(`/v1/businesses/${businessId}/apis/${username}`, { method: 'DELETE' })
  },
  async resetApiUser(businessId: string, username: string) {
    return fetch<{ user: APIKeyReset }>(`/v1/businesses/${businessId}/apis/${username}/reset`, { method: 'PUT' })
  },
  async revokeInvite(businessId: string, userId: string) {
    return fetch(`/v1/businesses/${businessId}/invitations/${userId}`, { method: 'DELETE' })
  },
  async getSeals(businessId: string) {
    const response = await fetch<{ seals: { name: string; label: string; nApi: number; nMembers: number }[] }>(
      `/v1/businesses/${businessId}/seals`
    )
    return response.seals.map<SealInfo>(seal => {
      return {
        accountName: seal.name,
        displayName: seal.label,
        amountApiKeys: seal.nApi,
        amountMembers: seal.nMembers,
      }
    })
  },
  async addDomain(businessId: string, domain: string): Promise<DomainEntry[]> {
    const response = await fetch<{ business: BusinessData }>(`/v1/businesses/${businessId}/domains`, {
      method: 'POST',
      body: { domain },
    })

    return response.business.domains
  },
  async removeDomain(businessId: string, domain: string): Promise<DomainEntry[]> {
    const response = await fetch<{ business: BusinessData }>(
      `/v1/businesses/${businessId}/domains/${encodeURIComponent(domain)}`,
      { method: 'DELETE' }
    )

    return response.business.domains
  },
  async getPlanUsage(businessId: string) {
    return fetch<UsageData>(`/v2/businesses/${businessId}/subscriptions/usage`, { parseResponse: destr })
  },
  async createChargebeeSessionPortal(businessId: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    return fetch<Function>(`/v2/businesses/${businessId}/chargebee/portal_session`, {
      method: 'POST',
    })
  },
  /**
   * Updates the number of seats used by the business.
   *
   * We use a timestamp to let Stripe know about the cutoff date when adding partial seats.
   *
   * @param businessId The ID of the business to update
   * @param newTotal The new total number of seats
   * @param timestamp The timestamp of the cutoff date for partial seats
   */
  async updateUsedSeats(businessId: string, newTotal: number, timestamp?: string) {
    return fetch<ActivePricePlan['seats']>(`/v2/businesses/${businessId}/subscriptions/seats`, {
      method: 'PUT',
      body: { seats: newTotal, timestamp },
      parseResponse: destr,
    })
  },
  async getCustomerPortalUrl(businessId: string) {
    const { redirectUrl } = await fetch<{ redirectUrl: string }>(`/v2/businesses/${businessId}/stripe/cpsession`, {
      parseResponse: destr,
    })

    return redirectUrl
  },
  async updateContactInfo(businessId: string, contactInfo: Partial<BusinessContactInfo>) {
    return fetch<BusinessData>(`/v2/businesses/${businessId}/base`, {
      method: 'PUT',
      body: objectToSnakeCase(contactInfo),
      parseResponse: destr,
    })
  },
  async updateSupportContact(
    businessId: string,
    contactInfo: { email: string; name: string; phone: string; settings: { supportContact: SupportContactData } }
  ) {
    // FIXME: The response of this request has a nested `business` property, which is inconsistent when compared to similar requests
    const { business } = await fetch<{ business: BusinessData }>(`/v1/businesses/${businessId}`, {
      method: 'PUT',
      body: objectToSnakeCase(contactInfo),
    })

    return business
  },
  async getDashboard(businessId: string) {
    return fetch<DashboardData>(`/v2/businesses/${businessId}/statistics`)
  },
})

export default businessRepository
