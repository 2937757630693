class WebPFallback {
  private machine: { decode: (data: Uint8Array) => Promise<string> } | undefined
  private inProgress: number | undefined
  private queue: number[]
  private map: Record<number, string | Uint8Array>
  private _canHandleWebP: boolean

  constructor() {
    this.queue = []
    this.map = {}

    // const elem = document.createElement('canvas')
    // elem.width = elem.height = 1
    // if (elem.getContext && elem.getContext('2d')) {
    //   // was able or not to get WebP representation
    //   this._canHandleWebP = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
    // } else {
    //   // very old browser like IE 8, canvas not supported
    //   this._canHandleWebP = false
    // }

    this._canHandleWebP = true

    this.testWebP()
      .then((result: boolean) => {
        this._canHandleWebP = result
      })
      .catch(() => {
        this._canHandleWebP = false
      })

    this.processQueue = this.processQueue.bind(this)
    this.returnValue = this.returnValue.bind(this)
    this.init = this.init.bind(this)
    this.process = this.process.bind(this)
    this.canHandleWebP = this.canHandleWebP.bind(this)
  }

  private async processQueue() {
    if (!this.machine || this.inProgress !== undefined) return
    this.inProgress = this.queue.shift()
    if (!this.inProgress) return
    const output = this.map[this.inProgress]
    if (typeof output === 'string') {
      this.inProgress = undefined
      void this.processQueue()
      return
    }
    this.map[this.inProgress] = await this.machine.decode(output)
    this.inProgress = undefined
    void this.processQueue()
  }

  private returnValue(data: Uint8Array) {
    const id = ~~(Math.random() * 10000000)
    this.map[id] = data
    this.queue.push(id)
    void this.processQueue()
    return new Promise<string>(resolve => {
      const interval = setInterval(() => {
        const output = this.map[id]
        if (!output) {
          clearInterval(interval)
          return
        }
        if (typeof output === 'string') {
          clearInterval(interval)
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete this.map[id]
          resolve(output)
        }
      }, 100)
    })
  }

  private async init() {
    if (this.machine) return
    const { WebpMachine } = await import(/* webpackChunkName: "webpherocjs" */ 'webp-hero/dist-cjs/index.js')
    this.machine = new WebpMachine()
    // wasm needs time to initialise, 1 second should be more than enough
    await this.delay(1000)
  }

  private delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  private testWebP() {
    return new Promise<boolean>(resolve => {
      const webP = new Image()
      webP.src =
        'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA'
      webP.onload = webP.onerror = () => {
        resolve(webP.height === 2)
      }
    })
  }

  public async process(data: Uint8Array) {
    await this.init()
    return await this.returnValue(data)
  }

  public canHandleWebP() {
    return this._canHandleWebP
  }
}

export default defineNuxtPlugin({
  name: 'webp',
  setup() {
    const isLegacy = import.meta.env.LEGACY === 'true'

    if (!isLegacy)
      return {
        provide: {
          webp: {
            supported() {
              return true
            },
          },
        },
      }

    const handler = new WebPFallback()

    return {
      provide: {
        webp: {
          decode: handler.process,
          supported: handler.canHandleWebP,
        },
      },
    }
  },
})
