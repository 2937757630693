<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1403_3569)">
      <path
        d="M15.2425 21.914L21.914 15.2425C22.0997 15.0568 22.2471 14.8363 22.3476 14.5936C22.4481 14.351 22.4998 14.0909 22.4998 13.8282C22.4998 13.5656 22.4481 13.3055 22.3476 13.0629C22.2471 12.8202 22.0997 12.5997 21.914 12.414L11 1.5H1.5V11L12.414 21.914C12.5997 22.0997 12.8202 22.2471 13.0629 22.3476C13.3055 22.4481 13.5656 22.4998 13.8282 22.4998C14.0909 22.4998 14.351 22.4481 14.5936 22.3476C14.8363 22.2471 15.0568 22.0997 15.2425 21.914Z"
        stroke="#293D66"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z"
        stroke="#293D66"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
