<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,21.8c-5.4,0-9.8-4.4-9.8-9.8S6.6,2.2,12,2.2s9.8,4.4,9.8,9.8S17.4,21.8,12,21.8z M17.7,10.8H6.3c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h11.3c0.7,0,1.2-0.5,1.2-1.2S18.3,10.8,17.7,10.8z"
    />
  </svg>
</template>
