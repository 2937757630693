export const useExitUrlStore = defineStore('exitUrl', {
  state: () => ({
    exitInterval: 0,
    exitDelay: 0,
    autoRedirect: false,
    shouldDeclineRedirect: false,
    lastSignatureRequestId: '',
    timeout: 0,
    exitUrl: '',
    errorUrl: '',
    declineUrl: '',
  }),
  actions: {
    areExitUrlsApplicable(signatureRequestIDs: string[]) {
      // This functions expects an array of SR IDs which is the structure of
      // $store's processedSignatureRequests

      // The exit URLs are applicable if one was specified in this session
      // and the user just signed the corresponding signature request not in a
      // batch signature process
      return signatureRequestIDs.length === 1 && signatureRequestIDs[0] === this.lastSignatureRequestId
    },
    initAutoRedirect(signatureRequestID: string) {
      if (this.exitInterval) return
      // Init automatic redirection if there was an exitURL given based on
      // the redirection timeout set.
      this.exitDelay = this.timeout
      if (this.areExitUrlsApplicable([signatureRequestID])) {
        this.exitInterval = window.setInterval(() => {
          this.exitDelay -= 1
          if (this.exitDelay <= 0) {
            // redirect to exitURL
            this.leaveToExitUrl()
          }
        }, 1000)
      }
    },
    clearInterval() {
      window.clearInterval(this.exitInterval)
      this.exitInterval = 0
    },
    cancelRedirect() {
      this.clearInterval()
    },
    leaveToExitUrl() {
      // Leave to exitURL and clear state
      this.clearInterval()
      const redirectTo = this.exitUrl
      if (redirectTo) {
        this.$reset()
        window.location.href = redirectTo
      }
    },
    leaveToErrorUrl() {
      // Leave to errorURL and clear state
      const redirectTo = this.errorUrl
      if (redirectTo) {
        this.$reset()
        window.location.href = redirectTo
      }
    },
    leaveToDeclineUrl() {
      // Leave to declineURL and clear state
      const redirectTo = this.declineUrl
      if (redirectTo) {
        this.$reset()
        window.location.href = redirectTo
      }
    },
  },
})
