<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M6,11h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H6c-0.6,0-1,0.4-1,1S5.4,11,6,11z M6,15h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H6
  c-0.6,0-1,0.4-1,1S5.4,15,6,15z M18,4H2C1.4,4,1,4.4,1,5v18c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V5C19,4.4,18.6,4,18,4z M17,22H3V6
  h14V22z M6,19h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H6c-0.6,0-1,0.4-1,1S5.4,19,6,19z M22,0H5C4.4,0,4,0.4,4,1s0.4,1,1,1h16v19
  c0,0.6,0.4,1,1,1s1-0.4,1-1V1C23,0.4,22.6,0,22,0z"
    />
  </svg>
</template>
