<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M13.5,9H4.7C4.3,9,3.9,9.4,3.9,9.8V20c0,0.4,0.4,0.8,0.8,0.8h8.7c0.4,0,0.8-0.4,0.8-0.8V9.8C14.3,9.4,13.9,9,13.5,9zM12.7,19.2H5.5v-8.6h7.1V19.2z M16.4,6.1H7.6c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h7.9v9.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V6.9C17.2,6.5,16.8,6.1,16.4,6.1z M19.3,3.2h-8.7c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h7.9v9.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V4C20.1,3.6,19.7,3.2,19.3,3.2z"
    />
  </svg>
</template>
