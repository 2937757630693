<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,21H3v-3c0-0.9,0.5-1.6,1.3-1.9c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7V7c0-3.3-2.7-6-6-6S5,3.7,5,7v1
  c0,1.9,0.9,3.9,2.3,5.3c-1.4,0.2-2.7,0.6-3.6,0.9C2.1,14.8,1,16.3,1,18v4c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1S12.6,21,12,21z M7,8V7
  c0-2.2,1.8-4,4-4s4,1.8,4,4v1c0,2.3-1.8,5-4,5S7,10.3,7,8z M22.2,14.5c-0.5-0.4-1.2-0.4-1.7,0.1l-3.2,4.6l-1.8-1.9
  c-0.5-0.5-1.2-0.5-1.7,0c-0.5,0.5-0.5,1.2,0,1.7l2.7,2.8c0.2,0.2,0.5,0.4,0.8,0.4h0c0.3,0,0.6-0.1,0.9-0.4l4.1-5.5
  C22.7,15.7,22.7,15,22.2,14.5z"
    />
  </svg>
</template>
