<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    width="40"
    height="40"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <circle fill="#006FE6" cx="20" cy="20" r="20" />
    <circle fill="#FFF" transform="matrix(1 0 0 -1 0 20)" cx="20" cy="10" r="3" />
    <path
      d="M18.907 16h2.186a1 1 0 0 1 1 .997l.036 12.997a2 2 0 0 1-2 2.006h-.258a2 2 0 0 1-2-2.006l.036-12.997a1 1 0 0 1 1-.997z"
      fill="#FFF"
    />
  </svg>
</template>
