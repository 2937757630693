interface DirectSignState {
  username: string
  password: string
}

export const useDirectSignStore = defineStore('directSign', {
  state: (): DirectSignState => ({
    username: '',
    password: '',
  }),
  getters: {
    auth(): { username: string; password: string } {
      if (this.username && this.password) return { username: this.username, password: this.password }
      return { username: '', password: '' }
    },
    directSignB64AuthHeader(): string {
      let str = ''
      const { username, password } = this.auth
      if (username && password) {
        str = btoa(`${username}:${password}`)
      }
      return str
    },
    directSignSession(): DirectSignSession | null {
      return this.auth.username ? { auth: this.auth } : null
    },
    isDirectSign(): boolean {
      return Boolean(this.directSignSession)
    },
    immutableEidFields(): string[] {
      const userStore = useUserStore()

      const fields = userStore.attributes.immutable_eid_fields

      return fields?.length ? fields : ['all']
    },
  },
  actions: {
    setDirectSign(username: string, password: string) {
      this.username = username
      this.password = password
    },
    // TODO: Combine with user store
    async update(data: DirectUserUpdateData): Promise<void> {
      const userStore = useUserStore()
      const { directUserRepository } = useApi()

      await directUserRepository.update(data)
      await userStore.fetchUser()
    },
  },
})
