<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11.3,17.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1s0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2l6-6
  c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L13,14.1V1.5c0-0.6-0.4-1-1-1s-1,0.4-1,1v12.6L6.7,9.8c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
  L11.3,17.2z M22,15.5c-0.6,0-1,0.4-1,1v5H3v-5c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1v-6
  C23,15.9,22.6,15.5,22,15.5z"
    />
  </svg>
</template>
