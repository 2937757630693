<script setup></script>

<template>
  <svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.9 38.6L35.6 29.7C38 26.6 39.4 22.7 39.4 18.5C39.3 8.3 31 0 20.8 0C10.6 0 2.30004 8.3 2.30004 18.5C2.30004 22.8 3.80004 26.8 6.30004 30L1.10004 38.7C0.400039 39.9 0.40004 41.4 1.20004 42.5C1.90004 43.4 3.00004 44 4.10004 44C4.30004 44 4.50004 44 4.80004 43.9L21 41L37.2 44C38.6 44.3 39.9 43.7 40.7 42.5C41.6 41.3 41.6 39.8 40.9 38.6ZM5.60004 18.5C5.60004 10.1 12.4 3.3 20.8 3.3C29.2 3.3 36 10.1 36 18.5C36 26.9 29.2 33.7 20.8 33.7C16.4 33.7 12.5 31.8 9.70004 28.8C9.60004 28.7 9.50004 28.6 9.40004 28.5C7.10004 25.9 5.60004 22.4 5.60004 18.5ZM38 40.6C37.9 40.7 37.8 40.7 37.8 40.7L21.3 37.7C21.1 37.7 20.9 37.7 20.7 37.7L4.20004 40.7C4.20004 40.7 4.10004 40.7 4.00004 40.6C3.90004 40.5 4.00004 40.4 4.00004 40.4L8.70004 32.5C12 35.3 16.2 37.1 20.8 37.1C25.6 37.1 29.9 35.3 33.2 32.3L38 40.4C38.1 40.4 38.1 40.5 38 40.6ZM21.2 22.5C22.2 22.5 23.1 21.7 23.1 20.6C23.1 19.5 22.3 18.7 21.2 18.7C20.1 18.7 19.3 19.5 19.3 20.6C19.3 21.7 20.1 22.5 21.2 22.5ZM20.8 29.4C25.8 29.4 29.9 25.3 29.9 20.2C29.9 15.1 25.8 11 20.8 11C15.8 11 11.7 15.1 11.7 20.2C11.7 25.3 15.8 29.4 20.8 29.4ZM20.8 14.4C24 14.4 26.6 17 26.6 20.2C26.6 23.4 24 26 20.8 26C17.6 26 15 23.4 15 20.2C15 17 17.6 14.4 20.8 14.4Z"
      fill="currentColor"
    />
  </svg>
</template>
