<script lang="ts" setup>
const widgetStore = useWidgetStore()

const activeOverlay = computed(() => widgetStore.activeOverlay)

const isActive = ref(false)

watch(activeOverlay, overlay => {
  if (overlay) {
    document.body.classList.add('overflow-clip')
    isActive.value = true
  } else {
    isActive.value = false
    document.body.classList.remove('overflow-clip')
  }
})

const overlayComponent = computed(() => {
  if (!activeOverlay.value) return null

  const name = activeOverlay.value.name

  return defineAsyncComponent(() => import(`./overlays/${name}.vue`))
})

const isLoading = ref(false)

const onAction = async (type: OverlayAction, data?: unknown) => {
  isLoading.value = true
  try {
    await activeOverlay.value?.onAction?.(type, data)
    isLoading.value = false
    if (type === 'complete') {
      widgetStore.activeOverlay = null
    }
  } catch {
    isLoading.value = false
  }
}

const onClose = () => {
  widgetStore.activeOverlay = null
}
</script>

<template>
  <div v-if="isActive">
    <component
      :is="overlayComponent"
      v-if="overlayComponent"
      :key="activeOverlay?.id"
      v-bind="activeOverlay?.props ?? {}"
      :is-loading="isLoading"
      @action="onAction"
      @close="onClose"
    />
    <v-overlay :model-value="true" scrim="white" opacity="1" persistent :style="{ zIndex: 1900 }" />
  </div>
</template>
