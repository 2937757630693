<script lang="ts" setup>
const props = defineProps<{
  source: string
  width?: number
  height?: number
}>()

const imgHeight = computed(() => props.height ?? 'auto')

const src = computed(() => {
  if (/\/.+\.[a-z]+$/.test(props.source)) {
    // Assume that the source is a full path
    return props.source
  } else {
    return `/img/${props.source}.webp`
  }
})
</script>

<template>
  <nuxt-img class="skr-image" loading="lazy" :src="src" :width="width" :height="imgHeight" />
</template>

<style lang="scss" scoped>
.skr-image {
  display: block;
  max-width: 100%;
  max-height: max-content;
  margin: 0 auto;
}
</style>
