<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8 7.50023C20 7.50023 20.1 7.50023 20.3 7.40023L22 6.40023C22.5 6.10023 22.6 5.50023 22.4 5.00023C22.1 4.50023 21.5 4.40023 21 4.60023L19.3 5.60023C18.8 5.90023 18.7 6.50023 18.9 7.00023C19.1 7.30023 19.5 7.50023 19.8 7.50023ZM16.6 2.20023C16.3 2.00023 16 1.90023 15.7 2.10023L0.7 7.10023C0.3 7.20023 0 7.60023 0 8.00023V14.0002C0 14.4002 0.3 14.8002 0.7 14.9002L2 15.4002V19.0002C2 19.4002 2.3 19.8002 2.7 20.0002L9.7 22.0002C9.8 22.0002 9.9 22.0002 10 22.0002C10.2 22.0002 10.4 21.9002 10.6 21.8002C10.8 21.6002 11 21.3002 11 21.0002V18.4002L15.7 20.0002C15.8 20.0002 15.9 20.1002 16 20.1002C16.2 20.1002 16.4 20.0002 16.6 19.9002C16.9 19.7002 17 19.4002 17 19.1002V3.00023C17 2.70023 16.8 2.40023 16.6 2.20023ZM9 19.7002L4 18.3002V16.1002L9 17.8002V19.7002ZM15 17.6002L2 13.3002V8.70023L15 4.40023V17.6002ZM22 15.6002L20.3 14.6002C19.8 14.3002 19.2 14.5002 18.9 15.0002C18.6 15.5002 18.8 16.1002 19.3 16.4002L21 17.4002C21.2 17.5002 21.3 17.5002 21.5 17.5002C21.8 17.5002 22.2 17.3002 22.4 17.0002C22.6 16.5002 22.5 15.9002 22 15.6002ZM23 10.0002H21C20.4 10.0002 20 10.4002 20 11.0002C20 11.6002 20.4 12.0002 21 12.0002H23C23.6 12.0002 24 11.6002 24 11.0002C24 10.4002 23.6 10.0002 23 10.0002Z"
      fill="#FF531A"
    />
  </svg>
</template>
