import { createI18n, type IntlDateTimeFormats, type IntlNumberFormats } from 'vue-i18n'

const datetimeFormats: IntlDateTimeFormats = {
  'en-gb': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'de-ch': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'fr-ch': {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  it: {
    datetime: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

const numberFormats: IntlNumberFormats = {
  // NB: Technically, this is not correct, since the UK does not use EUR,
  // but `en-gb` is our fallback and EUR our default
  'en-gb': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'code',
    },
  },
  'de-ch': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      currencyDisplay: 'code',
    },
  },
  'fr-ch': {
    currency: {
      style: 'currency',
      currency: 'CHF',
      currencyDisplay: 'code',
    },
  },
}

export default defineNuxtPlugin({
  name: 'i18n',
  async setup(nuxtApp) {
    const messages = (await import('@intlify/unplugin-vue-i18n/messages')).default

    const i18n = createI18n({
      datetimeFormats,
      numberFormats,
      legacy: false,
      globalInjection: true,
      locale: 'en-gb', // Default locale
      ...(nuxtApp.$config.public.mode !== 'development' ? { fallbackLocale: 'en-gb' } : {}),
      messages: {
        'en-gb': messages!.en,
        'de-ch': messages!.de,
        'fr-ch': messages!.fr,
        it: messages!.it,
      },
    })

    nuxtApp.vueApp.use(i18n)

    return {
      provide: {
        i18nInstance: i18n,
        i18n: i18n.global,
      },
    }
  },
})
