<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M23.4,9.6c-0.1-0.1-0.1-0.2-0.2-0.3l-4-4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L20.1,9h-8.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h8.6
  l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l4-4c0.1-0.1,0.2-0.2,0.2-0.3C23.5,10.1,23.5,9.9,23.4,9.6z
   M13.5,12c-0.6,0-1,0.4-1,1v3h-3V7c0-0.3-0.2-0.6-0.4-0.8L4.6,3h7.9v4c0,0.6,0.4,1,1,1s1-0.4,1-1V2c0-0.6-0.4-1-1-1h-12
  c0,0-0.1,0-0.1,0C1.3,1,1.2,1,1,1.1C0.9,1.2,0.8,1.3,0.7,1.4C0.6,1.5,0.6,1.6,0.5,1.7c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3v15
  c0,0.3,0.2,0.6,0.4,0.8l7,5C8.1,22.9,8.3,23,8.5,23c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9v-4h4c0.6,0,1-0.4,1-1v-4
  C14.5,12.4,14.1,12,13.5,12z M7.5,20.1l-5-3.6V3.9l5,3.6V20.1z"
    />
  </svg>
</template>
