<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8848 6.75012C10.8848 6.20647 10.444 5.76575 9.90039 5.76575C9.35674 5.76575 8.91602 6.20647 8.91602 6.75012V8.91577H6.75049C6.20683 8.91577 5.76611 9.35649 5.76611 9.90015C5.76611 10.4438 6.20683 10.8845 6.75049 10.8845H8.91602V13.0502C8.91602 13.5939 9.35674 14.0346 9.90039 14.0346C10.444 14.0346 10.8848 13.5939 10.8848 13.0502V10.8845H13.0506C13.5942 10.8845 14.0349 10.4438 14.0349 9.90015C14.0349 9.35649 13.5942 8.91577 13.0506 8.91577H10.8848V6.75012Z"
      fill="currentColor"
    />
    <path
      d="M22.5001 22.5003L15.8398 15.8401"
      stroke="currentColor"
      stroke-width="1.96875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.90011 18.3002C14.5394 18.3002 18.3002 14.5394 18.3002 9.90011C18.3002 5.26086 14.5394 1.5 9.90011 1.5C5.26086 1.5 1.5 5.26086 1.5 9.90011C1.5 14.5394 5.26086 18.3002 9.90011 18.3002Z"
      stroke="currentColor"
      stroke-width="1.96875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
