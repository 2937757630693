<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17,15h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1S17.6,15,17,15z M17,11h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2
      c0.6,0,1-0.4,1-1S17.6,11,17,11z M6.3,16.7C6.5,16.9,6.7,17,7,17s0.5-0.1,0.7-0.3l4-4c0.4-0.4,0.4-1,0-1.4l-4-4
      c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L9.6,12l-3.3,3.3C5.9,15.7,5.9,16.3,6.3,16.7z M13,9h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4
      c-0.6,0-1,0.4-1,1S12.4,9,13,9z M22,1H2C1.4,1,1,1.4,1,2v20c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1V2C23,1.4,22.6,1,22,1z M21,21H3V3
      h18V21z"
    />
  </svg>
</template>
