<script setup></script>

<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5330_15629)">
      <path
        d="M14.625 1.12402V7.49902H21"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 11.624V7.49902L14.625 1.12402H3V22.874H14.25"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.875 18.374H7.125"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.875 14.624H7.125"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 10.874H7.125"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.25 7.12402H7.125"
        stroke="currentColor"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.185 22.6752V18.2388"
        stroke="#00A671"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5031 13.7111L14.201 17.5712L18.3117 22.9915L22.1301 17.7819L20.6137 13.7111"
        stroke="#00A671"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3241 17.0955C19.3241 16.4767 18.8139 15.9751 18.1845 15.9751C17.5551 15.9751 17.0449 16.4767 17.0449 17.0955C17.0449 17.7142 17.5551 18.2158 18.1845 18.2158C18.8139 18.2158 19.3241 17.7142 19.3241 17.0955Z"
        stroke="#00A671"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5330_15629">
        <rect width="24" height="24" fill="white" transform="translate(0 -0.000976562)" />
      </clipPath>
    </defs>
  </svg>
</template>
