import Cookies from 'js-cookie'

export default defineNuxtRouteMiddleware(to => {
  const { $config } = useNuxtApp()

  const { splitTesting } = $config.public

  if (!splitTesting.branch) return

  const { id: userId, attributes: userAttributes } = storeToRefs(useUserStore())

  const routeName = to.name ?? ''

  if (userId && userAttributes.value.frontend_version?.[0] === splitTesting.branch) {
    // Don't set the cookie again if unchanged
    if (Cookies.get('nf_ab') === splitTesting.branch) return

    const date = new Date()
    const expires = date.getTime() + 1000 * 3600 * 24 * 365
    date.setTime(expires)

    Cookies.set('nf_ab', splitTesting.branch, { expires: date })

    window.location.reload()
  } else if (userId && Cookies.get('nf_ab') === splitTesting.branch) {
    // We don't want to interrupt with a reload on auth routes, that will happen on next login if necessary
    if (['login', 'logout'].includes(routeName)) return

    // Reset to default branch
    Cookies.remove('nf_ab')

    window.location.reload()
  }
})
