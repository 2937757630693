<script setup></script>

<template>
  <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9 34.725V23.725C6.9 22.725 6.1 22.025 5.2 22.025C4.2 22.025 3.5 22.825 3.5 23.725V34.725C3.5 35.725 4.3 36.425 5.2 36.425C6.1 36.425 6.9 35.625 6.9 34.725ZM17.8 34.725V23.725C17.8 22.725 17 22.025 16.1 22.025C15.2 22.025 14.4 22.825 14.4 23.725V34.725C14.4 35.725 15.2 36.425 16.1 36.425C17 36.425 17.8 35.625 17.8 34.725ZM42.2 38.825H1.8C0.8 38.825 0.1 39.625 0.1 40.525C0.1 41.425 0.9 42.225 1.8 42.225H42.3C43.3 42.225 44 41.425 44 40.525C44 39.625 43.2 38.825 42.2 38.825ZM43.1 11.225L22.9 0.225C22.4 -0.075 21.7 -0.075 21.2 0.225L1 11.225C0.4 11.525 0 12.125 0 12.725V18.225C0 19.225 0.8 19.925 1.7 19.925H42.2C43.2 19.925 43.9 19.125 43.9 18.225V12.725C44 12.125 43.6 11.525 43.1 11.225ZM40.5 16.525H3.5V13.825L22 3.725L40.5 13.825V16.525ZM29.7 34.725V23.725C29.7 22.725 28.9 22.025 28 22.025C27.1 22.025 26.3 22.825 26.3 23.725V34.725C26.3 35.725 27.1 36.425 28 36.425C28.9 36.425 29.7 35.625 29.7 34.725ZM20.3 11.125C20.3 12.125 21.1 12.925 22.1 12.925C23.1 12.925 23.9 12.125 23.9 11.125C23.9 10.125 23.1 9.325 22.1 9.325C21.1 9.325 20.3 10.125 20.3 11.125ZM40.6 34.725V23.725C40.6 22.725 39.8 22.025 38.9 22.025C37.9 22.025 37.2 22.825 37.2 23.725V34.725C37.2 35.725 38 36.425 38.9 36.425C39.8 36.425 40.6 35.625 40.6 34.725Z"
      fill="currentColor"
    />
  </svg>
</template>
