<script setup></script>

<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1898 4.14691C12.4701 3.80719 12.7721 3.69999 12.9998 3.69999H30.9998C31.2275 3.69999 31.5295 3.80719 31.8098 4.14691C32.0977 4.49593 32.2998 5.02831 32.2998 5.63635V6.29999H11.6998V5.63635C11.6998 5.02831 11.9019 4.49593 12.1898 4.14691ZM8.2998 9.69999V6.29999V5.63635C8.2998 4.31554 8.72988 2.99808 9.56715 1.9832C10.4121 0.959023 11.6362 0.299988 12.9998 0.299988H30.9998C32.3634 0.299988 33.5875 0.959022 34.4325 1.9832C35.2697 2.99808 35.6998 4.31555 35.6998 5.63635V6.29999V9.69999V30.3V33.7V38.3636C35.6998 39.6844 35.2697 41.0019 34.4325 42.0168C33.5875 43.041 32.3634 43.7 30.9998 43.7H12.9998C11.6362 43.7 10.4121 43.041 9.56715 42.0168C8.72988 41.0019 8.2998 39.6844 8.2998 38.3636V33.7V30.3V9.69999ZM32.2998 9.69999V30.3H11.6998V9.69999H32.2998ZM11.6998 38.3636V33.7H32.2998V38.3636C32.2998 38.9717 32.0977 39.504 31.8098 39.8531C31.5295 40.1928 31.2275 40.3 30.9998 40.3H12.9998C12.7721 40.3 12.4701 40.1928 12.1898 39.8531C11.9019 39.504 11.6998 38.9717 11.6998 38.3636ZM22 39C23.1046 39 24 38.1046 24 37C24 35.8954 23.1046 35 22 35C20.8954 35 20 35.8954 20 37C20 38.1046 20.8954 39 22 39Z"
      fill="currentColor"
    />
  </svg>
</template>
