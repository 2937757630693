const ssoRepository = (fetch: CustomFetch) => ({
  async getUrl(email: string) {
    const response = await fetch<{ url: string | null }>('/v1/identity_providers', {
      method: 'POST',
      body: { email },
    })

    return response.url
  },
})

export default ssoRepository
