<script setup></script>

<template>
  <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 9L9 1V6C14.6 6 22 8.2 22 18C19.3 13.4 16.5 12 9 12V17L1 9Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
