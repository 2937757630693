import type { FetchOptions } from 'ofetch'

type RetryOptions = Pick<FetchOptions, 'retry' | 'retryDelay' | 'onResponseError'>

export const getRetryOptions = (retries: number): RetryOptions => {
  return {
    retry: retries,
    retryDelay: ({ options, response }) => {
      if (response?.status === 503) {
        const retryAfter = response.headers.get('Retry-After')

        if (retryAfter) return parseInt(retryAfter) * 1000
      }

      if (typeof options.retry !== 'number') return 1000

      const currentRetry = retries - options.retry

      return Math.min(100 * 2 ** currentRetry, 5000)
    },
    onResponseError: ({ response, options }) => {
      if (!(options.retry && options.retry > 0)) {
        console.debug('Failed to load data, server returned ', response._data)
      }
    },
  }
}

const documentRepository = (fetch: CustomFetch) => ({
  async getDocumentData(documentId: string, isSigned?: boolean) {
    const params = new URLSearchParams()
    if (isSigned) params.append('signed', 'true')

    const response = await fetch.raw<Blob>(`v3/documents/${documentId}/download${params.size ? `?${params}` : ''}`)
    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${documentId}.pdf`,
    }
  },
  async getProtocol(srId: string, type: SignatureProtocolType) {
    const response = await fetch.raw<Blob>(`v3/signature-requests/${srId}/report?type=${type}`)

    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${srId}_protocol.pdf`,
    }
  },
  async getAttachment(srId: string, attachmentId: string) {
    const response = await fetch.raw<Blob>(`v3/signature-requests/${srId}/attachments/${attachmentId}/content`)

    const data = response._data!
    const dataType = data.type.split('/')[1]
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${srId}_attachment-${attachmentId}.${dataType}`,
    }
  },
  async removeAttachment(srId: string, attachmentId: string) {
    await fetch(`v3/signature-requests/${srId}/attachments/${attachmentId}`, {
      method: 'DELETE',
    })
  },
  async getDocument(documentId: string) {
    return fetch<DocumentData>(`/v3/documents/${documentId}`)
  },
  async removeDocument(documentId: string) {
    return fetch<DocumentData>(`/v3/documents/${documentId}`, {
      method: 'DELETE',
    })
  },
  async getDocumentPage(documentId: string, pageNumber: number, scale = 100) {
    return fetch<Blob>(`v3/documents/${documentId}/pages/${pageNumber}?scale=${scale}`, { ...getRetryOptions(10) })
  },
  async getDocumentPageByUrl(url: string) {
    return fetch<Blob>(url, { ...getRetryOptions(10) })
  },
})

export default documentRepository
