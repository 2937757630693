const tanRepository = (fetch: CustomFetch) => ({
  async resend(challengeId: string) {
    await fetch(`api/resend/${challengeId}`, { method: 'POST' })
  },
  async verify(challengeId: string, secret: string) {
    return fetch<{ status: string }>(`api/respond/${challengeId}`, {
      method: 'POST',
      body: { secret },
    })
  },
  async cancelVerification(challengeId: string) {
    await fetch(`api/cancel/${challengeId}`, { method: 'POST' })
  },
})

export default tanRepository
