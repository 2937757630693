<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11.6,20.9h-9v-3c0-0.9,0.5-1.6,1.3-1.9c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7v-1c0-3.3-2.7-6-6-6s-6,2.7-6,6v1
      c0,1.9,0.9,3.9,2.3,5.3c-1.4,0.2-2.7,0.6-3.6,0.9c-1.6,0.5-2.7,2.1-2.7,3.8v4c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1
      S12.1,20.9,11.6,20.9z M6.6,7.9v-1c0-2.2,1.8-4,4-4s4,1.8,4,4v1c0,2.3-1.8,5-4,5S6.6,10.2,6.6,7.9z M23,17C23,17,23,17,23,17
      c-0.8-1.2-2.6-3.3-5.2-3.3c-2.6,0-4.4,2.1-5.2,3.3c-0.5,0.8-0.6,1.9,0,2.7c0,0,0,0,0,0c0.8,1.2,2.6,3.3,5.2,3.3
      c2.6,0,4.4-2.1,5.2-3.3C23.6,18.9,23.6,17.8,23,17z M21.4,18.6c-0.6,0.9-1.9,2.4-3.5,2.4c-1.7,0-2.9-1.5-3.5-2.4c0,0,0,0,0,0
      c-0.1-0.2-0.1-0.4,0-0.5c0.6-0.9,1.9-2.4,3.5-2.4c1.7,0,2.9,1.5,3.5,2.4C21.5,18.3,21.5,18.5,21.4,18.6z M17.8,17
      c-0.8,0-1.5,0.6-1.5,1.4s0.7,1.4,1.5,1.4s1.5-0.6,1.5-1.4S18.6,17,17.8,17z"
    />
  </svg>
</template>
