import type { LocalizedCountryNames } from 'i18n-iso-countries'

export const useLanguageStore = defineStore('language', () => {
  const { $i18n, $countries, $userpilot } = useNuxtApp()

  const systemStore = useSystemStore()

  const platform = computed(() => systemStore.platform)

  const supportedLocales = ref<LocaleName[]>(['en-gb', 'de-ch', 'fr-ch', 'it'])

  const selectedLocale = ref<LocaleName>('en-gb')
  const selectedLanguage = computed<Language>(() => selectedLocale.value.split('-')[0] as Language)

  const nonSelectableLocales = ['it']
  const selectableLocales = ref<LocaleName[]>(supportedLocales.value.filter(l => !nonSelectableLocales.includes(l)))

  const countryList = ref<CountryEntry[]>([])

  const updateCountryList = () => {
    const countries = $countries.getNames(selectedLanguage.value) as LocalizedCountryNames<{ select: 'official' }>

    const allCountryCodes = Object.keys(countries)

    // Manually define our featured countries
    const featuredCountryCodes: string[] = platform.value === 'CH' ? ['CH', 'DE', 'FR', 'AT'] : ['DE', 'CH', 'FR', 'AT']

    // Generate a featured countries list in the format { value: 'DE', text: 'Germany' }
    const featuredCountries: { value: string; text: string }[] = featuredCountryCodes.map(countryCode => ({
      value: countryCode,
      text: countries[countryCode],
    }))

    // Generate a list of all other countries in the format { value: 'DE', text: 'Germany' },
    // sorted alphabetically
    const allOtherCountries: { value: string; text: string }[] = allCountryCodes
      .filter(countryCode => !featuredCountryCodes.includes(countryCode))
      .map(countryCode => ({
        value: countryCode,
        text: countries[countryCode],
      }))
      .sort((a, b) => a.text.localeCompare(b.text))

    countryList.value = [...featuredCountries, ...allOtherCountries]
  }

  const setLocale = (locale: LocaleName) => {
    if (!supportedLocales.value.includes(locale)) return

    const shouldUpdateCountryList = !countryList.value.length || locale !== selectedLocale.value

    selectedLocale.value = locale
    $i18n.locale.value = locale

    $userpilot?.reload()

    if (shouldUpdateCountryList) {
      updateCountryList()
    }
  }

  const setLanguage = async (language: Language) => {
    const locale = supportedLocales.value.find(locale => locale.startsWith(language))
    if (locale) {
      setLocale(locale)
    }
  }

  // Checks if a language code is a supported language
  const validateLanguageCode = (languageCode: unknown) => {
    if (typeof languageCode !== 'string') return null

    const languageCodeIsSupported = supportedLocales.value.some(locale => locale.substring(0, 2) === languageCode)
    return languageCodeIsSupported ? languageCode : null
  }

  return {
    supportedLocales,
    selectableLocales,
    countryList,
    locale: selectedLocale,
    language: selectedLanguage,
    setLanguage,
    setLocale,
    validateLanguageCode,
  }
})
