<script lang="ts" setup>
import SkrSnackbar from '@/components/snackbar/SkrSnackbar.vue'

const widgetStore = useWidgetStore()
const { snackbarQueue } = storeToRefs(widgetStore)
const { removeSnackbar } = widgetStore
</script>

<template>
  <teleport to="body">
    <div class="snackbars">
      <skr-snackbar
        v-for="{ id, ...props } in snackbarQueue"
        :key="id"
        v-bind="props"
        attach
        @close="removeSnackbar(id)"
      />
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.snackbars {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  pointer-events: none;

  :deep(.snackbar) {
    padding: 0;
    max-width: 100%;
    height: max-content;
    position: relative;

    .v-snackbar__wrapper {
      position: relative;
    }
  }
}
</style>
