<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12.9,14c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.3-1-0.2-1.3,0.3l-2.7,4.1c-0.1,0.1-0.2,0.3-0.2,0.5v0.7h7.3v-0.7
      c0-0.2-0.1-0.4-0.2-0.5L12.9,14z M20.3,21h-0.8v-2.4c0-0.9-0.3-1.8-0.8-2.6L16,12l2.7-4c0.5-0.8,0.8-1.7,0.8-2.6V3h0.8
      c0.6,0,1-0.4,1-1s-0.4-1-1-1H3.9c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.8v2.4C4.7,6.4,5,7.3,5.5,8l2.7,4l-2.7,4c-0.5,0.8-0.8,1.7-0.8,2.6V21
      H3.9c-0.6,0-1,0.4-1,1s0.4,1,1,1h16.4c0.6,0,1-0.4,1-1S20.8,21,20.3,21z M17.5,21H6.7v-2.4c0-0.5,0.2-1,0.4-1.5l3-4.5
      c0.2-0.3,0.2-0.8,0-1.1l-3-4.5C6.9,6.5,6.7,6,6.7,5.4V3h10.7v2.4c0,0.5-0.2,1-0.4,1.5l-3,4.5c-0.2,0.3-0.2,0.8,0,1.1l3,4.5
      c0.3,0.4,0.4,0.9,0.4,1.5V21z M12.1,11c0.3,0,0.5-0.2,0.7-0.5c0.1-0.1,1.8-2.7,1.9-2.9c0.1-0.2,0.1-0.5,0.1-0.5H9.4
      c0,0,0,0.3,0.1,0.5c0.1,0.1,1.7,2.7,1.8,2.8C11.5,10.8,11.7,11,12.1,11z"
    />
  </svg>
</template>
