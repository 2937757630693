<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <rect id="Background" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" width="24" height="24" />

    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#000000"
      d="M0,12v12h24V12H0z M10.1,19.1h-1v-3.6h1V19.1z M9.6,15.2C9.3,15.2,9,15,9,14.7
      s0.2-0.5,0.6-0.5c0.3,0,0.6,0.2,0.6,0.5S9.9,15.2,9.6,15.2z M12.4,19.1h-1.3v-4.8h1.3c1.6,0,2.6,0.9,2.6,2.4
      C14.9,18.3,14,19.1,12.4,19.1z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4,15.2h-0.3v3.1h0.3c0.9,0,1.5-0.5,1.5-1.6C13.9,15.7,13.3,15.2,12.4,15.2z"
    />

    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#FF0000"
      d="M0,0v12h24V0H0z M6.7,7H5.6v1.3C5.6,8.9,5.8,9,6.2,9
    c0.1,0,0.1,0,0.2,0v0.9c0,0-0.2,0-0.4,0C5,9.9,4.6,9.4,4.6,8.4V8.1h0V7H3.5V6h1.1V4.9h1V6h1.1V7z M9.4,6.9c-0.8,0-1,0.5-1,1.2v1.7
    h-1V6h1v0.6c0.2-0.4,0.6-0.7,1.1-0.7V6.9z M13.4,9.9h-1V9.4c-0.2,0.3-0.5,0.6-1,0.6C10.2,10,10,9.1,10,8.2V6h1v2.4
    c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V6h1V9.9z M15.4,10c-0.8,0-1.2-0.4-1.5-0.7l0.6-0.5C14.7,9,15,9.2,15.4,9.2
    c0.3,0,0.5-0.1,0.5-0.3c0-0.6-1.8-0.4-1.8-1.7c0-0.7,0.6-1.2,1.4-1.2c0.7,0,1.1,0.4,1.3,0.7l-0.7,0.5C16,7,15.8,6.7,15.4,6.7
    C15.2,6.7,15,6.8,15,7c0,0.6,1.8,0.3,1.8,1.7C16.9,9.5,16.2,10,15.4,10z M20.5,7.1h-1.1v1.1h-1V7.1h-1.1V6h1.1V4.9h1V6h1.1V7.1z"
    />
  </svg>
</template>
