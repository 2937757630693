<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    xml:space="preserve"
  >
    <circle cx="28" cy="28" fill="#eef0f3" r="28" />
    <g fill="#a1aaba">
      <path
        d="m28 17.06a10.94 10.94 0 1 0 10.94 10.94 10.94 10.94 0 0 0 -10.94-10.94zm-13.12 10.94a13.12 13.12 0 1 1 13.12 13.12 13.11 13.11 0 0 1 -13.12-13.12z"
        fill-rule="evenodd"
      />
      <path d="m23.08 28a1.64 1.64 0 1 0 -1.64-1.64 1.64 1.64 0 0 0 1.64 1.64z" />
      <path d="m32.92 28a1.64 1.64 0 1 0 -1.64-1.64 1.63 1.63 0 0 0 1.64 1.64z" />
      <path
        d="m28 32.38a2.2 2.2 0 0 0 -1.55.64 2.17 2.17 0 0 0 -.64 1.54 1.1 1.1 0 1 1 -2.19 0 4.38 4.38 0 0 1 8.76 0 1.1 1.1 0 1 1 -2.19 0 2.17 2.17 0 0 0 -.64-1.56 2.2 2.2 0 0 0 -1.55-.62z"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
