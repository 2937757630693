<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22.9,9.4C22.8,9,22.4,8.8,22,8.8h-8.8l0.9-6.6c0.1-0.4-0.2-0.9-0.6-1c-0.4-0.2-0.9-0.1-1.2,0.2L1.3,13.5
  c-0.3,0.3-0.3,0.7-0.2,1.1C1.2,15,1.6,15.2,2,15.2h8.8l-0.9,6.6c-0.1,0.4,0.2,0.9,0.6,1c0.1,0.1,0.3,0.1,0.4,0.1
  c0.3,0,0.5-0.1,0.7-0.3l11.1-12.2C23,10.2,23.1,9.7,22.9,9.4z M12.3,18.9l0.7-4.6c0-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.5-0.3-0.8-0.3H4.3
  l7.4-8.1L11,9.6c0,0.3,0,0.6,0.2,0.8c0.2,0.2,0.5,0.3,0.8,0.3h7.7L12.3,18.9z"
    />
  </svg>
</template>
