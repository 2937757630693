<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5571 13.2159L20.2771 17.8902C20.4015 18.0125 20.5003 18.1583 20.5678 18.3192C20.6352 18.4801 20.6699 18.6528 20.6699 18.8273C20.6699 19.0018 20.6352 19.1745 20.5678 19.3354C20.5003 19.4963 20.4015 19.6421 20.2771 19.7644C20.0272 20.011 19.691 20.1504 19.34 20.153C18.9857 20.1498 18.6461 20.0107 18.3914 19.7644L13.6714 15.1016C12.4433 15.9002 10.9838 16.2663 9.52414 16.142C8.06447 16.0176 6.68797 15.4099 5.61259 14.4151C4.53722 13.4203 3.8244 12.0952 3.58701 10.6496C3.34962 9.20398 3.60122 7.7205 4.30197 6.43401C5.00272 5.14752 6.11258 4.13154 7.45583 3.54691C8.79908 2.96229 10.299 2.84243 11.718 3.20632C13.1371 3.5702 14.3942 4.39704 15.2903 5.55592C16.1865 6.7148 16.6705 8.1395 16.6657 9.60445C16.6624 10.8915 16.2765 12.1486 15.5571 13.2159V13.2159ZM5.95712 9.59302C5.95032 10.3856 6.17913 11.1623 6.61451 11.8246C7.04988 12.4869 7.6722 13.0049 8.40248 13.3129C9.13276 13.6209 9.93808 13.705 10.7163 13.5546C11.4944 13.4041 12.2104 13.0259 12.7732 12.4679C13.336 11.9099 13.7204 11.1972 13.8776 10.4204C14.0347 9.64352 13.9575 8.8375 13.6558 8.1046C13.3541 7.37169 12.8414 6.74494 12.1829 6.30388C11.5244 5.86282 10.7497 5.62733 9.95712 5.62731C9.43387 5.6243 8.91518 5.72457 8.43075 5.92238C7.94633 6.12019 7.5057 6.41164 7.13411 6.78004C6.76253 7.14844 6.46729 7.58655 6.26532 8.06925C6.06335 8.55195 5.95861 9.06977 5.95712 9.59302V9.59302Z"
      fill="currentColor"
    />
  </svg>
</template>
