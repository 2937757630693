const hubspotRepository = (fetch: CustomFetch) => ({
  async sendData(portalId: string, formId: string, data: Record<string, unknown>) {
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

    await fetch(url, {
      baseURL: '',
      method: 'POST',
      body: data,
    })
  },
})

export default hubspotRepository
