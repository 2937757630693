export type ActivityType = 'members' | 'signatures' | 'identifications'

export type ItemType<T> = T extends 'signatures'
  ? SignatureEvent
  : T extends 'members'
    ? AccountEvent
    : T extends 'identifications'
      ? IdentificationEvent
      : never

export abstract class ActivityEventsRepository<T extends { timestamp: string }> {
  protected fetch: CustomFetch

  constructor(fetch: CustomFetch) {
    this.fetch = fetch
  }
  /**
   * Get the list of activity events from server
   * Parameters:
   * - `page` - which page to return
   * - `size` - the number of items per page
   * - `start_date` and `end_date` - should get passed as YYYY-MM-DD format
   * - `sort_order` - should be `asc` or `desc` (column: date)
   */
  abstract getEvents(businessId: string, request: ActivityEventsRequest): Promise<ActivityEventsResponse<T>>
}

class MemberEventsRepository extends ActivityEventsRepository<AccountEvent> {
  async getEvents(businessId: string, request: ActivityEventsRequest) {
    const url =
      `/v2/businesses/${businessId}/activities/accounts?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    return this.fetch<ActivityEventsResponse<AccountEvent>>(url)
  }
}

class SignatureEventsRepository extends ActivityEventsRepository<SignatureEvent> {
  async getEvents(businessId: string, request: ActivityEventsRequest) {
    const url =
      `/v2/businesses/${businessId}/activities/signatures?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    return this.fetch<ActivityEventsResponse<SignatureEvent>>(url)
  }
}

class IdentificationEventsRepository extends ActivityEventsRepository<IdentificationEvent> {
  async getEvents(businessId: string, request: ActivityEventsRequest) {
    const url =
      `/v2/businesses/${businessId}/activities/identifications?page=${request.page}&size=${request.size}&sort=${request.sortOrder}` +
      (request.startDate ? `&start_date=${request.startDate}` : '') +
      (request.endDate ? `&end_date=${request.endDate}` : '')

    return this.fetch<ActivityEventsResponse<IdentificationEvent>>(url)
  }
}

const businessRepository = (fetch: CustomFetch) => ({
  signatures: new SignatureEventsRepository(fetch),
  members: new MemberEventsRepository(fetch),
  identifications: new IdentificationEventsRepository(fetch),
  async getSignaturesExportCsv(businessId: string, groupBy: string, startDate: string, endDate: string) {
    const data = await fetch<Blob>(
      `/v2/businesses/${businessId}/activities/signatures/export?group_by=${groupBy}&start_date=${startDate}&end_date=${endDate}`,
      {
        responseType: 'blob',
      }
    )

    // file name should be : signature_usage-20240113_20240122.csv or cost_centre_report-20230113_20240122.csv
    const prefix = groupBy === 'cost_centers' ? 'cost_centre_report' : 'signature_usage'
    return {
      data,
      name: `${prefix}-${startDate.replace(/-/g, '')}_${endDate.replace(/-/g, '')}.csv`,
    }
  },
  async getIdentificationExportCsv(businessId: string, startDate: string, endDate: string) {
    const data = await fetch<Blob>(
      `/v2/businesses/${businessId}/activities/identifications/export?start_date=${startDate}&end_date=${endDate}`,
      {
        responseType: 'blob',
      }
    )

    return {
      data,
      name: `identifications-${startDate.replace(/-/g, '')}_${endDate.replace(/-/g, '')}.csv`,
    }
  },
})

export default businessRepository
