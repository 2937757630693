<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8252 12.581L22.4062 2"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0923 2H22.4063V10.3133"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.55787 4.26736H3.88947C3.38835 4.26736 2.90776 4.46643 2.55341 4.82078C2.19907 5.17512 2 5.65571 2 6.15683V20.5168C2 21.0179 2.19907 21.4985 2.55341 21.8528C2.90776 22.2072 3.38835 22.4063 3.88947 22.4063H18.2494C18.7505 22.4063 19.2311 22.2072 19.5855 21.8528C19.9398 21.4985 20.1389 21.0179 20.1389 20.5168V15.2263"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
