<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.1,15.8l-4.7-2c-0.3-0.1-0.7-0.2-1.1-0.1c-0.4,0.1-0.7,0.2-1,0.5L12,15.5c-0.7-0.5-1.4-1-2-1.6c-0.6-0.6-1.1-1.3-1.6-2
  l1.4-1.4c0.3-0.3,0.4-0.6,0.5-0.9c0.1-0.4,0-0.7-0.1-1.1l-2-4.7C8,3.5,7.7,3.2,7.3,3C6.9,2.8,6.5,2.8,6,2.9l-3.7,1
  C2,4,1.6,4.2,1.4,4.5C1.1,4.9,1,5.3,1,5.7c0.2,4.5,2.1,8.8,5.3,12c3.2,3.2,7.5,5,12,5.3h0.1c0.4,0,0.8-0.1,1.1-0.4
  c0.3-0.2,0.6-0.6,0.7-1l1-3.7c0.1-0.4,0.1-0.9-0.1-1.3C20.8,16.3,20.5,16,20.1,15.8z M18.3,21c-4-0.3-7.7-1.9-10.5-4.7
  C4.9,13.5,3.3,9.7,3,5.8l3.4-0.9l1.9,4.4l-1.9,1.9c-0.3,0.3-0.4,0.8-0.1,1.2c0.6,1.1,1.4,2.1,2.3,3c0.9,0.9,1.9,1.6,3,2.3
  c0.4,0.2,0.9,0.2,1.2-0.1l1.9-1.8l4.4,1.9L18.3,21z M20.3,3.7C18.6,2,16.3,1,13.8,1c-0.6,0-1,0.4-1,1s0.4,1,1,1
  c1.9,0,3.7,0.7,5.1,2.1c1.4,1.4,2.1,3.2,2.1,5.1c0,0.6,0.4,1,1,1s1-0.4,1-1C23,7.7,22,5.4,20.3,3.7z M16.3,7.7c0.7,0.7,1,1.6,1,2.5
  c0,0.6,0.4,1,1,1s1-0.4,1-1c0-1.5-0.6-2.9-1.6-3.9c-1-1-2.4-1.6-3.9-1.6c-0.6,0-1,0.4-1,1s0.4,1,1,1C14.8,6.6,15.7,7,16.3,7.7z"
    />
  </svg>
</template>
