<template>
  <v-main>
    <toolbar-base :title="$t('direct_sign.identification.identification_required')" app />
    <v-container fluid class="content">
      <v-row>
        <v-col cols="12" md="5">
          <div class="mb-12">
            <skr-heading level="1" semantic>
              {{ t('eu_ident.title_new') }}
            </skr-heading>
          </div>
          <v-container v-if="isIdentificationCovered === null" class="fill-height align-center justify-center" fluid>
            <v-progress-circular :size="70" color="text" indeterminate />
          </v-container>
          <template v-else>
            <identification-methods
              :available-methods="availableMethods"
              :is-direct-sign="true"
              :show-help-link="!isIdentificationCovered"
              @select="onSelect"
            />
            <v-btn
              data-cy="back"
              class="mt-8 align-self-center flat-link"
              size="large"
              variant="text"
              width="100%"
              @click="$router.go(-1)"
            >
              {{ t('global.button.go_back') }}
            </v-btn>
          </template>
        </v-col>
        <v-col v-if="$vuetify.display.mdAndUp" cols="12" md="7" align-self="center">
          <responsive-image source="zertes-id-magnifying-glass" :width="570" :height="630" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="ts">
import SkrHeading from '@/components/SkrHeading.vue'
import IdentificationMethods from '@/components/identification/IdentificationMethods.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'
import IdentificationType from '@/pages/profile/signature-standards/zertes/index.vue'

export default defineComponent({
  components: { ToolbarBase, SkrHeading, IdentificationMethods, ResponsiveImage },
  extends: IdentificationType,
  setup(props, ctx) {
    definePageMeta({
      accessControl: {
        roles: ['nas'],
      },
    })

    return {
      ...IdentificationType.setup(props, ctx),
    }
  },
})
</script>

<style lang="sass" scoped>
.content
  display: flex
  align-items: center
  height: 100%
  max-width: $site-width
  margin: 0 auto
</style>
