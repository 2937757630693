<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17.6,13.5c-0.5,0-0.9,0.4-0.9,0.9v3.7c0,0.6-0.5,1-1,1H5.9c-0.6,0-1-0.5-1-1V8.3c0-0.6,0.5-1,1-1h3.7
  c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H5.9c-1.6,0-2.8,1.3-2.8,2.8v9.8c0,1.6,1.3,2.8,2.8,2.8h9.8c1.6,0,2.8-1.3,2.8-2.8v-3.7
  C18.5,13.9,18.1,13.5,17.6,13.5z M20.6,5.8l-2.4-2.4C17.9,3,17.3,3,17,3.4l-8.8,8.8C8,12.3,8,12.4,7.9,12.6l-0.8,3.2
  c-0.1,0.3,0,0.6,0.2,0.9c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.1,0,0.2,0l3.2-0.8c0.2,0,0.3-0.1,0.4-0.2L20.6,7C21,6.7,21,6.1,20.6,5.8z
   M10.7,14.4l-1.5,0.4l0.4-1.5l5.6-5.6l1.1,1.1L10.7,14.4z M17.6,7.5l-1.1-1.1l1.1-1.1l1.1,1.1L17.6,7.5z"
    />
  </svg>
</template>
