<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12" md="5">
        <skr-heading level="1" semantic>{{ t('unlock_qes.mobileid.title') }}</skr-heading>
        <i18n-t tag="div" keypath="unlock_qes.mobileid.text">
          <template #helpCenter>
            <a rel="noopener" target="_blank" :href="$t('unlock_qes.mobileid.help_center_link')">{{
              $t('unlock_qes.mobileid.help_center_text')
            }}</a>
          </template>
        </i18n-t>
        <div class="pt-6 pb-11">
          <v-btn :href="href" target="blank" data-cy="start" color="primary" block size="x-large">
            {{ $t('unlock_qes.mobileid.cta') }}
          </v-btn>
        </div>
        <div class="text-h6">
          <strong>{{ $t('unlock_qes.mobileid.proceed.title') }}</strong>
        </div>
        <div>{{ $t('unlock_qes.mobileid.proceed.text') }}</div>
        <div class="pt-6">
          <v-btn exact block type="" color="info" variant="outlined" size="x-large" data-cy="next" @click="next">
            {{ $t('unlock_qes.mobileid.proceed.cta') }}
          </v-btn>
          <v-btn
            data-cy="cancel"
            class="mt-4 align-self-center flat-link"
            size="large"
            variant="text"
            width="100%"
            @click="$router.go(-1)"
          >
            {{ $t('unlock_qes.eu.video_ident.start.back') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="mdAndUp" cols="12" md="7">
        <responsive-image :source="`mobile-id-${language}`" :width="469" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const { mdAndUp } = useDisplay()

    const { language } = storeToRefs(useLanguageStore())

    return {
      t,
      mdAndUp,
      language,
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Mobile ID',
    }
  },
  computed: {
    href(): string {
      return `https://www.mobileid.ch/${this.language.toLowerCase()}/login`
    },
  },
  methods: {
    next() {
      const l = this.$route.query.l
      let name = 'profile-signature-standards-legislation'
      if (l === 'zertes') {
        name = 'profile-signature-standards-zertes'
      } else if (l === 'eidas') {
        name = 'profile-signature-standards-eidas'
      }
      const obj: {
        name: string
        query?: { sr?: string | (string | null)[]; l?: string }
      } = { name }
      const srid = this.$route.query.sr
      if (srid) obj.query = { sr: srid }
      return navigateTo(obj)
    },
  },
})
</script>

<style lang="sass">
.heading__title
  font-size: 2rem
</style>
