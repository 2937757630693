<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.182 12.91c0 4.51-3.67 8.18-8.182 8.18-4.512 0-8.182-3.67-8.182-8.18 0-4.513 3.67-8.183 8.182-8.183 1.808 0 3.52.591 4.936 1.667L13.66 9.478l8.334.894L21.602 2l-3.323 3.13A9.923 9.923 0 0 0 12 2.91c-5.514 0-10 4.485-10 10 0 5.513 4.486 10 10 10s10-4.487 10-10h-1.818z"
      fill-rule="nonzero"
    />
  </svg>
</template>
