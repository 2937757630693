<template>
  <two-cols-block class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1" semantic>{{ t('unlock_qes.eu.video_ident.start.title') }}</skr-heading>
      </div>
      <div class="space-y-4 mb-12">
        <p>{{ t('ident.ch.video_ident.setup.text.info') }}</p>
        <p>
          <strong>{{ t('ident.ch.video_ident.setup.text.languages') }}</strong
          ><br />
          <i18n-t keypath="ident.ch.video_ident.setup.text.requirements">
            <template #link>
              <a :href="t('ident.ch.video_ident.setup.link_url')" target="_blank" rel="noopener">
                {{ t('ident.ch.video_ident.setup.link_text') }}
              </a>
            </template>
          </i18n-t>
        </p>
        <p>
          <strong>{{ t('ident.ch.video_ident.setup.list.header') }}</strong>
        </p>
        <ul>
          <li>{{ t('ident.ch.video_ident.setup.list.item_1') }}</li>
          <li>{{ t('ident.ch.video_ident.setup.list.item_2') }}</li>
        </ul>
        <p>
          <strong>{{ t('ident.ch.video_ident.setup.text.activation_header') }}</strong
          ><br />{{ t('ident.ch.video_ident.setup.text.activation') }}
        </p>
      </div>
      <skr-button-group size="xl" stacked>
        <skr-button
          data-cy="start_button"
          :loading="isLoading"
          :disabled="!identUrl"
          :href="identUrl"
          @click="openStatusPage"
          >{{ t('unlock_qes.ch.video_ident.start') }}</skr-button
        >
        <skr-button data-cy="cancel_button" type="secondary" :disabled="isLoading" @click="onCancel">{{
          t('unlock_qes.eu.video_ident.start.back')
        }}</skr-button>
      </skr-button-group>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image :source="`zertes-videoident-steps-${language}`" :width="537" :height="663" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrButtonGroup,
    TwoColsBlock,
    ResponsiveImage,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const router = useRouter()

    const { email } = storeToRefs(useUserStore())
    const identStore = useIdentStore()
    const { zertesDeleteVideoIdent, zertesGenerateVideoIdent, openStatusPage } = identStore
    const { zertesVideoIdentURL, identInProgress } = storeToRefs(identStore)
    const { language } = storeToRefs(useLanguageStore())
    const { srId } = useIdentAvailability('zertes')

    const onCancel = async () => {
      if (zertesVideoIdentURL.value) await zertesDeleteVideoIdent(email.value)
      router.back()
    }

    onBeforeMount(() => {
      void zertesGenerateVideoIdent(srId.value || undefined)
    })

    return {
      t,
      language,
      isLoading: identInProgress,
      identUrl: zertesVideoIdentURL,
      openStatusPage,
      onCancel,
    }
  },
})
</script>
