<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,24c6.6,0,12-5.4,12-12S18.6,0,12,0S0,5.4,0,12S5.4,24,12,24z M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12
  S6.5,2,12,2z M8.7,14.8l3.3-3.3l3.3,3.3c0.4,0.4,1.1,0.4,1.6,0c0.4-0.4,0.4-1.1,0-1.6l-4.1-4.1C12.6,9,12.3,8.9,12,8.9
  S11.4,9,11.2,9.2l-4.1,4.1c-0.4,0.4-0.4,1.1,0,1.6C7.6,15.2,8.3,15.2,8.7,14.8z"
    />
  </svg>
</template>
