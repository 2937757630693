<script setup></script>

<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 11.5C1 5.977 5.477 1.5 11 1.5C13.5065 1.5 15.798 2.4225 17.553 3.946"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 6.5L20 1.5L21 7.5L15 6.5Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.0003 11.5C21.0003 17.023 16.5233 21.5 11.0003 21.5C8.49377 21.5 6.20227 20.5775 4.44727 19.054"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 16.5L2 21.5L1 15.5L7 16.5Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
