<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M2,5H1C0.4,5,0,5.4,0,6s0.4,1,1,1h1c0.6,0,1-0.4,1-1S2.6,5,2,5z M6,3c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1S5,0.4,5,1v1
      C5,2.6,5.4,3,6,3z M6,21c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1C7,21.4,6.6,21,6,21z M2,17H1c-0.6,0-1,0.4-1,1
      s0.4,1,1,1h1c0.6,0,1-0.4,1-1S2.6,17,2,17z M22,7h1c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1c-0.6,0-1,0.4-1,1S21.4,7,22,7z M18,0
      c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1V1C19,0.4,18.6,0,18,0z M18,21c-0.6,0-1,0.4-1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1
      C19,21.4,18.6,21,18,21z M23,17h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S23.6,17,23,17z M18,5H6C5.4,5,5,5.4,5,6v12
      c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6C19,5.4,18.6,5,18,5z M17,17H7V7h10V17z"
    />
  </svg>
</template>
