<script setup></script>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.6667 21.334H20V28.0007H30.6667V21.334Z"
      stroke="currentColor"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6719 21.3327V17.3327C22.6719 15.866 23.8719 14.666 25.3385 14.666C26.8052 14.666 28.0052 15.866 28.0052 17.3327V21.3327"
      stroke="currentColor"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 18.6667V5.33333C4 4.59733 4.59733 4 5.33333 4H26.6667C27.4027 4 28 4.59733 28 5.33333V9.33333"
      stroke="currentColor"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.6693 24H1.33594C1.33594 26.2093 3.1266 28 5.33594 28H14.6693"
      stroke="currentColor"
      stroke-width="2.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
