<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M18,10.7V6c0-3.4-2.6-6-6-6S6,2.6,6,6v4.7C4.8,12.1,4,14,4,16c0,4.4,3.6,8,8,8s8-3.6,8-8C20,14,19.2,12.1,18,10.7z M8,6
  c0-2.2,1.8-4,4-4s4,1.8,4,4v3.1C14.8,8.4,13.5,8,12,8S9.2,8.4,8,9.1V6z M12,22c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S15.3,22,12,22z
   M12,12c-1.7,0-3,1.3-3,3c0,1.3,0.8,2.4,2,2.8V19c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.2c1.2-0.4,2-1.5,2-2.8C15,13.3,13.7,12,12,12z
   M12,16c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S12.6,16,12,16z"
    />
  </svg>
</template>
