<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,8c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4s4-1.8,4-4C16,9.8,14.2,8,12,8z M12,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2
  C14,13.1,13.1,14,12,14z M21,15c1.7,0,3-1.3,3-3s-1.3-3-3-3h-0.5c-0.1-0.3-0.2-0.6-0.4-0.9l0.4-0.4c1.2-1.2,1.2-3.1,0-4.2
  c-1.2-1.2-3.1-1.2-4.2,0l-0.4,0.4c-0.3-0.1-0.6-0.3-0.9-0.4V3c0-1.7-1.3-3-3-3S9,1.3,9,3v0.5C8.7,3.6,8.4,3.7,8.1,3.9L7.8,3.5
  c-1.2-1.2-3.1-1.2-4.2,0c-1.2,1.2-1.2,3.1,0,4.2l0.4,0.4C3.7,8.4,3.6,8.7,3.5,9H3c-1.7,0-3,1.3-3,3s1.3,3,3,3h0.5
  c0.1,0.3,0.2,0.6,0.4,0.9l-0.4,0.4c-1.2,1.2-1.2,3.1,0,4.2c1.2,1.2,3.1,1.2,4.2,0l0.4-0.4c0.3,0.1,0.6,0.3,0.9,0.4V21
  c0,1.7,1.3,3,3,3s3-1.3,3-3v-0.5c0.3-0.1,0.6-0.2,0.9-0.4l0.4,0.4c1.2,1.2,3.1,1.2,4.2,0c1.2-1.2,1.2-3.1,0-4.2l-0.4-0.4
  c0.1-0.3,0.3-0.6,0.4-0.9H21z M19.7,13c-0.5,0-0.9,0.3-1,0.8c-0.2,0.6-0.4,1.2-0.7,1.8c-0.2,0.4-0.2,0.9,0.2,1.2l0.9,0.9
  c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-0.9-0.9c-0.3-0.3-0.8-0.4-1.2-0.2c-0.6,0.3-1.2,0.6-1.8,0.7c-0.4,0.1-0.8,0.5-0.8,1V21
  c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.3c0-0.5-0.3-0.9-0.8-1C9.6,18.6,9,18.4,8.5,18c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.7,0.3
  l-0.9,0.9c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l0.9-0.9c0.3-0.3,0.4-0.8,0.2-1.2c-0.3-0.6-0.6-1.2-0.7-1.8
  c-0.1-0.4-0.5-0.8-1-0.8H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.3c0.5,0,0.9-0.3,1-0.7C5.4,9.6,5.6,9,6,8.5c0.2-0.4,0.2-0.9-0.2-1.2
  L4.9,6.3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l0.9,0.9C7.6,6.1,8.1,6.2,8.5,6C9,5.6,9.6,5.4,10.2,5.2c0.4-0.1,0.8-0.5,0.8-1V3
  c0-0.6,0.4-1,1-1s1,0.4,1,1v1.3c0,0.5,0.3,0.9,0.8,1C14.4,5.4,15,5.6,15.5,6c0.4,0.2,0.9,0.2,1.2-0.2l0.9-0.9c0.4-0.4,1-0.4,1.4,0
  c0.4,0.4,0.4,1,0,1.4l-0.9,0.9c-0.3,0.3-0.4,0.8-0.2,1.2c0.3,0.6,0.6,1.2,0.7,1.8c0.1,0.4,0.5,0.7,1,0.7H21c0.6,0,1,0.4,1,1
  s-0.4,1-1,1H19.7z"
    />
  </svg>
</template>
