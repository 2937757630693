<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2766_332)">
      <path
        d="M10.2432 16.2747V9.40513"
        stroke="currentColor"
        stroke-width="1.49859"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.09036 2.39522L4.07417 8.37254L10.4393 16.7656L16.352 8.69877L14.004 2.39522"
        stroke="currentColor"
        stroke-width="1.49859"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0078 7.63541C12.0078 6.6773 11.2178 5.90059 10.2432 5.90059C9.26862 5.90059 8.47858 6.6773 8.47858 7.63541C8.47858 8.59353 9.26862 9.37024 10.2432 9.37024C11.2178 9.37024 12.0078 8.59353 12.0078 7.63541Z"
        stroke="currentColor"
        stroke-width="1.49857"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.87329 2.3042H16.4379"
        stroke="currentColor"
        stroke-width="1.49859"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M17.7397 13.6601L17.7397 20.8508L14.3561 17.5055C14.2965 17.4467 14.2257 17.4 14.1478 17.3681C14.0699 17.3363 13.9863 17.3199 13.902 17.3199C13.8177 17.3199 13.7341 17.3363 13.6562 17.3681C13.5783 17.4 13.5075 17.4467 13.4479 17.5055C13.3882 17.5644 13.3409 17.6342 13.3087 17.7111C13.2764 17.788 13.2598 17.8704 13.2598 17.9537C13.2598 18.0369 13.2764 18.1193 13.3087 18.1962C13.3409 18.2731 13.3882 18.343 13.4479 18.4018L17.9252 22.8202C17.9853 22.8786 18.0572 22.9238 18.1363 22.9527C18.292 23.0158 18.4667 23.0158 18.6224 22.9527C18.7009 22.9227 18.7726 22.8776 18.8335 22.8202L23.3108 18.4018C23.3708 18.3431 23.4184 18.2733 23.4508 18.1964C23.4833 18.1195 23.5 18.037 23.5 17.9537C23.5 17.8703 23.4833 17.7878 23.4508 17.7109C23.4184 17.634 23.3708 17.5642 23.3108 17.5055C23.2514 17.4464 23.1806 17.3994 23.1027 17.3674C23.0247 17.3353 22.9411 17.3188 22.8567 17.3188C22.7723 17.3188 22.6886 17.3353 22.6107 17.3674C22.5328 17.3994 22.462 17.4464 22.4026 17.5055L19.019 20.8508L19.019 13.6601C19.019 13.4927 18.9516 13.3322 18.8316 13.2138C18.7117 13.0954 18.549 13.0289 18.3793 13.0289C18.2097 13.0289 18.047 13.0954 17.9271 13.2138C17.8071 13.3322 17.7397 13.4927 17.7397 13.6601Z"
      fill="currentColor"
    />
    <defs>
      <clipPath id="clip0_2766_332">
        <rect width="17.0441" height="16.8195" fill="white" transform="translate(1.24329 1.19849)" />
      </clipPath>
    </defs>
  </svg>
</template>
