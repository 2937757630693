/*
 * protects identification routes
 */

const redirectIfOngoingIdentification = [
  'profile-signature-standards-legislation',
  'profile-signature-standards-update',
  'profile-signature-standards-eidas-video',
  'profile-signature-standards-eidas-self',
  'profile-signature-standards-eidas',
  'profile-signature-standards-zertes-personal-details',
  'profile-signature-standards-zertes-video',
  'profile-signature-standards-zertes-auto',
  'profile-signature-standards-zertes',
  'direct-sign-identification-eidas-video',
  'direct-sign-identification-eidas-self',
  'direct-sign-identification-eidas',
  'direct-sign-identification-zertes-personal-details',
  'direct-sign-identification-zertes-video',
  'direct-sign-identification-zertes-auto',
  'direct-sign-identification-zertes',
]

export default defineNuxtRouteMiddleware(async to => {
  if (!to.name || redirectIfOngoingIdentification.includes(to.name)) {
    const ident = useIdentStore()
    /* legislation not relevant */
    const { redirect } = useIdentAvailability('eidas')
    const { eidasOngoingIdent, zertesOngoingIdent } = storeToRefs(ident)
    await Promise.all([ident.eidasGetAllIdentInfo(), ident.zertesGetAllIdentInfo()])
    // If there is already an identification in process,
    // redirect to the signature standards page
    if (eidasOngoingIdent.value || zertesOngoingIdent.value) {
      redirect()
    }
  }
})
