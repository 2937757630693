<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,2C9.3,2,6.9,3.1,5.1,4.8C5,4.8,5,4.9,4.9,4.9S4.8,5,4.8,5.1C3.1,6.9,2,9.3,2,12c0,5.5,4.5,10,10,10
  c2.7,0,5.1-1.1,6.9-2.8c0.1,0,0.1-0.1,0.2-0.1s0.1-0.1,0.1-0.2c1.7-1.8,2.8-4.2,2.8-6.9C22,6.5,17.5,2,12,2z M12,20
  c-4.4,0-8-3.6-8-8c0-1.8,0.6-3.5,1.7-4.9l11.2,11.2C15.5,19.4,13.8,20,12,20z M18.3,16.9L7.1,5.7C8.5,4.6,10.2,4,12,4
  c4.4,0,8,3.6,8,8C20,13.8,19.4,15.5,18.3,16.9z"
    />
  </svg>
</template>
