<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M16,9.9H8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h8c0.4,0,0.8-0.3,0.8-0.8S16.4,9.9,16,9.9z M17.6,4H6.4C5.1,4,4,5.1,4,6.4
  v11.2C4,18.9,5.1,20,6.4,20h11.2c1.3,0,2.4-1.1,2.4-2.4V6.4C20,5.1,18.9,4,17.6,4z M18.5,17.6c0,0.5-0.4,0.9-0.9,0.9H6.4
  c-0.5,0-0.9-0.4-0.9-0.9v-3.3c0.1,0,0.1,0,0.2,0H8V16c0,0.4,0.3,0.8,0.8,0.8h6.4c0.4,0,0.8-0.3,0.8-0.8v-1.6h2.3c0.1,0,0.2,0,0.2,0
  V17.6z M18.5,12.9c-0.1,0-0.1,0-0.2,0h-3.1c-0.4,0-0.8,0.3-0.8,0.8v1.6H9.5v-1.6c0-0.4-0.3-0.8-0.8-0.8H5.7c-0.1,0-0.2,0-0.2,0V6.4
  c0-0.5,0.4-0.9,0.9-0.9h11.2c0.5,0,0.9,0.4,0.9,0.9V12.9z M16,7H8C7.6,7,7.2,7.3,7.2,7.7c0,0.4,0.3,0.7,0.8,0.7h8
  c0.4,0,0.8-0.3,0.8-0.7C16.8,7.3,16.4,7,16,7z"
    />
  </svg>
</template>
