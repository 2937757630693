<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,3.1c-4.9,0-8.9,4-8.9,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S16.9,3.1,12,3.1z M12,19.1c-3.9,0-7.1-3.2-7.1-7.1
  S8.1,4.9,12,4.9s7.1,3.2,7.1,7.1S15.9,19.1,12,19.1z M14.8,8.2l-4,5.1l-1.3-1.4c-0.3-0.4-0.9-0.4-1.3,0c-0.4,0.3-0.4,0.9,0,1.3
  l2,2.1c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0c0.3,0,0.5-0.1,0.7-0.3l4.7-5.9c0.3-0.4,0.2-1-0.1-1.3C15.6,7.8,15.1,7.9,14.8,8.2z"
    />
  </svg>
</template>
