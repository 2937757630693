<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,0C5.4,0,0,5.4,0,12c0,3.7,1.7,7,4.3,9.2c0,0,0,0,0.1,0C6.4,23,9.1,24,12,24c2.9,0,5.6-1,7.7-2.8c0,0,0,0,0.1,0
      C22.3,19,24,15.7,24,12C24,5.4,18.6,0,12,0z M18,20c-1.7,1.3-3.7,2-6,2s-4.3-0.8-6-2v-0.2C6,19,6.4,18.4,7,18l2.7-1.6
      c0.7,0.3,1.4,0.6,2.3,0.6c0.8,0,1.6-0.2,2.3-0.6L17,18c0.6,0.4,1,1,1,1.7V20z M9,12v-2c0-1.7,1.3-3,3-3s3,1.3,3,3v2
      c0,1.7-1.3,3-3,3S9,13.7,9,12z M19.7,18.3c-0.3-0.9-0.9-1.6-1.7-2.1l-2.1-1.2c0.7-0.9,1.1-1.9,1.1-3.1v-2c0-2.8-2.2-5-5-5
      s-5,2.2-5,5v2c0,1.2,0.4,2.2,1.1,3.1L6,16.3c-0.8,0.5-1.4,1.2-1.8,2.1C2.9,16.6,2,14.4,2,12C2,6.5,6.5,2,12,2s10,4.5,10,10
      C22,14.4,21.1,16.6,19.7,18.3z"
    />
  </svg>
</template>
