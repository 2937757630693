<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11.5,16.2c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.3,16.2,11.5,16.2z M9.3,5.7C8.8,5.9,8.6,6.5,8.8,7
  c0.2,0.5,0.8,0.7,1.3,0.5c1.3-0.6,2.8-0.5,3.2,0.2c0.7,1.2-0.5,2.4-0.8,2.7c-1.3,1.2-2,2.1-2,3.4c0,0.6,0.4,1,1,1s1-0.4,1-1
  c0-0.5,0.2-0.9,1.4-2c1.7-1.5,2.1-3.5,1.2-5.2C14,4.8,11.3,4.7,9.3,5.7z M12,0.5C5.7,0.5,0.5,5.7,0.5,12c0,6.3,5.2,11.5,11.5,11.5
  S23.5,18.3,23.5,12C23.5,5.7,18.3,0.5,12,0.5z M12,21.5c-5.2,0-9.5-4.3-9.5-9.5S6.8,2.5,12,2.5s9.5,4.3,9.5,9.5S17.2,21.5,12,21.5z"
    />
  </svg>
</template>
