<script setup></script>

<template>
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="currentColor"
    enable-background="new 0 0 21 24"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 12.5H15.5C16.1 12.5 16.5 12.1 16.5 11.5C16.5 10.9 16.1 10.5 15.5 10.5H14.5C13.9 10.5 13.5 10.9 13.5 11.5C13.5 12.1 13.9 12.5 14.5 12.5ZM14.5 16.5H15.5C16.1 16.5 16.5 16.1 16.5 15.5C16.5 14.9 16.1 14.5 15.5 14.5H14.5C13.9 14.5 13.5 14.9 13.5 15.5C13.5 16.1 13.9 16.5 14.5 16.5ZM14.5 8.5H15.5C16.1 8.5 16.5 8.1 16.5 7.5C16.5 6.9 16.1 6.5 15.5 6.5H14.5C13.9 6.5 13.5 6.9 13.5 7.5C13.5 8.1 13.9 8.5 14.5 8.5ZM5.5 8.5H10.5C11.1 8.5 11.5 8.1 11.5 7.5C11.5 6.9 11.1 6.5 10.5 6.5H5.5C4.9 6.5 4.5 6.9 4.5 7.5C4.5 8.1 4.9 8.5 5.5 8.5ZM19.5 0.5H1.5C0.9 0.5 0.5 0.9 0.5 1.5V22.5C0.5 22.9 0.7 23.2 1 23.4C1.3 23.6 1.7 23.6 2 23.4L4.5 21.7L6.9 23.3C7.2 23.5 7.7 23.5 8 23.3L10.4 21.7L12.8 23.3C13.1 23.5 13.6 23.5 13.9 23.3L16.3 21.7L18.7 23.3C18.9 23.4 19.1 23.5 19.3 23.5C19.5 23.5 19.6 23.5 19.8 23.4C20.1 23.2 20.3 22.9 20.3 22.5V1.5C20.5 0.9 20.1 0.5 19.5 0.5ZM18.5 20.6L17.1 19.6C16.8 19.4 16.3 19.4 16 19.6L13.5 21.3L11.1 19.7C10.9 19.6 10.7 19.5 10.5 19.5C10.3 19.5 10.1 19.6 9.9 19.7L7.5 21.3L5.1 19.7C4.8 19.5 4.3 19.5 4 19.7L2.6 20.7V2.5H18.6V20.6H18.5ZM5.5 16.5H10.5C11.1 16.5 11.5 16.1 11.5 15.5C11.5 14.9 11.1 14.5 10.5 14.5H5.5C4.9 14.5 4.5 14.9 4.5 15.5C4.5 16.1 4.9 16.5 5.5 16.5ZM5.5 12.5H10.5C11.1 12.5 11.5 12.1 11.5 11.5C11.5 10.9 11.1 10.5 10.5 10.5H5.5C4.9 10.5 4.5 10.9 4.5 11.5C4.5 12.1 4.9 12.5 5.5 12.5Z"
    />
  </svg>
</template>
