<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header>
      <template v-if="$vuetify.display.smAndUp" #default>
        <strong>{{ $t('signup.fairflat.steps.header.text') }}</strong>
      </template>
      <template #right>
        <progress-steps :num-of-steps="numOfSteps" :current-step="currentStep" @change-step="currentStep = $event" />
      </template>
    </site-header>

    <v-fade-transition v-if="currentStep === 1" hide-on-leave>
      <set-name :init-name="{ first: userData.firstName, last: userData.lastName }" @save="storeName" />
    </v-fade-transition>

    <v-fade-transition v-if="currentStep === 2" hide-on-leave>
      <accept-gtc :loading="loading" @accept="register">
        <div class="text-center mt-6">
          <v-btn :block="true" class="flat-link" size="large" variant="text" @click="currentStep--">
            {{ $t('business.setup.billing.back') }}
          </v-btn>
        </div>
      </accept-gtc>
    </v-fade-transition>
  </v-row>
</template>

<script lang="ts">
import ProgressSteps from '@/components/ProgressSteps.vue'
import AcceptGtc from '@/components/signup/AcceptGtc.vue'
import SetName from '@/components/signup/SetName.vue'
import SiteHeader from '@/components/SiteHeader.vue'

export default defineComponent({
  components: {
    AcceptGtc,
    ProgressSteps,
    SiteHeader,
    SetName,
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['unauthenticated'],
      },
    })

    const userStore = useUserStore()
    const { language } = storeToRefs(useLanguageStore())

    const { logError } = useErrorHandling()
    const { userRepository } = useApi()

    return {
      user: userStore,
      language,
      logError,
      updateProfileData: userRepository.update,
      acceptGTC: userRepository.acceptGeneralTerms,
    }
  },
  data() {
    return {
      currentStep: 1,
      numOfSteps: 2,
      firstNameCompliant: false,
      lastNameCompliant: false,
      loading: false,
      gtcCompliant: false,
      userData: {
        token: '',
        password: '',
        firstName: '',
        lastName: '',
        mobile: '',
        country: '',
        // hardcode avatar for everyone. avatar is created in the frontend by using the first letters of the name
        avatar: 'f2',
        // will be overwritten by user-selected value in created
        lang: 'en',
      },
    }
  },
  head() {
    return {
      title: 'SSO Sign Up Steps',
    }
  },
  created() {
    const storedName = localStorage.getItem('signup-name')
    if (storedName) {
      const parsedName = JSON.parse(storedName) as {
        firstName: string
        lastName: string
      }
      this.userData.firstName = parsedName.firstName
      this.userData.lastName = parsedName.lastName
      this.firstNameCompliant = true
      this.lastNameCompliant = true
    }
  },
  methods: {
    goToNextStep(): void {
      this.currentStep = this.currentStep + 1
    },
    storeName(name: { first: string; last: string }): void {
      this.userData.firstName = name.first
      this.userData.lastName = name.last
      localStorage.setItem(
        'signup-name',
        JSON.stringify({
          firstName: this.userData.firstName,
          lastName: this.userData.lastName,
        })
      )

      this.goToNextStep()
    },
    async register(): Promise<void> {
      this.loading = true
      try {
        if (this.user.firstName) {
          this.userData.firstName = this.user.firstName
        }
        if (this.user.lastName) {
          this.userData.lastName = this.user.lastName
        }
        this.userData.lang = this.language
        await this.updateProfileData(this.userData)
        await this.acceptGTC()
        // clear signup localStorage
        localStorage.removeItem('signup-name')

        // and redirect to /welcome
        // use traditional location.href instead of view router here
        // because the tracking on the welcome page needs a real load
        window.location.href = '/welcome'
      } catch (error) {
        this.logError('Failed to register user', error)
      } finally {
        this.loading = false
      }
    },
  },
})
</script>

<style scoped lang="sass">
.checkbox-links
  margin-top: -25px
  margin-left: 32px

.signup-steps
  +center-content
  +add-focussed-form-layout
  +add-lateral-site-padding
  +add-vertical-main-padding

  &-wrapper
    display: flex
    flex-direction: column
    flex: 1 0 auto

  &__step
    display: flex
    flex-direction: column
    align-items: center

.siteheader
  +max-width
    padding-left: 24px
    padding-right: 24px

.layout-f
  display: flex
  justify-content: center
  align-items: center
  height: 110px
  border-top: 1px solid $c-border
  +max-width
    height: 74px
    padding-left: 30px
    padding-right: 30px

// positioning inside of signup-steps
.progress-steps
  width: 400px
  top: 14px
  +max-width
    top: 10px
</style>
