<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path d="M9.3,16.1h1.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H9.3c-0.5,0-0.9,0.4-0.9,0.9S8.8,16.1,9.3,16.1z" />
    <path d="M9.3,18.8h1.8c0.5,0,0.9-0.4,0.9-0.9S11.6,17,11.1,17H9.3c-0.5,0-0.9,0.4-0.9,0.9S8.8,18.8,9.3,18.8z" />
    <path d="M9.3,13.4h1.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H9.3c-0.5,0-0.9,0.4-0.9,0.9S8.8,13.4,9.3,13.4z" />
    <path
      d="M22.3,4.3l-9.1-2.7c-0.3-0.1-0.6,0-0.8,0.1C12.1,1.9,12,2.2,12,2.5v3.6C12,6.6,12.4,7,12.9,7s0.9-0.4,0.9-0.9V3.7l7.3,2.2
    v14.8h-5.5V8.8c0-0.5-0.4-0.9-0.9-0.9H5.6c-0.5,0-0.9,0.4-0.9,0.9v2.7H2c-0.5,0-0.9,0.4-0.9,0.9v9.1c0,0.5,0.4,0.9,0.9,0.9h3.6H22
    c0.5,0,0.9-0.4,0.9-0.9V5.2C22.9,4.8,22.6,4.4,22.3,4.3z M2.9,13.4h1.8v7.3H2.9V13.4z M13.8,20.6H6.5V9.7h7.3V20.6z"
    />
    <path d="M19.3,17.9V8.8c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v9.1c0,0.5,0.4,0.9,0.9,0.9S19.3,18.4,19.3,17.9z" />
  </svg>
</template>
