import createBusinessRepository from '@/repository/business.ts'
import createDocumentRepository from '@/repository/document.ts'
import createDirectDocumentRepository from '@/repository/direct-sign/document.ts'
import createHubspotRepository from '@/repository/hubspot.ts'
import createIdentificationRepository from '@/repository/identification.ts'
import createDirectIdentificationRepository from '@/repository/direct-sign/identification.ts'
import createPaymentRepository from '@/repository/payment.ts'
import createSealRepository from '@/repository/seal.ts'
import createSearchRepository from '@/repository/search.ts'
import createSrRepository from '@/repository/signatureRequest.ts'
import createDirectSrRepository from '@/repository/direct-sign/signatureRequest.ts'
import createSigningRepository from '@/repository/signing.ts'
import createDirectSigningRepository from '@/repository/direct-sign/signing.ts'
import createSsoRepository from '@/repository/sso.ts'
import createTanRepository from '@/repository/tan.ts'
import createDirectTanRepository from '@/repository/direct-sign/tan.ts'
import createUserRepository from '@/repository/user.ts'
import createDirectUserRepository from '@/repository/direct-sign/user.ts'
import createSystemRepository from '@/repository/system.ts'
import createVisualSignatureRepository from '@/repository/visualSignature.ts'
import createDirectVisualSignatureRepository from '@/repository/direct-sign/visualSignature.ts'
import createActivityRepository from '@/repository/activity.ts'
import createLegacyRepository from '@/repository/legacy.ts'

export default function useApi() {
  const { $api, $verificationApi, $abortController } = useNuxtApp()

  const abortableApi = $api.create({
    signal: $abortController.instance.signal,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const createAbortableRepository = <T extends (fetch: CustomFetch) => any>(setup: T) => {
    const abortHandler = {
      get(target, prop, receiver) {
        const value = Reflect.get(target, prop, receiver)
        if (typeof value === 'function') {
          return async (...args) => {
            try {
              return await value(...args)
            } catch (error) {
              if (error instanceof Error && error.message.includes($abortController.message)) {
                console.debug('Aborted request:', prop)
              } else {
                throw error
              }
            }
          }
        }
      },
    }

    const repository = setup(abortableApi)

    return new Proxy<ReturnType<T>>(repository, abortHandler)
  }

  return {
    businessRepository: createBusinessRepository($api),
    documentRepository: createDocumentRepository($api),
    directDocumentRepository: createDirectDocumentRepository($api),
    hubspotRepository: createHubspotRepository($api),
    identificationRepository: createIdentificationRepository($api),
    directIdentificationRepository: createDirectIdentificationRepository($api),
    paymentRepository: createPaymentRepository($api),
    sealRepository: createSealRepository($api),
    searchRepository: createSearchRepository($api),
    srRepository: createSrRepository($api),
    directSrRepository: createDirectSrRepository($api),
    signingRepository: createSigningRepository($api),
    directSigningRepository: createDirectSigningRepository($api),
    ssoRepository: createSsoRepository($api),
    tanRepository: createTanRepository($verificationApi),
    directTanRepository: createDirectTanRepository($api),
    userRepository: createUserRepository($api),
    directUserRepository: createDirectUserRepository($api),
    systemRepository: createSystemRepository($api),
    visualSignatureRepository: createVisualSignatureRepository($api),
    directVisualSignatureRepository: createDirectVisualSignatureRepository($api),
    activityRepository: createActivityRepository($api),
    legacyRepository: createLegacyRepository($api),
  }
}
