// TODO: This composable should be converted to utils
import { bool, mixed, number, string } from 'yup'

const phonePattern = /^[+]?[\s \d-]{6,}$/
const domainPattern = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default function useValidation() {
  const isTrue = (value: unknown) => {
    return bool().required().isTrue().isValidSync(value)
  }

  const isRequired = (value: unknown) => {
    return mixed().required().isValidSync(value)
  }

  const isString = (value: unknown): boolean => {
    return string().required().isValidSync(value)
  }

  const isEmptyString = (value: unknown): boolean => {
    if (typeof value !== 'string') return false
    return value.trim() === ''
  }

  const isNumber = (value: unknown): boolean => {
    return number().required().isValidSync(value)
  }

  const isInteger = (value: unknown): boolean => {
    return number().required().integer().isValidSync(value)
  }

  const isNumberRange = (value: unknown, min?: number, max?: number): boolean => {
    let chain = number()
    if (min) chain = chain.min(min)
    if (max) chain = chain.max(max)
    return chain.required().isValidSync(value)
  }

  // TODO: Use libphonenumber
  const isPhoneNumber = (value: unknown): boolean => {
    if (typeof value === 'string') {
      return phonePattern.test(value)
    } else if (typeof value === 'number') {
      return phonePattern.test(value.toString())
    }
    return false
  }

  const isEmail = (value: unknown): boolean => {
    return string().required().email().isValidSync(value)
  }

  const isDomain = (value: string) => {
    if (typeof value !== 'string' || !value) return false
    return domainPattern.test(value)
  }

  return {
    isTrue,
    isRequired,
    isString,
    isEmptyString,
    isNumber,
    isPhoneNumber,
    isInteger,
    isNumberRange,
    isEmail,
    isDomain,
  }
}
