<template>
  <v-container class="pa-0">
    <v-row class="pb-10 flex-md-nowrap" align="center" justify="space-between">
      <v-col cols="12" md="auto" class="flex-shrink-1">
        <skr-heading level="1">{{ $t('business.developers.api.title') }}</skr-heading>
        <skr-heading level="2">{{ $t('business.developers.api.subtitle') }}</skr-heading>
      </v-col>
      <v-col cols="12" md="auto">
        <skr-button
          size="lg"
          data-cy="addDomainsButton"
          :block="$vuetify.display.smAndDown"
          @click="addApiUserDialog = true"
        >
          {{ $t('business.developers.api.create_key') }}
        </skr-button>
      </v-col>
    </v-row>
  </v-container>
  <v-data-table
    class="mb-4"
    sort-asc-icon="custom:arrow_up"
    sort-desc-icon="custom:arrow_down"
    :items-per-page-options="[5, 10, 25, 50]"
    :items-per-page-text="$t('global.rows_per_page')"
    :headers="apiUserHeaders"
    :items="apiUsers"
    :items-per-page="12"
    :sort-by="[{ key: 'username', order: 'asc' }]"
    mobile-breakpoint="0"
  >
    <template v-if="apiUsers.length <= 12" #bottom></template>
    <template #no-data>
      {{ $t('business.developers.api.table.no_api_key') }}
    </template>
    <template #[`item.username`]="{ item }">
      <div class="text-truncate">
        <strong>{{ item.username }}</strong>
      </div>
    </template>
    <template #[`item.description`]="{ item }">
      <div>{{ item.description }}</div>
    </template>
    <template #[`item.type`]="{ item }">
      <skr-chip :color="typeColor(item.type)">
        {{ dynamicTranslations.apiTableType[item.type] }}
      </skr-chip>
    </template>
    <template #[`item.options`]="{ item }">
      <v-menu location="bottom center">
        <template #activator="{ props }">
          <v-btn variant="text" icon color="primary" :ripple="false" v-bind="props">
            <v-icon color="text">custom:gear</v-icon>
          </v-btn>
        </template>
        <v-list density="compact" class="pa-1">
          <v-list-item
            v-if="!item.memberIsAdmin"
            prepend-icon="custom:pencil"
            @click="showEditDescriptionDialog(item.username, item.description)"
          >
            <v-list-item-title v-if="item.description">
              {{ $t('business.developers.api.description.edit') }}
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ $t('business.developers.api.description.add') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!item.memberIsAdmin"
            prepend-icon="custom:reset"
            class="danger-on-hover"
            @click="showEditApiUserDialog(item.username, 'reset')"
          >
            <v-list-item-title>
              {{ $t('business.developers.api.table.reset') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            v-if="!item.subscriptionActive || item.renewalCancelled"
            prepend-icon="custom:bin"
            class="danger-on-hover"
            @click="showEditApiUserDialog(item.username, 'delete')"
          >
            <v-list-item-title>
              {{ $t('business.developers.api.table.delete') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
  <div>
    <i18n-t keypath="business.developers.api.help.text">
      <template #link>
        <a :href="$t('business.developers.api.help.link_url')" target="_blank" rel="noopener">
          {{ $t('business.developers.api.help.link_text') }}
        </a>
      </template>
    </i18n-t>
  </div>

  <v-dialog v-model="addApiUserDialog" width="500">
    <v-card>
      <v-card-title>
        {{ $t('business.developers.api.create.dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p class="mb-4">
          <strong>{{ $t('business.developers.api.create.dialog.text') }}</strong>
        </p>
        <card drop-padding class="mb-8">
          <v-radio-group v-model="selectedQualityType" class="biz-api-type-selector" hide-details>
            <v-radio
              true-icon="custom:radio_button_active"
              false-icon="custom:radio_button_inactive"
              value="demo"
              class="pa-4 ma-0 align-start"
            >
              <template #label>
                <div class="pl-2 pb-2">
                  <p class="font-weight-bold text-skribbleu my-2">
                    {{ $t('business.developers.api.create.dialog.demo.title') }}
                  </p>
                  <p />
                  <p>
                    {{ $t('business.developers.api.create.dialog.demo.text') }}
                  </p>
                </div>
              </template>
            </v-radio>
            <v-divider />
            <v-radio
              true-icon="custom:radio_button_active"
              false-icon="custom:radio_button_inactive"
              value="production"
              class="pa-4 ma-0 align-start"
              :disabled="isTrialPlan"
            >
              <template #label>
                <div class="pl-2 pb-2">
                  <p class="font-weight-bold my-2" :class="{ 'text-skribbleu': !isTrialPlan }">
                    {{ $t('business.developers.api.create.dialog.prod.title') }}
                  </p>
                  <p />
                  <p>
                    {{ $t('business.developers.api.create.dialog.prod.text') }}
                  </p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </card>
        <p class="pb-4">
          <strong>{{ $t('business.developers.api.description.use') }}</strong>
        </p>
        <v-textarea
          v-model="description"
          data-cy="descriptionInput"
          :label="$t('business.developers.api.description.optional')"
          :hint="$t('business.developers.api.description.hint')"
          name="fname"
          clearable
          clear-icon="custom:clear"
          auto-grow
          rows="2"
          counter="100"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" size="x-large" color="info" variant="outlined" @click="addApiUserDialog = false">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="info"
          variant="elevated"
          :disabled="loading || !selectedQualityType"
          :loading="loading"
          @click="createApiUser()"
        >
          {{ $t('business.developers.api.create.dialog.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-if="editedUser" v-model="apiUserDataDialog" persistent width="500">
    <v-card>
      <v-card-title>
        {{ $t('business.developers.api.data.dialog.title') }}
      </v-card-title>
      <v-card-text>
        <p>{{ $t('business.developers.api.data.dialog.text_upper') }}</p>
        <div class="business-api__removal-domain-label mt-6 mb-2">
          {{ $t('business.developers.api.data.dialog.user_label') }}
        </div>
        <read-only-text-field
          copy-to-clipboard
          :snackbar-message="$t('business.developers.api.data.dialog.user_copied')"
          :tooltip-message="$t('business.developers.api.data.dialog.copy_user')"
          class="mb-6"
          :value="editedUser.username"
        />
        <div class="business-api__removal-domain-label mt-6 mb-2">
          {{ $t('business.developers.api.data.dialog.api_label') }}
        </div>
        <read-only-text-field
          copy-to-clipboard
          :snackbar-message="$t('business.developers.api.data.dialog.key_copied')"
          :tooltip-message="$t('business.developers.api.data.dialog.copy_key')"
          class="mb-6"
          :value="editedUser.apiKey"
        />
        <p>{{ $t('business.developers.api.data.dialog.text_lower') }}</p>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="elevated" @click="apiUserDataDialog = false">
          {{ $t('global.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="editApiUserDialog" width="500">
    <v-card>
      <v-card-title>
        {{ dynamicTranslations.dialogTitle[editApiUserOperation] }}
      </v-card-title>
      <v-card-text>
        {{ dynamicTranslations.dialogText[editApiUserOperation] }}
        <read-only-text-field class="my-6" :value="apiUserToEdit" />
        <skr-checkbox v-model="editApiUserConsent" color="error">
          <span :class="{ 'text-error': editApiUserConsent }">
            {{ dynamicTranslations.consentLabel[editApiUserOperation] }}
          </span>
        </skr-checkbox>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" size="x-large" color="error" variant="outlined" @click="editApiUserDialog = false">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="error"
          variant="elevated"
          :disabled="loading || !editApiUserConsent"
          :loading="loading"
          @click="editApiUser()"
        >
          {{ dynamicTranslations.cta[editApiUserOperation] }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="editDescriptionDialog" width="500">
    <v-card>
      <v-card-title>{{ $t('business.developers.api.description.title') }}</v-card-title>
      <v-card-text>
        <v-textarea
          v-model="description"
          data-cy="descriptionInput"
          :label="$t('business.developers.api.description.add')"
          :hint="$t('business.developers.api.description.hint')"
          name="fname"
          variant="filled"
          clearable
          clear-icon="custom:clear"
          auto-grow
          rows="4"
          counter="100"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" color="info" size="x-large" variant="outlined" @click="editDescriptionDialog = false">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="info"
          size="x-large"
          variant="elevated"
          :disabled="loading"
          :loading="loading"
          @click="editDescription()"
        >
          {{ $t('business.developers.api.description.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Card from '@/components/Card.vue'
import ReadOnlyTextField from '@/components/ReadOnlyTextField.vue'
import SkrChip from '@/components/SkrChip.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import SkrCheckbox from '@/components/SkrCheckbox.vue'
import SkrHeading from '@/components/SkrHeading.vue'

const maxNumberOfApiUsers = 10

export default defineComponent({
  components: {
    SkrHeading,
    SkrButton,
    SkrCheckbox,
    Card,
    ReadOnlyTextField,
    SkrChip,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
      accessControl: {
        roles: ['admin'],
      },
    })

    const businessStore = useBusinessStore()
    const widgetStore = useWidgetStore()
    const { activePricePlanId } = useUserPlanInfo()
    const isTrialPlan = computed(() => activePricePlanId.value?.startsWith('TRIAL'))

    return { businessStore, widgetStore, isTrialPlan }
  },
  data() {
    return {
      description: '',
      apiUsers: [] as {
        username: string
        description: string
        type: string
      }[],
      apiUserDataDialog: false,
      apiUserToEdit: undefined as string | undefined,
      addApiUserDialog: false,
      editedUser: null as null | APIKeyCreateUser | APIKeyReset,
      editApiUserConsent: false,
      editApiUserDialog: false,
      editDescriptionDialog: false,
      editApiUserOperation: 'delete',
      loading: false,
      mainNavDrawer: false,
      pagination: {
        sortBy: 'user',
        rowsPerPage: 12,
        descending: false,
      },
      selectedQualityType: 'demo',
      apiUserHeaders: [
        {
          title: this.$t('business.developers.api.table.header.user'),
          value: 'username',
          sortable: true,
        },
        {
          title: this.$t('business.developers.api.table.header.description'),
          value: 'description',
          sortable: false,
        },
        {
          title: this.$t('business.developers.api.table.header.type'),
          value: 'type',
          sortable: true,
          align: 'center',
        },
        {
          title: this.$t('business.developers.api.table.header.manage'),
          value: 'options',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  head() {
    return {
      title: 'Business - Developers - API',
    }
  },
  computed: {
    dynamicTranslations(): Record<string, Record<string, string>> {
      return {
        apiTableType: {
          demo: this.$t('business.developers.api.table.type.demo'),
          production: this.$t('business.developers.api.table.type.production'),
        },
        dialogTitle: {
          delete: this.$t('business.developers.api.delete.dialog.title'),
          reset: this.$t('business.developers.api.reset.dialog.title'),
        },
        dialogText: {
          delete: this.$t('business.developers.api.delete.dialog.text'),
          reset: this.$t('business.developers.api.reset.dialog.text'),
        },
        consentLabel: {
          delete: this.$t('business.developers.api.delete.dialog.consent_label'),
          reset: this.$t('business.developers.api.reset.dialog.consent_label'),
        },
        cta: {
          delete: this.$t('business.developers.api.delete.dialog.cta'),
          reset: this.$t('business.developers.api.reset.dialog.cta'),
        },
        limitReached: {
          demo: this.$t('business.developers.api.create.error.limit_reached.demo', { limit: maxNumberOfApiUsers }),
          production: this.$t('business.developers.api.create.error.limit_reached.production', {
            limit: maxNumberOfApiUsers,
          }),
        },
      }
    },
  },
  watch: {
    addApiUserDialog(value) {
      if (!value) {
        this.description = ''
      }
    },
    editDescriptionDialog(value) {
      if (!value) {
        this.description = ''
      }
    },
    apiUserDataDialog(value) {
      if (!value) {
        this.editedUser = null
      }
    },
    editApiUserDialog(value) {
      if (!value) {
        this.editApiUserConsent = false
        this.editApiUserOperation = 'delete'
        this.apiUserToEdit = undefined
      }
    },
  },
  created() {
    void this.loadUsers()
  },
  methods: {
    async loadUsers() {
      const response = await this.businessStore.getApiUsers()
      this.apiUsers = this.transformListForTable(response)
    },
    refIsInput(ref: vueRef): ref is HTMLInputElement {
      return !!(ref && 'select' in ref)
    },
    changeSort(column: string): void {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    async createApiUser() {
      this.loading = true
      const response = await this.businessStore.createApiUser({
        type: this.selectedQualityType,
        description: this.description,
      })
      if ('status' in response && response.status === 'error') {
        // there is a maximum of API users of each type that can be created
        this.widgetStore.createSnackbar({
          message:
            this.selectedQualityType === 'demo'
              ? this.$t('business.developers.api.create.error.limit_reached.demo', { limit: maxNumberOfApiUsers })
              : this.$t('business.developers.api.create.error.limit_reached.production', {
                  limit: maxNumberOfApiUsers,
                }),
        })
      } else {
        // show data to user
        this.editedUser = response.user
        this.apiUserDataDialog = true
        // add new user to list of api users
        this.apiUsers.push({
          username: response.user.username ?? '',
          type: this.selectedQualityType,
          description: this.description,
        })
      }
      // clean up other dialog
      this.loading = false
      this.addApiUserDialog = false
    },
    async editApiUser() {
      if (this.apiUserToEdit === undefined) return

      this.loading = true
      if (this.editApiUserOperation === 'delete') {
        await this.businessStore.deleteApiUser(this.apiUserToEdit)
        // remove user from list of api users
        const filteredList = this.apiUsers.filter(user => {
          return user.username !== this.apiUserToEdit
        })
        this.apiUsers = filteredList
        this.widgetStore.createSnackbar({
          message: this.$t('business.developers.api.delete.dialog.key_deleted'),
        })
      } else if (this.editApiUserOperation === 'reset') {
        const response = await this.businessStore.resetApiUser(this.apiUserToEdit)
        // show new data to user
        this.editedUser = response.user
        this.apiUserDataDialog = true
      }
      this.loading = false
      this.editApiUserDialog = false
    },
    // show confirmation dialog for operation which is either
    // 'delete' or 'reset'
    showEditApiUserDialog(username: string, operation: string): void {
      this.apiUserToEdit = username
      this.editApiUserOperation = operation
      this.editApiUserDialog = true
    },
    typeColor(type: string): string {
      switch (type) {
        case 'production':
          return 'success'
        case 'demo':
          return 'warning'
        default:
          return ''
      }
    },
    transformListForTable(list: APIKeyList): { username: string; type: string; description: string }[] {
      return list.map(({ name, description }) => {
        const type = name.substring(4, 8) === 'demo' ? 'demo' : 'production'
        return { username: name, type, description }
      })
    },
    showEditDescriptionDialog(username: string, description: string): void {
      this.apiUserToEdit = username
      this.description = description
      this.editDescriptionDialog = true
    },
    async editDescription() {
      if (this.apiUserToEdit === undefined) return

      this.loading = true

      const response = await this.businessStore.updateApiUser({
        username: this.apiUserToEdit,
        description: this.description,
      })

      const userIndex = this.apiUsers.findIndex(({ username }) => username === this.apiUserToEdit)
      this.apiUsers[userIndex].description = response.user.description
      this.loading = false
      this.editDescriptionDialog = false
    },
  },
})
</script>

<style lang="sass">
.biz-api-type-selector
  .v-selection-control__input
    align-self: baseline
</style>
