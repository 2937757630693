<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M21,2H3C1.3,2,0,3.3,0,5v14c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V5C24,3.3,22.7,2,21,2z M22,19c0,0.6-0.4,1-1,1H3
  c-0.6,0-1-0.4-1-1v-7h20V19z M22,10H2V8h20V10z M22,6H2V5c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1V6z M18,17h1c0.6,0,1-0.4,1-1
  s-0.4-1-1-1h-1c-0.6,0-1,0.4-1,1S17.4,17,18,17z M5,17h5c0.6,0,1-0.4,1-1s-0.4-1-1-1H5c-0.6,0-1,0.4-1,1S4.4,17,5,17z"
    />
  </svg>
</template>
