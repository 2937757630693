<template>
  <v-container class="fill-height content">
    <v-row class="fill-height" align="center">
      <v-col>
        <div class="mb-10">
          <skr-heading level="1" semantic>{{ $t('password_reset.mail_sent.title') }}</skr-heading>
          <skr-heading level="2">{{
            $t('password_reset.mail_sent.subtitle', { email: $route.params.email })
          }}</skr-heading>
        </div>
      </v-col>
      <v-col v-if="$vuetify.display.mdAndUp">
        <responsive-image source="confirm-email-feedback" :width="453" :height="400" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    SkrHeading,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      layout: 'unauthenticated',
      accessControl: {
        roles: ['unauthenticated'],
      },
    })
  },
  head() {
    return {
      title: 'Password Reset - Mail Sent',
    }
  },
})
</script>
