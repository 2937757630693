<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    enable-background="new 0 0 32 32"
    xml:space="preserve"
  >
    <path
      d="M20.9,10.9l-6.9,6.9l-3-3c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l4.1,4c0.3,0.3,0.7,0.4,1,0.4
  c0.4,0,0.8-0.1,1.1-0.4l7.9-8c0.6-0.6,0.6-1.5,0-2.1C22.5,10.4,21.5,10.4,20.9,10.9z M16,1C7.7,1,1,7.7,1,16s6.7,15,15,15
  c8.3,0,15-6.7,15-15S24.3,1,16,1z M16,28.4C9.2,28.4,3.6,22.8,3.6,16S9.2,3.6,16,3.6c6.8,0,12.4,5.6,12.4,12.4S22.8,28.4,16,28.4z"
    />
  </svg>
</template>
