<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path d="M21,0H3C1.3,0,0,1.3,0,3v18c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V3C24,1.3,22.7,0,21,0z M20,20H4V4h16V20z" />
  </svg>
</template>
