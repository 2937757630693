<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10
  S17.5,22,12,22z M15.3,9.2L12,12.5L8.7,9.2c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l4.1,4.1c0.2,0.2,0.5,0.3,0.8,0.3
  s0.6-0.1,0.8-0.3l4.1-4.1c0.4-0.4,0.4-1.1,0-1.6C16.4,8.8,15.7,8.8,15.3,9.2z"
    />
  </svg>
</template>
