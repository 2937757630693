<script setup></script>

<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9387_1196)">
      <path
        d="M26.6719 9.33398H30.6719"
        stroke="currentColor"
        stroke-width="2.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33594 9.33301H16.5359"
        stroke="currentColor"
        stroke-width="2.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.0693 4H30.6693V30.6667H1.33594V4H21.8693"
        stroke="currentColor"
        stroke-width="2.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M8 4V9.33333" stroke="currentColor" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M16.7995 14.3999L14.1328 11.7333L23.9995 1.8666C24.7995 1.0666 25.8661 1.0666 26.6661 1.8666C27.4661 2.6666 27.4661 3.73327 26.6661 4.53327L16.7995 14.3999Z"
        stroke="currentColor"
        stroke-width="2.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.07148 15.7336C10.1381 14.6669 11.7381 14.6669 12.8048 15.7336C13.8715 16.8003 13.8715 18.4003 12.8048 19.4669C11.7381 20.5336 8.13815 20.4003 8.13815 20.4003C8.13815 20.4003 8.00481 16.6669 9.07148 15.7336Z"
        stroke="currentColor"
        stroke-width="2.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9387_1196">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
