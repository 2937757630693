<script setup></script>

<template>
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.5 3.7C13.3618 3.7 4.31705 12.7801 4.31705 24C4.31705 35.2199 13.3618 44.3 24.5 44.3C35.6382 44.3 44.683 35.2199 44.683 24C44.683 12.7801 35.6382 3.7 24.5 3.7ZM0.917053 24C0.917053 10.9194 11.467 0.300003 24.5 0.300003C37.5331 0.300003 48.083 10.9194 48.083 24C48.083 37.0806 37.5331 47.7 24.5 47.7C11.467 47.7 0.917053 37.0806 0.917053 24Z"
      fill="currentColor"
    />
    <path
      d="M15.5479 24C17.1959 24 18.5319 22.6569 18.5319 21C18.5319 19.3431 17.1959 18 15.5479 18C13.8998 18 12.5638 19.3431 12.5638 21C12.5638 22.6569 13.8998 24 15.5479 24Z"
      fill="currentColor"
    />
    <path
      d="M33.4524 24C35.1004 24 36.4364 22.6569 36.4364 21C36.4364 19.3431 35.1004 18 33.4524 18C31.8043 18 30.4683 19.3431 30.4683 21C30.4683 22.6569 31.8043 24 33.4524 24Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8428 32C14.8428 31.0611 15.6039 30.3 16.5428 30.3H32.4577C33.3966 30.3 34.1577 31.0611 34.1577 32C34.1577 32.9389 33.3966 33.7 32.4577 33.7H16.5428C15.6039 33.7 14.8428 32.9389 14.8428 32Z"
      fill="currentColor"
    />
  </svg>
</template>
