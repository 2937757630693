<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22,5.3h-9.5l-2.4-3.2C9.9,1.9,9.6,1.7,9.3,1.7H2c-0.6,0-1,0.4-1,1v16.4c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.3,0.8,2,0.8h3.6
  c0.6,0,1-0.4,1-1s-0.4-1-1-1H3.8c-0.2,0-0.4-0.1-0.6-0.2C3.1,19.5,3,19.3,3,19.1V3.7h5.8l2.4,3.2c0.2,0.3,0.5,0.4,0.8,0.4h9v11.7
  c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-3.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6c0.8,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2
  V6.3C23,5.8,22.6,5.3,22,5.3z M12,19.5c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S12.8,19.5,12,19.5z M15.8,10.5
  c-1.1-1.8-4-1.9-6.2-1c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.5,0.8,0.7,1.3,0.5c1.4-0.6,3.2-0.5,3.7,0.2c0.7,1.1-0.5,2.3-0.9,2.7
  c-1.5,1.2-2.2,2.1-2.2,3.5c0,0.6,0.4,1,1,1s1-0.4,1-1c0-0.5,0.2-0.8,1.5-1.9C16.3,14.2,16.8,12.2,15.8,10.5z"
    />
  </svg>
</template>
