<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22.7,19.3l-4.9-4.9c0.7-1.3,1.2-2.8,1.2-4.4c0-5-4-9-9-9s-9,4-9,9s4,9,9,9c1.6,0,3.1-0.4,4.4-1.2l4.9,4.9
  c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2-2C23.1,20.3,23.1,19.7,22.7,19.3z M3,10c0-3.9,3.1-7,7-7s7,3.1,7,7s-3.1,7-7,7
  S3,13.9,3,10z"
    />
  </svg>
</template>
