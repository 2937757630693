interface GetVisualSignaturesResponse {
  defaultSignature: string // The ID of the default signature in the `signatures` array
  signatures: {
    id: string
    alias: string
    name?: string
    optionalLine?: string
    location?: string
    displayDate: boolean
    displayClaim: boolean
    displayStandards: boolean
    default: boolean
    hasCustomImage?: boolean
  }[]
}

const visualSignatureRepository = (fetch: CustomFetch) => ({
  async create(data: Omit<VisualSignatureDetails, 'id'> & { customImage?: CustomImageData }) {
    const { company, ...rest } = data

    const payload = {
      ...rest,
      optionalLine: company,
    }

    await fetch('/v1/user/visual-signatures', {
      method: 'POST',
      body: objectToSnakeCase(payload),
    })
  },
  async update(id: string, data: Partial<Omit<VisualSignatureDetails, 'id'>>) {
    const { company, isDefault, ...rest } = data

    const payload = {
      ...rest,
      default: isDefault,
      optionalLine: company,
    }

    await fetch(`/v1/user/visual-signatures/${id}`, {
      method: 'PUT',
      body: objectToSnakeCase(payload),
    })
  },
  async delete(id: string) {
    await fetch(`/v1/user/visual-signatures/${id}`, { method: 'DELETE' })
  },
  async getAll(): Promise<{ defaultSignature: string; signatures: VisualSignatureDetails[] }> {
    const response = await fetch<GetVisualSignaturesResponse>('/v1/user/visual-signatures')

    if (!response.signatures?.length) {
      return {
        defaultSignature: '',
        signatures: [],
      }
    }

    const signatures = response.signatures.map<VisualSignatureDetails>(signature => ({
      id: signature.id,
      alias: signature.alias,
      name: signature.name ?? '',
      company: signature.optionalLine ?? '',
      location: signature.location ?? '',
      hasCustomImage: Boolean(signature.hasCustomImage), // FIXME: Backend sends `null` instead of `false`
      displayDate: Boolean(signature.displayDate), // FIXME: Backend may send `null` instead of `false`
      displayClaim: Boolean(signature.displayClaim), // FIXME: Backend may send `null` instead of `false`
      displayStandards: Boolean(signature.displayStandards), // FIXME: Backend may send `null` instead of `false`
    }))

    return {
      defaultSignature: response.defaultSignature,
      signatures,
    }
  },
  async getDefault(params: Record<VisualSignatureParam, string>) {
    const query = new URLSearchParams(params)
    return fetch<Blob>(`/v1/user/visual-signature?${query.toString()}`)
  },
  async getPredefined(
    signatureId: string,
    params: Record<Extract<VisualSignatureParam, 'qual' | 'leg' | 'prov'>, string>
  ) {
    const query = new URLSearchParams(params)
    return fetch<Blob>(`/v1/user/visual-signatures/${signatureId}?${query.toString()}`)
  },
  async getPlaceholder(params: Record<Extract<VisualSignatureParam, 'qual' | 'leg' | 'prov'>, string>) {
    const query = new URLSearchParams(params)
    return fetch<Blob>(`/v1/user/preview-visual-signature?${query.toString()}`)
  },
  async getPreview(params: Record<VisualSignatureParam, string>, imageData?: CustomImageData | null) {
    const query = new URLSearchParams(params)
    return fetch<Blob>(`/v1/user/preview-visual-signature?${query.toString()}`, {
      method: 'POST',
      body: imageData ? { image: objectToSnakeCase(imageData) } : {},
    })
  },
  async setImage(signatureId: string, imageData: CustomImageData) {
    await fetch(`/v1/images/signature/${signatureId}`, {
      method: 'POST',
      body: objectToSnakeCase(imageData),
    })
  },
  async deleteImage(signatureId: string) {
    await fetch(`/v1/images/signature/${signatureId}`, { method: 'DELETE' })
  },
})

export default visualSignatureRepository
