import { camelize, camelizeKeys, decamelizeKeys } from 'humps'

export function objectToCamelCase<R = undefined, I = unknown>(obj: I): R extends undefined ? Camelized<I> : R {
  return camelizeKeys(obj, (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))) as never
}

export function objectToSnakeCase<R = undefined, I = unknown>(obj: I): R extends undefined ? Decamelized<I> : R {
  return decamelizeKeys(obj, (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))) as never
}

export function stringToCamelCase(str: string) {
  return camelize(str)
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
