export default defineNuxtPlugin({
  name: 'userpilot',
  async setup() {
    const { features } = useRuntimeConfig().public

    if (!features.userpilot) return {}

    const { Userpilot } = await import('userpilot')

    let userpilotActive = false
    const additionalData = {}

    const reload = () => {
      if (!userpilotActive) return
      const { id: userId, isBusinessAdmin, email, signerIdentityEmail } = useUserStore()

      if (!userId) {
        Userpilot.anonymous()
        Userpilot.reload()
        return
      }

      const { language } = useLanguageStore()
      const { activePricePlanId, planStart } = useUserPlanInfo()
      const { id: business } = useBusinessStore()

      const data = {
        email: signerIdentityEmail || email, // first value is used by NAS for emails, otherwise use the email
        business,
        username: userId,
        role: isBusinessAdmin ? 'admin' : 'user',
        plan: activePricePlanId.value,
        locale_code: language,
        plan_start: planStart.value,
        ...additionalData,
      }

      Userpilot.identify(userId, data)
      Userpilot.reload()
    }

    const initialize = () => {
      const { settings } = useBusinessStore()
      const { activePricePlanId } = useUserPlanInfo()
      const isFreeLegacy = activePricePlanId.value === 'free_legacy'
      const enabled = isFreeLegacy || Boolean(settings.userPilot?.enabled)
      if (enabled === userpilotActive) {
        // ... nothing to do
      } else if (enabled) {
        Userpilot.initialize('NX-b832279d')
        userpilotActive = true
        reload()
      } else {
        Userpilot.destroy()
        userpilotActive = false
      }
    }

    const setData = (data: Record<string, number | string>) => {
      Object.assign(additionalData, data)
    }

    return {
      provide: {
        userpilot: {
          initialize,
          reload,
          setData,
        },
      },
    }
  },
})
