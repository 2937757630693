<template>
  <v-app>
    <v-container v-if="error && error.statusCode !== 401" class="fill-height">
      <v-row class="fill-height" align="center" align-content="center">
        <v-col cols="12" md="5" order="2" order-md="1">
          <div class="mb-10">
            <skr-heading level="1" semantic>{{ t('error.title') }}</skr-heading>
            <skr-heading level="2">{{ t('error.subtitle') }}</skr-heading>
          </div>
          <skr-button size="lg" block :href="redirectUrl">{{ t('error.button') }}</skr-button>
        </v-col>
        <v-col cols="12" md="7" order="1" order-md="2">
          <responsive-image source="error-404" :width="407" :height="436" />
        </v-col>
      </v-row>
    </v-container>
    <!-- For unauthorized users that end up here we display nothing as they
      are being redirected to the login page -->
    <!-- Uncomment below to display error in page -->
    <!-- {{ error }} -->
  </v-app>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { NuxtError } from '#app'

import SkrHeading from '@/components/SkrHeading.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
    SkrButton,
    SkrHeading,
  },
  props: {
    error: Object as PropType<NuxtError>,
  },
  setup() {
    useErrorHandling()

    const { t } = useI18n()

    const { domainName } = useRuntimeConfig().public

    const redirectUrl = computed(() => `https://${domainName}/`)

    return { t, redirectUrl }
  },
})
</script>
