<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.7,10.3l-2.3-6.4h0.6c0.6,0,1-0.4,1-1s-0.4-1-1-1H4.9c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.6l-2.3,6.4c-0.1,0.3-0.1,0.7,0.1,0.9
  l7.8,10.6c0,0,0,0,0.1,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.1h0c0.2,0,0.3,0,0.4-0.1
  c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0,0l7.8-10.6C20.8,10.9,20.8,10.6,20.7,10.3z M12,10.9c-0.6,0-1-0.4-1-1
  s0.4-1,1-1s1,0.4,1,1S12.6,10.9,12,10.9z M13,18.2l0-5.4c1.2-0.4,2-1.5,2-2.8c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,1.3,0.8,2.4,2,2.8l0,5.4
  l-5.6-7.7l2.4-6.6h8.6l2.4,6.6L13,18.2z"
    />
  </svg>
</template>
