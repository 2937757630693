import cookies from 'js-cookie'

export default function useTracking() {
  const { $config, $matomo } = useNuxtApp()

  const { cookieConsentData } = useCookieConsent()

  const envIsTracked: boolean = $config.public.mode === 'production'

  const { id } = storeToRefs(useUserStore())
  const userIsLoggedIn = computed(() => id.value !== '')

  const { settings } = storeToRefs(useBusinessStore())
  const userIsInUserpilotBlocklist = computed(() => settings.value?.userPilot?.enabled === false)

  // Only enable global tracking logic if environment is tracked, user is logged in and is not part of the blocklist
  const trackingEnabled = computed(() => envIsTracked && userIsLoggedIn.value && !userIsInUserpilotBlocklist.value)

  // Dynamically show/hide cookie banner depending on global tracking settings
  const setCookieBannerVisibility = (): void => {
    const cookieBannerElement: HTMLElement | null = document.querySelector('#CybotCookiebotDialog')
    if (cookieBannerElement) {
      if (trackingEnabled.value) cookieBannerElement.style.display = 'flex'
      else cookieBannerElement.style.display = 'none'
    }
  }

  // Show/hide cookie banner on first cookiebot load
  window.addEventListener('CookiebotOnDialogDisplay', () => {
    setCookieBannerVisibility()
  })

  // Show/hide cookie banner on each change to global tracking settings
  watch(trackingEnabled, () => {
    setCookieBannerVisibility()
  })

  const userConsentedToTracking = computed((): boolean => {
    const userRespondedToCookieBanner = cookieConsentData.value?.hasResponse ?? false
    const userconsentedToStatistics = cookieConsentData.value?.consent.statistics ?? false

    return userRespondedToCookieBanner && userconsentedToStatistics
  })

  // Watch cookie consent data to enable/disable matomo consent
  watch(cookieConsentData, () => {
    $matomo?.setConsent(userConsentedToTracking.value)
  })

  const loadScriptToHTML = (scriptSource: string, options?: { pushMatomoCall: boolean }) => {
    // Based on Google Tag's script to load external js scripts. Originally used by Matomo
    if (options?.pushMatomoCall) {
      const _mtm = (window._mtm = window._mtm || [])
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
    }
    const d = document
    const g = d.createElement('script')
    const s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.defer = true
    g.src = scriptSource
    s.parentNode?.insertBefore(g, s)
  }

  const loadWelcomeTrackers = (): void => {
    // Matomo Tag Manager
    // This script is not the general matomo tracker. It is an additional event to track conversions on the /welcome page
    if ($matomo) {
      loadScriptToHTML('https://analytics.skribble.com/js/container_CFEkdfSo.js', { pushMatomoCall: true })
    }

    // Hubspot Script Loader, only on user's welcome page
    // Update the csp-policy in the header when file js.hs-scripts.com/5051692.js changes
    loadScriptToHTML('https://js.hs-scripts.com/5051692.js')
  }

  const { hubspotRepository } = useApi()

  const dispatchHubspotSignupTracker = (email: string) => {
    const hubspotTrackingCode = cookies.get('hubspotutk')

    const hubspotData = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
      ],
      context: {
        hutk: hubspotTrackingCode,
        pageUri: 'my.skribble.com/business/signup/',
        pageName: 'Business Signup Start Page',
      },
      legalConsentOptions: {
        legitimateInterest: {
          value: true,
          subscriptionTypeId: 5692350,
          legalBasis: 'LEAD',
          text: 'Legitimate interest by signing up to Skribble',
        },
      },
    }

    void hubspotRepository.sendData($config.public.hubspotPortalId, '24b2cb64-7486-4bc8-8751-6c876c34b191', hubspotData)
  }

  return {
    dispatchHubspotSignupTracker,
    loadWelcomeTrackers,
  }
}
