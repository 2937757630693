const statusMap: Record<IdentAppointmentStatus, IdentificationStatus> = {
  initialized: 'initialized',
  unknown: 'initialized',
  inprogress: 'inprogress',
  success: 'activatingQES',
  failure: 'failure',
}

export const useIdentStore = defineStore('ident', {
  state: () => ({
    eidasVideoIdentStatus: null as IdentificationStatus | null,
    eidasVideoIdentURL: '',
    eidasSelfIdentStatus: null as IdentificationStatus | null,
    eidasSelfIdentURL: '',
    zertesVideoIdentStatus: null as IdentificationStatus | null,
    zertesVideoIdentURL: '',
    zertesAutoIdentStatus: null as IdentificationStatus | null,
    zertesAutoIdentURL: '',
    identInProgress: false,
  }),
  getters: {
    eidasOngoingVideoIdent(): boolean {
      return this.eidasVideoIdentStatus !== null && useUserStore().highestSignatureQuality !== 'qes'
    },
    eidasOngoingSelfIdent(): boolean {
      return this.eidasSelfIdentStatus !== null && useUserStore().highestSignatureQuality !== 'qes'
    },
    eidasOngoingIdent(): boolean {
      return this.eidasOngoingVideoIdent || this.eidasOngoingSelfIdent
    },
    zertesOngoingVideoIdent(): boolean {
      return this.zertesVideoIdentStatus !== null && useUserStore().highestSignatureQuality !== 'qes'
    },
    zertesOngoingAutoIdent(): boolean {
      return this.zertesAutoIdentStatus !== null && useUserStore().highestSignatureQuality !== 'qes'
    },
    zertesOngoingIdent(): boolean {
      return this.zertesOngoingVideoIdent || this.zertesOngoingAutoIdent
    },
  },
  actions: {
    setIdentUrlStatus(
      info: IdentInfo,
      type: 'eidasVideoIdent' | 'eidasSelfIdent' | 'zertesVideoIdent' | 'zertesAutoIdent'
    ) {
      const status = statusMap[info.appointmentStatus] || null
      this[`${type}Status`] = status
      this[`${type}URL`] = info.identUrl
    },
    async doIdentIfNotInProgress(identFn: () => Promise<void>) {
      if (this.identInProgress) return
      this.identInProgress = true
      await identFn()
      this.identInProgress = false
    },
    async openStatusPage() {
      const { isDirectSign, directSignSession } = useDirectSignStore()
      if (isDirectSign) {
        await navigateTo({
          name: 'direct-sign-identification-id-userid',
          params: {
            id: directSignSession!.auth.password,
            userid: directSignSession!.auth.username,
          },
          query: {
            primaryAction: 'refresh',
          },
        })
      } else {
        await navigateTo({ name: 'profile-signature-standards' })
      }
    },
    async eidasGetAllIdentInfo() {
      await Promise.all([this.eidasGetVideoIdentInfo(), this.eidasGetSelfIdentInfo()])
    },
    async eidasGenerateVideoIdent(srid?: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      await this.doIdentIfNotInProgress(async () => {
        const { identUrl } = useDirectSignStore().isDirectSign
          ? await directIdentRepo.createEidasVideoIdent(srid)
          : await identRepo.createEidasVideoIdent(srid)
        this.eidasVideoIdentURL = identUrl
      })
    },
    async eidasGetVideoIdentInfo() {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      const info = useDirectSignStore().isDirectSign
        ? await directIdentRepo.getEidasVideoIdentInfo()
        : await identRepo.getEidasVideoIdentInfo()

      this.setIdentUrlStatus(info, 'eidasVideoIdent')
      return { appointmentStatus: info.appointmentStatus, identUrl: info.identUrl }
    },
    async eidasDeleteVideoIdent(email: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      useDirectSignStore().isDirectSign
        ? await directIdentRepo.deleteEidasVideoIdent(email)
        : await identRepo.deleteEidasVideoIdent(email)
    },
    async eidasGenerateSelfIdent(srid?: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      await this.doIdentIfNotInProgress(async () => {
        const { identUrl } = useDirectSignStore().isDirectSign
          ? await directIdentRepo.createEidasSelfIdent(srid)
          : await identRepo.createEidasSelfIdent(srid)
        this.eidasSelfIdentURL = identUrl
      })
    },
    async eidasGetSelfIdentInfo() {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      const info = useDirectSignStore().isDirectSign
        ? await directIdentRepo.getEidasSelfIdentInfo()
        : await identRepo.getEidasSelfIdentInfo()

      this.setIdentUrlStatus(info, 'eidasSelfIdent')
      return { appointmentStatus: info.appointmentStatus, identUrl: info.identUrl }
    },
    async eidasDeleteSelfIdent(email: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      useDirectSignStore().isDirectSign
        ? await directIdentRepo.deleteEidasSelfIdent(email)
        : await identRepo.deleteEidasSelfIdent(email)
    },
    async checkIsIdentificationCovered(data: { srId?: string; legislation?: Legislation }) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      const { password, isDirectSign } = useDirectSignStore()
      const { isCovered } = isDirectSign
        ? await directIdentRepo.getCoverageInfo(data.srId ?? password)
        : await identRepo.getCoverageInfo(data.srId, data.legislation)

      return isCovered
    },
    async zertesGetAllIdentInfo() {
      await Promise.all([this.zertesGetVideoIdentInfo(), this.zertesGetAutoIdentInfo()])
    },
    async zertesGenerateVideoIdent(srid?: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      await this.doIdentIfNotInProgress(async () => {
        const { identUrl } = useDirectSignStore().isDirectSign
          ? await directIdentRepo.createZertesVideoIdent(srid)
          : await identRepo.createZertesVideoIdent(srid)
        this.zertesVideoIdentURL = identUrl
      })
    },
    async zertesGetVideoIdentInfo() {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      const info = useDirectSignStore().isDirectSign
        ? await directIdentRepo.getZertesVideoIdentInfo()
        : await identRepo.getZertesVideoIdentInfo()

      this.setIdentUrlStatus(info, 'zertesVideoIdent')
      return { appointmentStatus: info.appointmentStatus, identUrl: info.identUrl }
    },
    async zertesDeleteVideoIdent(email: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      useDirectSignStore().isDirectSign
        ? await directIdentRepo.deleteZertesVideoIdent(email)
        : await identRepo.deleteZertesVideoIdent(email)
    },
    async zertesGenerateAutoIdent(srid?: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      await this.doIdentIfNotInProgress(async () => {
        const { identUrl } = useDirectSignStore().isDirectSign
          ? await directIdentRepo.createZertesSelfIdent(srid)
          : await identRepo.createZertesSelfIdent(srid)
        this.zertesAutoIdentURL = identUrl
      })
    },
    async zertesGetAutoIdentInfo() {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      const info = useDirectSignStore().isDirectSign
        ? await directIdentRepo.getZertesSelfIdentInfo()
        : await identRepo.getZertesSelfIdentInfo()

      this.setIdentUrlStatus(info, 'zertesAutoIdent')
      return { appointmentStatus: info.appointmentStatus, identUrl: info.identUrl }
    },
    async zertesDeleteAutoIdent(email: string) {
      const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

      useDirectSignStore().isDirectSign
        ? await directIdentRepo.deleteZertesSelfIdent(email)
        : await identRepo.deleteZertesSelfIdent(email)
    },
  },
})
