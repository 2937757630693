import { destr } from 'destr'

const paymentRepository = (fetch: CustomFetch) => ({
  async getPricePlans() {
    return fetch<Omit<PricePlan, 'name'>[]>(`/v2/businesses/price_plans`, { parseResponse: destr })
  },
  async getStripeSetupIntent(businessId: string, country?: string) {
    return fetch<GetStripeSetupIntentResponse>(`/v2/businesses/${businessId}/subscriptions/stripe_setup_intent`, {
      method: 'POST',
      body: { country },
      parseResponse: destr,
    })
  },
  async getDirectStripeSetupIntent(country?: string) {
    return fetch<GetStripeSetupIntentResponse>(`/v2/businesses/direct_upgrade/stripe_setup_intent`, {
      method: 'POST',
      body: { country },
      parseResponse: destr,
    })
  },
  async upgradeSubscription(businessId: string, subscriptionPayload: UpgradeSubscriptionPayload) {
    return fetch<PricePlanInfo>(`/v2/businesses/${businessId}/subscriptions/upgrade`, {
      method: 'PUT',
      body: objectToSnakeCase(subscriptionPayload),
      parseResponse: destr,
    })
  },
  async directUpgradeSubscription(upgradePayload: DirectUpgradeSubscriptionPayload) {
    return fetch<PricePlanInfo>(`/v2/businesses/direct_upgrade/confirm`, {
      method: 'POST',
      body: objectToSnakeCase(upgradePayload),
      parseResponse: destr,
    })
  },
  async previewUpgradeSubscriptionPrice(
    businessId: string,
    promoCode: string,
    plan: string,
    cycle: string,
    amountSeats: number,
    currency: Currency,
    referralCode: string | undefined
  ) {
    const payload = objectToSnakeCase({
      promoCode,
      plan,
      cycle,
      amountSeats,
      currency,
      referralCode,
    })

    if (!businessId) {
      // If the user is not part of any business yet, use the 'direct_upgrade' endpoint
      return fetch<PreviewUpgradeSubscriptionPriceResponse>(`/v2/businesses/direct_upgrade/preview`, {
        method: 'POST',
        body: payload,
        parseResponse: destr,
      })
    } else {
      return fetch<PreviewUpgradeSubscriptionPriceResponse>(
        `/v2/businesses/${businessId}/subscriptions/upgrade/preview`,
        {
          method: 'POST',
          body: payload,
          parseResponse: destr,
        }
      )
    }
  },
  async previewSeatsUpgradePrice(businessId: string, seats: number) {
    return fetch<PreviewSeatsUpgradePriceResponse>(`/v2/businesses/${businessId}/subscriptions/seats/preview`, {
      method: 'POST',
      body: { seats },
      parseResponse: destr,
    })
  },
  async validatePromoCode(businessId: string, promoCode: string, plan: string, cycle: string, type: string | null) {
    const payload = objectToSnakeCase({ promoCode, plan, cycle, type })

    return fetch<ValidatePromoCodeResponse>(`/v2/businesses/${businessId}/subscriptions/coupons/validate_promo`, {
      method: 'POST',
      body: payload,
      parseResponse: destr,
    })
  },
  async getAppliedReferralCodeIfExists(businessId: string) {
    return fetch<AppliedReferralCode>(`/v2/businesses/${businessId}/subscriptions/coupons/referral_code`)
  },
})

export default paymentRepository
