<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22,7h-5V2c0-0.6-0.4-1-1-1H2C1.4,1,1,1.4,1,2v14c0,0.6,0.4,1,1,1h5v5c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V8
  C23,7.4,22.6,7,22,7z M3,15V3h12v12H3z M21,21H9v-4h7c0.6,0,1-0.4,1-1V9h4V21z"
    />
  </svg>
</template>
