<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M9 17H7C5.7 17 4.4 16.5 3.5 15.5C2.5 14.6 2 13.3 2 12C2 10.7 2.5 9.4 3.5 8.5C4.5 7.6 5.7 7 7 7V9.5C7 9.6 7 9.7 7.1 9.8C7.1 9.9 7.2 9.9 7.3 10C7.4 10 7.5 10.1 7.6 10C7.7 9.9 7.8 10 7.9 9.9L12.4 6.4C12.5 6.4 12.5 6.3 12.5 6.2C12.5 6.1 12.6 6.1 12.6 6C12.6 5.9 12.6 5.8 12.5 5.8C12.5 5.7 12.4 5.7 12.4 5.6L7.8 2.1C7.7 2 7.6 2 7.6 2C7.5 2 7.4 2 7.3 2C7.2 2 7.1 2.1 7.1 2.2C7 2.3 7 2.4 7 2.5V5C5.1 5 3.4 5.7 2.1 7.1C0.8 8.5 0 10.1 0 12C0 13.9 0.7 15.6 2.1 16.9C3.4 18.3 5.1 19 7 19H9C9.3 19 9.5 18.9 9.7 18.7C9.9 18.5 10 18.3 10 18C10 17.7 9.9 17.5 9.7 17.3C9.5 17.1 9.3 17 9 17ZM21.9 7.1C20.6 5.7 18.9 5 17 5H15C14.7 5 14.5 5.1 14.3 5.3C14.1 5.5 14 5.7 14 6C14 6.3 14.1 6.5 14.3 6.7C14.5 6.9 14.7 7 15 7H17C18.3 7 19.6 7.5 20.5 8.5C21.5 9.4 22 10.7 22 12C22 13.3 21.5 14.6 20.5 15.5C19.6 16.5 18.3 17 17 17V14.5C17 14.4 17 14.3 16.9 14.2C16.9 14.1 16.8 14.1 16.7 14C16.6 14 16.5 13.9 16.4 14C16.3 14 16.2 14 16.1 14.1L11.6 17.6C11.5 17.6 11.5 17.7 11.5 17.8C11.5 17.9 11.4 17.9 11.4 18C11.4 18.1 11.4 18.2 11.5 18.2C11.5 18.3 11.6 18.3 11.6 18.4L16.1 21.9C16.2 22 16.3 22 16.4 22C16.5 22 16.6 22 16.7 22C16.8 22 16.9 21.9 16.9 21.8C16.9 21.7 17 21.6 17 21.5V19C18.9 19 20.6 18.3 21.9 16.9C23.2 15.6 24 13.8 24 12C24 10.2 23.3 8.4 21.9 7.1Z"
    />
  </svg>
</template>
