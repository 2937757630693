<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M5,6h14c0.6,0,1-0.4,1-1s-0.4-1-1-1H5C4.4,4,4,4.4,4,5S4.4,6,5,6z M8,2h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H8C7.4,0,7,0.4,7,1
  S7.4,2,8,2z M22,8H2C1.4,8,1,8.4,1,9v14c0,0.6,0.4,1,1,1h20c0.6,0,1-0.4,1-1V9C23,8.4,22.6,8,22,8z M21,22H3V10h18V22z M8,17h8
  c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9v-1c0-0.6-0.4-1-1-1s-1,0.4-1,1v2C7,16.6,7.4,17,8,17z"
    />
  </svg>
</template>
