<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,13.5c0.4,0,0.8-0.4,0.8-0.8V8.4c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v4.4C11.2,13.2,11.6,13.5,12,13.5z M18.5,3.2H5.5C5,3.2,4.7,3.6,4.7,4v16c0,0.4,0.4,0.8,0.8,0.8h13.1c0.4,0,0.8-0.4,0.8-0.8V4C19.3,3.6,19,3.2,18.5,3.2z M17.7,19.2H6.3V4.8h11.5V19.2z M12,16.9c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2S11.3,16.9,12,16.9z"
    />
  </svg>
</template>
