<script setup></script>

<template>
  <svg
    style="transform: rotate(180deg)"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <polygon points="16.1,8 12,12.2 7.9,8 6,9.9 12,16 18,9.9" />
  </svg>
</template>
