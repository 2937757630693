<template>
  <v-container v-if="isIdentificationCovered === null" class="fill-height">
    <v-progress-circular :size="70" color="text" indeterminate />
  </v-container>
  <two-cols-block v-else class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1">{{ t('eu_ident.title_new') }}</skr-heading>
      </div>
      <identification-methods
        :available-methods="availableMethods"
        :show-help-link="!isIdentificationCovered"
        @select="onSelect"
      />
      <skr-button class="mt-8" size="xl" type="secondary" block @click="router.back()">
        {{ t('global.button.go_back') }}
      </skr-button>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image source="bank-video-ident" :width="544" :height="600" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import IdentificationMethods from '@/components/identification/IdentificationMethods.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    ResponsiveImage,
    SkrHeading,
    TwoColsBlock,
    IdentificationMethods,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const router = useRouter()

    const { isIdentificationCovered, availableMethods, redirect, selectMethod } = useIdentAvailability('eidas')

    const onSelect = (method: IdentificationMethod) => {
      if (method === 'external-sc') {
        redirect()
      } else {
        selectMethod(method)
      }
    }

    return { t, router, isIdentificationCovered, availableMethods, onSelect }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Eidas',
    }
  },
})
</script>
