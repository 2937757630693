export default function useErrorHandling() {
  const { $config, $sentry } = useNuxtApp()

  const logError = (info: string, error: unknown, vm?: Record<string, unknown> | null) => {
    if ($config.public.mode === 'staging' || $config.public.mode === 'production') {
      $sentry?.captureException(error, {
        extra: {
          info,
          vm,
        },
      })
    } else {
      console.error(info, error)
    }
  }

  if (getCurrentInstance()) {
    onErrorCaptured((error, vm, info) => {
      logError(info, error, vm as Record<string, unknown> | null)
    })
  }

  return {
    logError,
  }
}
