<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    enable-background="new 0 0 56 56"
    xml:space="preserve"
  >
    <path
      d="M28,18.2c5.1,0,9.2-4.1,9.2-9.1S33.1,0,28,0c-5.1,0-9.2,4.1-9.2,9.1S22.9,18.2,28,18.2z M28,4c2.9,0,5.2,2.3,5.2,5.1s-2.3,5.1-5.2,5.1s-5.2-2.3-5.2-5.1S25.1,4,28,4z M35.2,21.3H20.8c-3.7,0-6.8,3-6.8,6.7v11.8c0,1.1,0.9,2,2,2h2.8V54c0,1.1,0.9,2,2,2h14.4c1.1,0,2-0.9,2-2v-9.8H40c1.1,0,2-0.9,2-2V28C42,24.3,38.9,21.3,35.2,21.3z M38,40.2h-2.8c-1.1,0-2,0.9-2,2V52H22.8V39.8c0-1.1-0.9-2-2-2H18V28c0-1.5,1.3-2.7,2.8-2.7h14.4c1.5,0,2.8,1.2,2.8,2.7V40.2z"
    />
  </svg>
</template>
