<script setup></script>

<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.117 3.7C12.9788 3.7 3.934 12.7801 3.934 24C3.934 35.2199 12.9788 44.3 24.117 44.3C35.2552 44.3 44.2999 35.2199 44.2999 24C44.2999 12.7801 35.2552 3.7 24.117 3.7ZM0.533997 24C0.533997 10.9194 11.0839 0.300003 24.117 0.300003C37.15 0.300003 47.7 10.9194 47.7 24C47.7 37.0806 37.15 47.7 24.117 47.7C11.0839 47.7 0.533997 37.0806 0.533997 24Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1807 24.3C13.1196 24.3 13.8807 25.0611 13.8807 26C13.8807 28.7347 14.9614 31.3555 16.882 33.2864C18.8024 35.217 21.405 36.3 24.1169 36.3C26.8288 36.3 29.4314 35.217 31.3518 33.2864C33.2724 31.3555 34.3531 28.7347 34.3531 26C34.3531 25.0611 35.1142 24.3 36.0531 24.3C36.9919 24.3 37.7531 25.0611 37.7531 26C37.7531 29.6306 36.3186 33.1142 33.7623 35.6842C31.2057 38.2544 27.7364 39.7 24.1169 39.7C20.4974 39.7 17.028 38.2544 14.4715 35.6842C11.9152 33.1142 10.4807 29.6306 10.4807 26C10.4807 25.0611 11.2418 24.3 12.1807 24.3Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.0557 13.9727C29.12 12.9028 30.5654 12.3 32.0744 12.3C33.5834 12.3 35.0288 12.9028 36.0931 13.9727C37.157 15.0423 37.7531 16.4912 37.7531 18C37.7531 18.9389 36.992 19.7 36.0531 19.7C35.1143 19.7 34.3531 18.9389 34.3531 18C34.3531 17.3871 34.1109 16.8011 33.6825 16.3704C33.2545 15.9401 32.6758 15.7 32.0744 15.7C31.473 15.7 30.8944 15.9401 30.4663 16.3704C30.038 16.8011 29.7957 17.3871 29.7957 18C29.7957 18.9389 29.0346 19.7 28.0957 19.7C27.1568 19.7 26.3957 18.9389 26.3957 18C26.3957 16.4912 26.9918 15.0423 28.0557 13.9727Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1408 13.9727C13.205 12.9028 14.6504 12.3 16.1594 12.3C17.6685 12.3 19.1138 12.9028 20.1781 13.9727C21.2421 15.0423 21.8382 16.4912 21.8382 18C21.8382 18.9389 21.077 19.7 20.1382 19.7C19.1993 19.7 18.4382 18.9389 18.4382 18C18.4382 17.3871 18.1959 16.8011 17.7675 16.3704C17.3395 15.9401 16.7609 15.7 16.1594 15.7C15.558 15.7 14.9794 15.9401 14.5513 16.3704C14.123 16.8011 13.8807 17.3871 13.8807 18C13.8807 18.9389 13.1196 19.7 12.1807 19.7C11.2418 19.7 10.4807 18.9389 10.4807 18C10.4807 16.4912 11.0768 15.0424 12.1408 13.9727Z"
      fill="currentColor"
    />
  </svg>
</template>
