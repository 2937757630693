<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.8,16.8h-2v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h2
  c0.6,0,1-0.4,1-1S21.3,16.8,20.8,16.8z M21,0H3C1.3,0,0,1.3,0,3v13c0,1.7,1.3,3,3,3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H3
  c-0.6,0-1-0.4-1-1v-5h21c0.6,0,1-0.4,1-1V3C24,1.3,22.7,0,21,0z M22,9H2V6h20V9z M22,4H2V3c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1V4z"
    />
  </svg>
</template>
