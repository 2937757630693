<template>
  <two-cols-block class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1" semantic>{{ t('ch_ident.auto.heading.title') }}</skr-heading>
      </div>
      <p class="mb-10">{{ t('ch_ident.auto.requirements.intro') }}</p>
      <ul class="mb-10">
        <li>{{ t('ch_ident.auto.requirements.item_1') }}</li>
        <li>{{ t('ch_ident.auto.requirements.item_2') }}</li>
        <li>{{ t('ch_ident.auto.requirements.item_3') }}</li>
      </ul>
      <p class="mb-10 font-weight-bold">
        {{ t('ch_ident.auto.duration_text') }}
      </p>
      <skr-button-group size="xl" stacked>
        <skr-button
          data-cy="start_button"
          :loading="isLoading"
          :disabled="!identUrl"
          :href="identUrl"
          @click="openStatusPage"
          >{{ t('ch_ident.auto.start') }}</skr-button
        >
        <skr-button data-cy="cancel_button" type="secondary" :disabled="isLoading" @click="onCancel">{{
          t('unlock_qes.eu.video_ident.start.back')
        }}</skr-button>
      </skr-button-group>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image source="self-ident" :width="723" :height="697" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrButtonGroup,
    SkrHeading,
    TwoColsBlock,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const { email } = storeToRefs(useUserStore())
    const identStore = useIdentStore()
    const { zertesDeleteAutoIdent, zertesGenerateAutoIdent, openStatusPage } = identStore
    const { zertesAutoIdentURL, identInProgress } = storeToRefs(identStore)
    const { srId } = useIdentAvailability('zertes')

    const onCancel = async () => {
      if (zertesAutoIdentURL.value) await zertesDeleteAutoIdent(email.value)
      useRouter().back()
    }

    onBeforeMount(() => {
      void zertesGenerateAutoIdent(srId.value || undefined)
    })

    return {
      t,
      isLoading: identInProgress,
      identUrl: zertesAutoIdentURL,
      openStatusPage,
      onCancel,
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Zertes - Auto Ident',
    }
  },
})
</script>
