export default defineNuxtRouteMiddleware(async (to, from) => {
  const directSignStore = useDirectSignStore()
  const languageStore = useLanguageStore()
  const userStore = useUserStore()

  // If current route has no query parameter, check the previous route
  let urlLang = languageStore.validateLanguageCode(to.query.lang ?? from.query.lang)
  // Italian is only used on direct sign pages. Override italian for all other pages
  if (urlLang === 'it' && !directSignStore.isDirectSign) urlLang = null

  // Only use language setting from user store in case the user is logged in
  const userLang = languageStore.validateLanguageCode(userStore.email ? languageStore.language : undefined)

  const browserLang = languageStore.validateLanguageCode(navigator.language?.split('-')[0])

  /* if a user isn't direct sign, they will have a preference for language in
     their settings that should always take precedence. direct signers get
     a default language from the inviter but the inviter can override this
     by adding the lang=<CODE> query, which should always take precedence. */
  const priorityLang = directSignStore.isDirectSign ? (urlLang ?? userLang) : (userLang ?? urlLang)

  const languageCode = (priorityLang ?? browserLang ?? 'en') as Language

  await languageStore.setLanguage(languageCode)
})
