const tanRepository = (fetch: CustomFetch) => ({
  async resend(subject: string, url: string, branding?: CompanyBranding) {
    const payload = {
      url,
      subject,
      companyBranding: branding,
    }

    await fetch('/v1/direct/tan/resend', {
      method: 'POST',
      body: objectToSnakeCase(payload),
    })
  },
  async verify(tan: string) {
    return fetch<CheckTanResponse>('/v1/direct/tan/check', {
      method: 'POST',
      body: { tan },
    })
  },
})

export default tanRepository
