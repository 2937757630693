<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17.7,6.7L12,12.4L6.3,6.7c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.4,6.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6.4-6.4
  c0.4-0.4,0.4-1,0-1.4S18,6.4,17.7,6.7z M20.2,2.8H3.8C2.3,2.8,1,4.1,1,5.6v12.7c0,1.6,1.3,2.8,2.8,2.8h16.4c1.6,0,2.8-1.3,2.8-2.8
  V5.6C23,4.1,21.7,2.8,20.2,2.8z M21,18.4c0,0.5-0.4,0.8-0.8,0.8H3.8c-0.5,0-0.8-0.4-0.8-0.8V5.6c0-0.5,0.4-0.8,0.8-0.8h16.4
  c0.5,0,0.8,0.4,0.8,0.8V18.4z M6.7,14l-1.8,1.8c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.8-1.8
  c0.4-0.4,0.4-1,0-1.4S7.1,13.6,6.7,14z M17.3,14c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l1.8,1.8c0.2,0.2,0.5,0.3,0.7,0.3
  s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L17.3,14z"
    />
  </svg>
</template>
