<script setup></script>

<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M19 13V8A7 7 0 1 0 5 8v5a13.83 13.83 0 0 1-2 7h18a13.83 13.83 0 0 1-2-7Z"
    />
    <path fill="currentColor" d="M9.17 22a3 3 0 0 0 5.66 0H9.17Z" />
  </svg>
</template>
