/*
 * protects billing routes
 */

export default defineNuxtRouteMiddleware(to => {
  const businessStore = useBusinessStore()
  const userStore = useUserStore()

  // routes available only for NON biz members
  const routesForNonBusinessMembersOnly = ['billing-settings', 'billing-usage']

  if (businessStore.isManuallyInvoiced && to.name === 'business-billing') {
    return navigateTo('/business/billing/invoices')
  }

  // prevent user who belongs to a business of opening these pages
  if (to.name && routesForNonBusinessMembersOnly.includes(to.name) && userStore.isBusinessMember)
    return navigateTo('/billing')

  if (to.path === '/business/billing') {
    const { isLegacyPlan } = useUserPlanInfo()

    if (businessStore.isManuallyInvoiced || isLegacyPlan) {
      navigateTo('/business/activity?tab=signatures')
    } else {
      navigateTo('/business/billing/plan')
    }
  }
})
