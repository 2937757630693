<script setup></script>

<template>
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 1.5H1.5V10.5H10.5V1.5Z" fill="#F25022" />
    <path d="M10.5 11.5H1.5V20.5H10.5V11.5Z" fill="#00A4EF" />
    <path d="M20.5 1.5H11.5V10.5H20.5V1.5Z" fill="#7FBA00" />
    <path d="M20.5 11.5H11.5V20.5H20.5V11.5Z" fill="#FFB900" />
  </svg>
</template>
