<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M15.5,1.5h-7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c3.3,0,6,2.7,6,6s-2.7,6-6,6H3.9l3.3-3.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5,5c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.5,0,0.8c0.1,0.1,0.1,0.2,0.2,0.3l5,5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-3.3-3.3h11.6c4.4,0,8-3.6,8-8S19.9,1.5,15.5,1.5z"
    />
  </svg>
</template>
