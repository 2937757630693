<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12.1,7.5C12.1,7.5,12.1,7.5,12.1,7.5l2.2,2.2c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3l-0.6-0.6h2.7
  v3.5c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V6.9C19.4,6.4,19,6,18.5,6h-3.6l0.6-0.6c0.4-0.4,0.4-0.9,0-1.3s-0.9-0.4-1.3,0l-2.2,2.2
  c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.1,0.5,0,0.7C11.9,7.4,12,7.4,12.1,7.5z M17.1,13.3c-2.1,0-3.8,1.7-3.8,3.8
  c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C20.9,15,19.2,13.3,17.1,13.3z M17.1,19.1c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2
  S18.2,19.1,17.1,19.1z M11.9,16.5C11.9,16.5,11.9,16.5,11.9,16.5l-2.2-2.2c-0.4-0.4-0.9-0.4-1.3,0s-0.4,0.9,0,1.3l0.6,0.6H6.4v-3.5
  c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v4.4C4.6,17.6,5,18,5.5,18h3.6l-0.6,0.6c-0.4,0.4-0.4,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3
  s0.5-0.1,0.6-0.3l2.2-2.2c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.5,0-0.7C12.1,16.6,12,16.6,11.9,16.5z M10.7,6.9
  c0-2.1-1.7-3.8-3.8-3.8S3.1,4.8,3.1,6.9s1.7,3.8,3.8,3.8S10.7,9,10.7,6.9z M4.9,6.9c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2
  S4.9,8,4.9,6.9z"
    />
  </svg>
</template>
