// TODO: Rename to something more fitting

// TODO: Merge business middleware into this one

export default defineNuxtRouteMiddleware(to => {
  const directSignStore = useDirectSignStore()

  // NAS will never be locked out of anything
  if (directSignStore.isDirectSign) return

  const businessStore = useBusinessStore()
  const userStore = useUserStore()

  const {
    $i18n: { t },
  } = useNuxtApp()

  if (userStore.attributes.weak_password?.[0] === 'true' && to.name !== 'profile-change-password') {
    return navigateTo({ name: 'profile-change-password' })
  }

  const { isBusinessMember, isBusinessAdmin } = storeToRefs(userStore)
  const { createSnackbar, setDialog } = useWidgetStore()

  if (businessStore.id) {
    if (!userStore.attributes.accepted_business_invite?.[0]) {
      setDialog('business/DialogBusinessInvitation', { businessName: businessStore.name }, async () => {
        await userStore.accept('biz')
        await userStore.fetchUser()
      })
      return
    }
    if (
      (businessStore.isTrialExpired || businessStore.isLocked) &&
      isBusinessAdmin.value &&
      !['business-members', 'business-upgrade', 'logout'].includes((to.name as string) ?? '')
    ) {
      return navigateTo('/business/members')
    } else if (
      (businessStore.isTrialExpired || businessStore.isLocked) &&
      isBusinessMember.value &&
      !isBusinessAdmin.value
    ) {
      setDialog('business/DialogBusinessLockedMember', {})
    }
  } else if (
    userStore.attributes.removed_from_business?.[0] === 'true' ||
    userStore.attributes.business_deleted?.[0] === 'true'
  ) {
    const { userRepository } = useApi()

    setDialog(
      'business/DialogBusinessDowngraded',
      { isAdmin: userStore.attributes.business_deleted?.[0] === 'true' },
      async () => {
        try {
          await userStore.accept('gtc')
          await userRepository.updateAttributes({
            removed_from_business: 'false',
            business_deleted: 'false',
          })
          await userStore.fetchUser()
          await navigateTo({ name: 'billing' })
        } catch {
          createSnackbar({
            message: t('global.error.generic_support'),
          })
        }
      }
    )
  } else if (
    !userStore.attributes.accepted_gtc?.[0] &&
    userStore.attributes.business_deleted?.[0] !== 'true' &&
    !userStore.isSsoSetupIncomplete
  ) {
    setDialog('DialogAcceptTerms', {}, async () => {
      await userStore.accept('gtc')
      await userStore.fetchUser()
    })
  }
})
