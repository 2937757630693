<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path d="m16.73 17.84-5.84-5.84 5.84-5.84 1.84 1.84-4 4 4 4z" />
    <path d="m5.43 4.7h2.6v14.6h-2.6z" />
  </svg>
</template>
