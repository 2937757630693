import type { SetupIntent } from '@stripe/stripe-js'

export interface BusinessBilling {
  next_invoice: {
    amount_due: number
    currency: string
    period_end: number
  }
  included_signatures: {
    remaining: number
    total: number
  }
  state: string
  cancel_at_period_end: boolean
  price_plan: {
    billing_cycle: string
  }
  tax_exempt: string
}

interface PaymentCard {
  brand: string
  checks: {
    address_line1_check: string
    address_postal_code_check: string
    cvc_check: string
  }
  country: string
  default_payment_method?: boolean
  exp_month: number
  exp_year: number
  fingerprint: string
  funding: string
  generated_from: null | string
  last4: number
  networks: {
    available: string[]
    preferred: string | null
  }
  three_d_secure_usage: {
    supported: boolean
  }
  wallet: string | null
}

interface PaymentSepa {
  default_payment_method?: boolean
  last4: string
}

interface PaymentMethodCard {
  type: 'card'
  paymentInformation: Camelized<PaymentCard>
}

interface PaymentMethodSepa {
  type: 'sepa'
  paymentInformation: Camelized<PaymentSepa>
}

export type DefaultPaymentMethod = PaymentMethodCard | PaymentMethodSepa

interface Payment {
  cards: Camelized<PaymentCard>[]
  sepaDebits: Camelized<PaymentSepa>[]
}

// NB: As the legacy data is expected in snake_case, we need to revert the automatic conversion
const legacyRepository = (fetch: CustomFetch) => ({
  async getBusinessInfo(businessId: string) {
    const response = await fetch<Partial<BusinessBilling>>(`/v1/businesses/${businessId}/billing/information`)

    return objectToSnakeCase(response)
  },
  async cancelBusinessSubscription(businessId: string) {
    await fetch(`/v1/businesses/${businessId}/cancel-subscription`, {
      method: 'POST',
    })
  },
  async reactivateBusinessSubscription(businessId: string) {
    await fetch(`/v1/businesses/${businessId}/reactivate-subscription`, {
      method: 'POST',
    })
  },
  /**
   * In the response there are two arrays of payment methods:
   * 'cards' and 'sepa_debits'. There is only one method flagged as
   * default_payment_method. This function also stores this payment method
   * to the state as: "defaultMethod".
   */
  async getPaymentMethods(businessId: string) {
    const response = await fetch<Payment>(`/v1/businesses/${businessId}/billing/payment`)
    let defaultMethod: DefaultPaymentMethod | null = null
    for (const paymentInformation of response.cards) {
      if (paymentInformation.defaultPaymentMethod === true) {
        defaultMethod = {
          type: 'card',
          paymentInformation,
        }
      }
    }
    if (!defaultMethod) {
      for (const paymentInformation of response.sepaDebits) {
        if (paymentInformation.defaultPaymentMethod === true) {
          defaultMethod = {
            type: 'sepa',
            paymentInformation,
          }
        }
      }
    }

    return defaultMethod ? objectToSnakeCase(defaultMethod) : null
  },
  async getSetupIntent(businessId: string, country: string) {
    const { setupIntent } = (await fetch(`/v1/businesses/${businessId}/billing/setup-intent`, {
      method: 'POST',
      body: { country },
    })) as { setupIntent: Camelized<SetupIntent> }

    return objectToSnakeCase(setupIntent)
  },
  async confirmSetupIntent(businessId: string, intentId: string, country: string) {
    await fetch(`/v1/businesses/${businessId}/billing/setup-intent/${intentId}`, {
      method: 'POST',
      body: { country },
    })
  },
})

export default legacyRepository
