/**
 * Cello is a third-party service used for generating and sending referral codes for signing up to the platform.
 * This plugin is used to load the Cello widget script and boot the widget with the user's data and chosen language.
 */

export default defineNuxtPlugin(() => {
  const { $config } = useNuxtApp()
  const { userRepository } = useApi()
  const { language } = storeToRefs(useLanguageStore())
  const { logError } = useErrorHandling()
  const userStore = useUserStore()
  const directSignStore = useDirectSignStore()

  const loadScript = (): Promise<void> => {
    const scriptAlreadyExists = document.querySelector(`script[src="${$config.public.cello.widgetScript}"]`)

    if (scriptAlreadyExists) {
      return Promise.resolve()
    }

    const script = document.createElement('script')
    script.src = $config.public.cello.widgetScript
    script.type = 'module'
    script.async = true

    return new Promise((resolve, reject) => {
      const firstScript = document.getElementsByTagName('script')[0]
      if (!firstScript?.parentNode) {
        reject(new Error('No parent node found for script insertion'))
        return
      }

      script.onload = () => {
        resolve()
      }
      script.onerror = () => reject(new Error('Failed to load script'))

      firstScript.parentNode.insertBefore(script, firstScript)
    })
  }

  const boot = async () => {
    const resp = await userRepository.retrieveCelloToken()

    return new Promise<void>((resolve, reject) => {
      window.cello?.cmd.push(async function (cello) {
        try {
          await cello.boot({
            productId: $config.public.cello.productId,
            token: resp.token,
            language: language.value,
            productUserDetails: {
              firstName: userStore.firstName,
              lastName: userStore.lastName,
              fullName: `${userStore.firstName} ${userStore.lastName}`.trim(),
              email: userStore.email,
            },
          })
          resolve()
        } catch (error) {
          reject(error)
        }
      })
    })
  }

  const initialiseCello = async () => {
    try {
      await loadScript()
      await boot()
    } catch (error) {
      logError('Cello initialisation failed', error)
    }
  }

  watchOnce(
    () => userStore.id,
    userId => {
      if (userId && !directSignStore.isDirectSign) {
        void initialiseCello()
      }
    }
  )

  watch(
    () => language.value,
    () => {
      const scriptAlreadyExists = document.querySelector(`script[src="${$config.public.cello.widgetScript}"]`)
      if (scriptAlreadyExists) {
        window.cello?.cmd.push(async function (cello) {
          cello.changeLanguage(language.value)
        })
      }
    }
  )
})
