<script setup></script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9 17H18v-2h-2.1c-.1-.4-.2-.7-.4-1l1.5-1.5-1.5-1.5-1.5 1.6c-.3-.2-.7-.3-1-.4V10h-2v2.1c-.4.1-.7.2-1 .4L8.5 11 7 12.5 8.6 14c-.2.3-.3.7-.4 1H6v2h2.1c.1.4.2.7.4 1L7 19.5 8.5 21l1.5-1.5c.3.2.7.3 1 .4V22h2v-2.1c.4-.1.7-.2 1-.4l1.5 1.5 1.4-1.4-1.5-1.6c.2-.3.4-.6.5-1ZM12 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm.5-13-3-4H0v19c0 .8.3 1.6.9 2.1.6.5 1.3.9 2.1.9h4v-2H3c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7V3h6.5l3 4H22v13c0 .3-.1.5-.3.7-.2.2-.4.3-.7.3h-4v2h4c.8 0 1.6-.3 2.1-.9.5-.6.9-1.3.9-2.1V5H12.5Z"
      fill="currentColor"
    />
  </svg>
</template>
