<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    enable-background="new 0 0 44 44"
    xml:space="preserve"
  >
    <path
      d="M22,0C9.8,0,0,9.8,0,22c0,12.2,9.8,22,22,22s22-9.8,22-22C44,9.8,34.2,0,22,0z M29.7,23.8h-5.9v5.9c0,1-0.8,1.8-1.8,1.8
  s-1.8-0.8-1.8-1.8v-5.9h-5.9c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h5.9v-5.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8v5.9h5.9
  c1,0,1.8,0.8,1.8,1.8S30.7,23.8,29.7,23.8z"
    />
  </svg>
</template>
