/*
 * Warns user in case of a cookie blocker being used.
 *
 * Cookie blockers also block localStorage and sessionStorage.
 * This prevents any auth actions, and therefore renders the platform unusable
 */

export default defineNuxtRouteMiddleware(to => {
  const cookiesAreEnabled = navigator.cookieEnabled
  const userInCookiesBlockedRoute = to.name === 'cookiesblocked'

  if (cookiesAreEnabled && userInCookiesBlockedRoute) {
    return navigateTo('/')
  } else if (!cookiesAreEnabled && !userInCookiesBlockedRoute) {
    return navigateTo('/cookiesblocked')
  }
})
