import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_N_mdyAC06gZUGj6PAMZ9tWqMBkPQ4AmUaEixO15htDg from "/opt/build/repo/node_modules/@kgierke/nuxt-matomo/dist/runtime/plugin.js";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import _0_error_handling_XdHdW5snhf4pgPVQgt4HX2s1T1sfGlQHdE3yFDJ4Cbw from "/opt/build/repo/src/plugins/0.error-handling.ts";
import analytics_M_uwSskwPh7vNwYj_BE2k7BH7YCyT5CMzCIIotCkN_4 from "/opt/build/repo/src/plugins/analytics.ts";
import auth_KUdagv3oyvSBS3mO749eP0dD8u9oBnRf_V2qaChjLVc from "/opt/build/repo/src/plugins/auth.ts";
import http_CweZ94EHS6Xl_ply357IRISLGS5hlp0NFDPwKBxM0X4 from "/opt/build/repo/src/plugins/http.ts";
import i18n_iso_countries_UQAGLoUmepm8ID76Pbw8Bh0Q0jRQ7uvmghWQW0Rp5yw from "/opt/build/repo/src/plugins/i18n-iso-countries.ts";
import i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og from "/opt/build/repo/src/plugins/i18n.ts";
import sentry__dL__xYpF2Fkv524f9GL2snsEqbEoTBS8vypTeIHH0g from "/opt/build/repo/src/plugins/sentry.ts";
import userpilot_USZCOxx8Qrgygsqqjt74mp8sahUoTeKsaR1FXWK7btY from "/opt/build/repo/src/plugins/userpilot.ts";
import verification_Q0rwljD1XZ3BtN4UPwS2TX2OywtQvJD7A57J5zsVUv0 from "/opt/build/repo/src/plugins/verification.ts";
import vuetify_A4vj6Blz0rziNPFx8IAs4cm4oy_tLrlU0fESn0FMtnw from "/opt/build/repo/src/plugins/vuetify.ts";
import webp_4mMrt13ThkV_MhybHw2z47WZ2EfMXy4oZ1U8vrj4XY4 from "/opt/build/repo/src/plugins/webp.ts";
import widget_cello_attribution_PYcwbbCozBmOTBCrPOxDVd9h0l5AGo0qR8f7JBWfye8 from "/opt/build/repo/src/plugins/widget-cello-attribution.ts";
import widget_cello_SmOpmdilHPNrMP7QvPW4q14TbKfgDxDk_tVjRXG8Hhk from "/opt/build/repo/src/plugins/widget-cello.ts";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/opt/build/repo/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_N_mdyAC06gZUGj6PAMZ9tWqMBkPQ4AmUaEixO15htDg,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  _0_error_handling_XdHdW5snhf4pgPVQgt4HX2s1T1sfGlQHdE3yFDJ4Cbw,
  analytics_M_uwSskwPh7vNwYj_BE2k7BH7YCyT5CMzCIIotCkN_4,
  auth_KUdagv3oyvSBS3mO749eP0dD8u9oBnRf_V2qaChjLVc,
  http_CweZ94EHS6Xl_ply357IRISLGS5hlp0NFDPwKBxM0X4,
  i18n_iso_countries_UQAGLoUmepm8ID76Pbw8Bh0Q0jRQ7uvmghWQW0Rp5yw,
  i18n_OkFQ9LTcb1ALD8at3v1fSiJbXuv91qvs85z9kOsJ3og,
  sentry__dL__xYpF2Fkv524f9GL2snsEqbEoTBS8vypTeIHH0g,
  userpilot_USZCOxx8Qrgygsqqjt74mp8sahUoTeKsaR1FXWK7btY,
  verification_Q0rwljD1XZ3BtN4UPwS2TX2OywtQvJD7A57J5zsVUv0,
  vuetify_A4vj6Blz0rziNPFx8IAs4cm4oy_tLrlU0fESn0FMtnw,
  webp_4mMrt13ThkV_MhybHw2z47WZ2EfMXy4oZ1U8vrj4XY4,
  widget_cello_attribution_PYcwbbCozBmOTBCrPOxDVd9h0l5AGo0qR8f7JBWfye8,
  widget_cello_SmOpmdilHPNrMP7QvPW4q14TbKfgDxDk_tVjRXG8Hhk,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk
]