<script setup></script>

<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 11.3333V4H11.3333"
      stroke="currentColor"
      stroke-width="3.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.6668 11.3333V4H33.3335"
      stroke="currentColor"
      stroke-width="3.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 33.3334V40.6667H11.3333"
      stroke="currentColor"
      stroke-width="3.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.6668 33.3334V40.6667H33.3335"
      stroke="currentColor"
      stroke-width="3.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.3335 35.1666L28.807 34.0355C28.0107 33.8364 27.3042 33.376 26.8006 32.7278C26.2971 32.0796 26.0255 31.2812 26.0295 30.4605V28.5776C27.6708 27.4869 29.025 26.0167 29.9773 24.2914C30.9296 22.5661 31.452 20.6367 31.5002 18.6666V16.8333C31.5002 14.4021 30.5344 12.0706 28.8153 10.3515C27.0962 8.6324 24.7646 7.66663 22.3335 7.66663C19.9023 7.66663 17.5708 8.6324 15.8517 10.3515C14.1326 12.0706 13.1668 14.4021 13.1668 16.8333V18.6666C13.2154 20.637 13.7383 22.5667 14.6913 24.292C15.6443 26.0173 16.9992 27.4874 18.6412 28.5776V30.4605C18.6451 31.2812 18.3736 32.0796 17.87 32.7278C17.3665 33.376 16.66 33.8364 15.8637 34.0355L11.3335 35.1666"
      stroke="currentColor"
      stroke-width="3.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
