export default function useIdentAvailability(legislation: Legislation) {
  const route = useRoute()

  const { directSignSession } = storeToRefs(useDirectSignStore())

  const { identificationRepository: identRepo, directIdentificationRepository: directIdentRepo } = useApi()

  const srId = computed(() => route.query.sr as string | undefined)

  const { data: isIdentificationCovered } = useLazyAsyncData(
    'isIdentificationCovered',
    async () => {
      if (directSignSession.value) {
        const { isCovered } = await directIdentRepo.getCoverageInfo(directSignSession.value.auth.password)
        return isCovered
      } else {
        const { isCovered } = await identRepo.getCoverageInfo(srId.value, legislation)
        return isCovered
      }
    },
    { default: () => false }
  )

  const availableMethods = computed<IdentificationMethod[]>(() => {
    if (legislation === 'eidas') {
      return isIdentificationCovered.value ? ['video', 'self', 'id-austria'] : ['id-austria', 'external-sc']
    } else if (legislation === 'zertes') {
      return isIdentificationCovered.value ? ['video', 'loc', 'auto'] : ['loc', 'external-sc']
    } else return []
  })

  /**
   * Redirects to the appropriate location after choosing an external method.
   */
  const redirect = () => {
    if (directSignSession.value)
      return navigateTo({
        name: 'direct-sign-identification-id-userid',
        params: {
          id: directSignSession.value.auth.password,
          userid: directSignSession.value.auth.username,
        },
      })
    return navigateTo({ name: 'profile-signature-standards' })
  }

  const selectMethod = (method: IdentificationMethod) => {
    if (directSignSession.value) return navigateTo({ name: `direct-sign-identification-${legislation}-${method}` })
    return navigateTo(
      Object.assign(
        { name: `profile-signature-standards-${legislation}-${method}` },
        srId.value ? { query: { sr: srId.value } } : {}
      )
    )
  }

  return { isIdentificationCovered, availableMethods, redirect, selectMethod, srId }
}
