<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M18,13.1c1.9,0,3.5-1.6,3.5-3.5S19.9,6.1,18,6.1s-3.5,1.6-3.5,3.5S16.1,13.1,18,13.1z M18,8.1c0.8,0,1.5,0.7,1.5,1.5
  s-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S17.2,8.1,18,8.1z M10,7.1c1.9,0,3.5-1.6,3.5-3.5S11.9,0.1,10,0.1S6.5,1.7,6.5,3.6
  S8.1,7.1,10,7.1z M10,2.1c0.8,0,1.5,0.7,1.5,1.5S10.8,5.1,10,5.1S8.5,4.5,8.5,3.6S9.2,2.1,10,2.1z M19.5,15.1h-5c-0.9-1.2-2.4-2-4-2
  H8c-1.5-1.6-4.4-2-5.5-2h-1c-0.6,0-1,0.4-1,1v7c0,0.4,0.2,0.7,0.6,0.9l6.8,3.4c0.6,0.3,1.2,0.4,1.8,0.4c0.5,0,1-0.1,1.4-0.3
  l11.8-4.5c0.4-0.2,0.7-0.6,0.6-1C23.4,17,22.5,15.1,19.5,15.1z M10.4,21.7c-0.5,0.2-1.1,0.2-1.6-0.1l-6.3-3.1v-5.4
  c1.1,0,3.4,0.5,4.2,1.6c0.2,0.3,0.5,0.4,0.8,0.4h3c1.3,0,2.4,0.8,2.8,2H7.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c0.6,0,1-0.4,1-1
  c0-0.3,0-0.7-0.1-1h4.1c0.8,0,1.3,0.2,1.6,0.5L10.4,21.7z"
    />
  </svg>
</template>
