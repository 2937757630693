const srRepository = (fetch: CustomFetch) => ({
  async get(id: string) {
    return fetch<SignatureRequestData>(`/v3/signature-requests/${id}`)
  },
  async getAll(type: SignatureRequestFilter = 'all', email?: string) {
    const searchParams: Record<string, string> = {}

    switch (type) {
      case 'toSign':
        Object.assign(searchParams, {
          status_overall: 'OPEN',
          signature_status: 'OPEN',
          account_email: email,
        })
        break
      case 'pending':
        Object.assign(searchParams, {
          status_overall: 'OPEN',
          /**
           * Magic number defined in the backend, signifying `signatures_count > 0` 😵‍💫
           *
           * @see {@link https://app.asana.com/0/1201960016666275/1204888603830081/f}
           */
          signatures_count: 672,
        })
        break
      case 'declined':
        searchParams.status_overall = 'DECLINED,WITHDRAWN'
        break
      case 'completed':
        searchParams.status_overall = 'SIGNED'
        break
    }
    return fetch<SignatureRequestData[]>('/v3/signature-requests', { query: searchParams })
  },
  async getAllDrafts() {
    return fetch<SignatureRequestData[]>('/v3/signature-requests', { query: { status_overall: 'DRAFT' } })
  },
  async create(documents: SignatureRequestDocument[], title: string) {
    return fetch<SignatureRequestData>('/v3/signature-requests', {
      method: 'POST',
      body: {
        title: title,
        documents: documents,
        message: '',
        signatures: [],
      },
    })
  },
  async initiate(id: string) {
    // move SR from DRAFT to OPEN state
    return fetch<SignatureRequestData>(`/v3/signature-requests/${id}/initiate`, {
      method: 'POST',
      body: {},
    })
  },
  async update(
    id: string,
    updateData:
      | Partial<SignatureRequestData>
      | (Omit<SignatureRequestData, 'signatures'> & { signatures: Partial<SignatureData>[] })
  ) {
    return fetch<SignatureRequestData>(`/v3/signature-requests/${id}`, {
      method: 'PUT',
      body: objectToSnakeCase(updateData),
    })
  },
  async getEvents(id: string) {
    return fetch<SignatureRequestEvent[]>(`/v3/signature-requests/${id}/events`)
  },
  async getDocumentsZip(signatureRequestId: string, isSigned?: boolean) {
    const srOptions: Record<string, string> = {}
    if (isSigned) srOptions.signed = 'true'

    const response = await fetch.raw<Blob>(`/v3/signature-requests/${signatureRequestId}/zip`, {
      query: srOptions,
    })

    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${signatureRequestId}.zip`,
    }
  },
  async addDocument(id: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}/documents`, {
      method: 'POST',
    })
  },
  async removeDocument(id: string, docId: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}/documents/${docId}`, {
      method: 'DELETE',
    })
  },
  async checkLock(signatureRequestId: string) {
    return fetch<{ locked: boolean }>(`/v3/signature-requests/${signatureRequestId}/lock-status`)
  },
  async decline(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}/decline`, {
      method: 'POST',
      body: { message },
    })
  },
  async withdraw(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}/withdraw`, {
      method: 'POST',
      body: { message },
    })
  },
  async remove(id: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}`, {
      method: 'DELETE',
    })
  },
  async remindSigners(id: string): Promise<void> {
    try {
      await fetch(`/v3/signature-requests/${id}/remind`, {
        method: 'POST',
      })
    } catch {
      throw new Error('Failed to remind signers')
    }
  },
  async delegate(srId: string, delegateData: SignatureDelegateData) {
    return await fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${srId}/delegate`, {
      method: 'POST',
      body: delegateData,
    })
  },
  async inviteSigner(srId: string, email: string, documentIds: string[]) {
    try {
      await fetch(`/v3/signature-requests/${srId}/signatures`, {
        method: 'POST',
        body: {
          account_email: email,
          signer_identity_data: {
            email_address: email,
          },
          document_signatures: documentIds.map(id => ({
            document_id: id,
          })),
        },
      })
    } catch {
      throw new Error('Failed to invite signer')
    }
  },
  async removeSigner(id: string, signatureId: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/signature-requests/${id}/signatures/${signatureId}`, {
      method: 'DELETE',
    })
  },
  async getUnclaimed(id: string) {
    return fetch<SendToResponse>(`/v3/sendto/${id}/get-internal`)
  },
  async claim(id: string) {
    try {
      return fetch<SignatureRequestData>(`/v3/sendto/${id}/claim`, {
        method: 'POST',
      })
    } catch {
      throw new Error('Failed to claim signature request')
    }
  },
})

export default srRepository
