<template>
  <div class="mb-10">
    <skr-heading level="1">{{ $t('business.settings.title') }}</skr-heading>
    <skr-heading level="2" class="mb-4">
      <i18n-t keypath="business.settings.subtitle_text">
        <template #link>
          <a rel="noopener" target="_blank" :href="$t('business.settings.link.admin_guide.link')">{{
            $t('business.settings.link.admin_guide.text')
          }}</a>
        </template>
      </i18n-t>
    </skr-heading>
  </div>
  <v-progress-circular v-if="isFetching" :size="70" color="text" indeterminate />
  <div v-else class="business-settings">
    <settings-section-header>
      {{ $t('business.settings.header.document') }}
    </settings-section-header>
    <template v-if="isLegacy || !isTeamPlan">
      <settings-entry
        icon="custom:auto_delete"
        :title="$t('business.settings.deletion_period.title')"
        :description="
          hasLegacyAutoDeletion
            ? $t('business.settings.deletion_period.legacy_description')
            : $t('business.settings.deletion_period.description')
        "
      >
        <template #contentAddition>
          <v-btn
            data-cy="autoDeleteToggleButton"
            size="large"
            variant="outlined"
            :color="settings.autoDelete.isEnabled ? 'danger' : 'info'"
            @click="onAutoDeleteToggleIntent"
          >
            {{ settings.autoDelete.isEnabled ? $t('global.deactivate') : $t('global.activate') }}
          </v-btn>
        </template>
        <template #controls>
          <settings-select
            v-model="settings.autoDelete.value"
            data-cy="deletionPeriodSelect"
            :placeholder="$t('business.settings.deletion_period.select_placeholder')"
            :is-disabled="!settings.autoDelete.isEnabled"
            :items="autoDeleteOptions"
          />
        </template>
      </settings-entry>
      <v-divider class="mt-14 mb-12" />
    </template>
    <settings-entry
      icon="custom:attachments_settings"
      :title="$t('business.settings.auto_attachment.title')"
      :description="$t('business.settings.auto_attachment.description')"
    >
      <template #controls>
        <settings-checkbox
          v-if="settings.autoAttachment.checkboxes"
          v-model="settings.autoAttachment.checkboxes.attachDocument"
          data-cy="attachDocCheckbox"
          :label="$t('business.settings.auto_attachment.signed_document')"
        />
        <v-divider v-if="isFeatureSignatureProtocolUsable" class="my-2" />
        <settings-checkbox
          v-if="settings.autoAttachment.checkboxes && isFeatureSignatureProtocolUsable"
          v-model="settings.autoAttachment.checkboxes.attachProtocol"
          data-cy="attachSignatureProtocolCheckbox"
          :label="$t('business.settings.auto_attachment.signature_protocol')"
          :is-disabled="isLegacy && !isEnterprise"
          :is-enterprise-only="isLegacy"
        />
      </template>
      <template #bottomContent>
        <div class="member-override">
          <div>{{ $t('business.settings.allow_member_override') }}</div>
          <skr-switch v-model="settings.autoAttachment.allowMemberOverride" />
        </div>
      </template>
    </settings-entry>
    <settings-section-header>
      {{ $t('business.settings.header.signatures') }}
    </settings-section-header>
    <settings-entry
      icon="custom:reply"
      :title="$t('business.settings.auto_reminder.title')"
      :description="$t('business.settings.auto_reminder.description')"
    >
      <template #controls>
        <settings-select
          v-model="settings.autoReminder.value"
          :is-disabled="!settings.autoReminder.isEnabled"
          :items="autoReminderOptions"
        />
      </template>
    </settings-entry>
    <save-changes-element
      is-fixed
      :offset="$vuetify.display.mdAndUp ? '18.5rem' : '0'"
      :is-loading="isSaving"
      :has-unsaved-changes="hasUnsavedChanges"
      @save="saveSettings"
    />
  </div>

  <dialog-activate-auto-deletion
    v-model="dialogValues.activateAutoDelete"
    :is-legacy="hasLegacyAutoDeletion"
    @activate="
      () => {
        settings.autoDelete.isEnabled = true
        settings.autoDelete.value = savedSettings?.signature_requests?.retention?.delete_after || 10
      }
    "
  />
  <dialog-deactivate-auto-deletion
    v-model="dialogValues.deactivateAutoDelete"
    @deactivate="
      () => {
        settings.autoDelete.isEnabled = false
        settings.autoDelete.value = null
      }
    "
  />
  <dialog-leave-page
    v-model="navigationTrap.isResolving"
    @leave="navigationTrap.resolveNavigation(false)"
    @stay="navigationTrap.resolveNavigation(true)"
  />
</template>

<script lang="ts">
import SettingsCheckbox from '@/components/business/settings/SettingsCheckbox.vue'
import SettingsEntry from '@/components/business/settings/SettingsEntry.vue'
import SettingsSectionHeader from '@/components/business/settings/SettingsSectionHeader.vue'
import SettingsSelect from '@/components/business/settings/SettingsSelect.vue'
import DialogActivateAutoDeletion from '@/components/dialogs/DialogActivateAutoDeletion.vue'
import DialogDeactivateAutoDeletion from '@/components/dialogs/DialogDeactivateAutoDeletion.vue'
import DialogLeavePage from '@/components/dialogs/DialogLeavePage.vue'
import SkrSwitch from '@/components/inputs/SkrSwitch.vue'
import SaveChangesElement from '@/components/SaveChangesElement.vue'
import SkrHeading from '@/components/SkrHeading.vue'

type BusinessSettings = Record<
  'autoDelete' | 'autoReminder' | 'autoAttachment',
  {
    isEnabled?: boolean
    checkboxes?: { [option: string]: boolean }
    value: SettingsValue | null
    allowMemberOverride?: boolean
  }
>

export default defineComponent({
  components: {
    SkrHeading,
    SkrSwitch,
    SettingsSectionHeader,
    SettingsEntry,
    SettingsSelect,
    SettingsCheckbox,
    DialogActivateAutoDeletion,
    DialogDeactivateAutoDeletion,
    DialogLeavePage,
    SaveChangesElement,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
      admin: {
        sidebar: false,
      },
      accessControl: {
        roles: ['admin'],
      },
    })

    const businessStore = useBusinessStore()
    const { loadUserBusinessData } = useUserStore()
    const widgetStore = useWidgetStore()

    const { formatDate } = useDate()

    const hasUnsavedChanges = ref(false)

    const { navigationTrap } = useNavigationTrap(hasUnsavedChanges)

    const isLegacy = computed(() => businessStore.isPrePricing2023)
    const hasLegacyAutoDeletion = computed(() => businessStore.companyHasLegacyAutoDeletion)

    const isTeamPlan = computed(() => businessStore.currentPricePlanName === 'Team')

    const businessSettings = computed(() => businessStore.settings)

    const isFeatureSignatureProtocolUsable = computed(() => {
      return (
        businessStore.companyIsEnterprise ||
        isTeamPlan.value ||
        businessStore.currentPricePlanName === 'Pro' ||
        businessStore.currentPricePlanName === 'Scale'
      )
    })

    return {
      hasUnsavedChanges,
      navigationTrap,
      isLegacy,
      hasLegacyAutoDeletion,
      isTeamPlan,
      formatDate,
      isFeatureSignatureProtocolUsable,
      loadUserBusinessData,
      businessSettings,
      getSettings: businessStore.getSettings,
      updateSettings: businessStore.updateSettings,
      isEnterprise: businessStore.companyIsEnterprise,
      widgetStore,
    }
  },
  data() {
    return {
      isFetching: true,
      dialogValues: {
        activateAutoDelete: false,
        deactivateAutoDelete: false,
      },
      isSaving: false,
      savedSettings: null as null | SettingsPayload,
      settingsReference: '',
      settings: {
        autoDelete: {
          isEnabled: true,
          value: 30,
        },
        autoReminder: {
          isEnabled: true,
          value: 0,
        },
        autoAttachment: {
          isEnabled: true,
          allowMemberOverride: false,
          value: 0,
          checkboxes: {
            attachDocument: false,
            attachProtocol: false,
          },
        },
      } as BusinessSettings,
    }
  },
  computed: {
    autoDeleteOptions(): SettingsOption[] {
      return [
        { value: 5, title: this.$t('global.countable.day', 5) },
        { value: 10, title: this.$t('global.countable.day', 10) },
        { value: 30, title: this.$t('global.countable.day', 30) },
        { value: 60, title: this.$t('global.countable.day', 60) },
        { value: 90, title: this.$t('global.countable.day', 90) },
      ]
    },
    autoReminderOptions(): SettingsOption[] {
      return [
        { value: 0, title: this.$t('business.auto_remind.inactive') },
        { value: 3, title: this.$t('business.auto_remind.interval.days3') },
        { value: 7, title: this.$t('business.auto_remind.interval.days7') },
        {
          value: 14,
          title: this.$t('business.auto_remind.interval.days14'),
        },
      ]
    },
    reminderInterval(): number[] {
      const values: number[] = []
      const step = this.settings.autoReminder.value as number

      if (this.settings.autoReminder.isEnabled && step > 0) {
        for (let count = step; count <= 90; count += step) {
          values.push(count)
        }
      }

      return values
    },
  },
  watch: {
    settings: {
      handler(changedSettings) {
        this.hasUnsavedChanges = JSON.stringify(changedSettings) !== this.settingsReference
      },
      deep: true,
    },
    settingsReference(updatedReference) {
      this.hasUnsavedChanges = JSON.stringify(this.settings) !== updatedReference
    },
  },
  created() {
    void this.fetchSettings()
  },
  methods: {
    async fetchSettings() {
      this.isFetching = true

      await this.getSettings()

      const { signatures, retention } = this.businessSettings.signatureRequests

      Object.assign(this.settings.autoReminder, {
        value: signatures?.reminder[0] ?? 0,
      })

      Object.assign(this.settings.autoDelete, {
        isEnabled: retention?.enabled ?? false,
        value: retention?.enabled ? retention.deleteAfter : null,
      })

      const { attachments } = this.businessSettings.mailService

      Object.assign(this.settings.autoAttachment, {
        checkboxes: {
          attachDocument: attachments.signedDocument?.enabled ?? false,
          attachProtocol: attachments.signatureProtocol?.enabled ?? false,
        },
        allowMemberOverride: attachments.signedDocument?.customisable ?? false,
      })

      this.updateSettingsReference()

      this.isFetching = false
    },
    updateSettingsReference() {
      this.settingsReference = JSON.stringify(this.settings)
    },
    onAutoDeleteToggleIntent() {
      if (this.settings.autoDelete.isEnabled) {
        this.dialogValues.deactivateAutoDelete = true
      } else {
        this.dialogValues.activateAutoDelete = true
      }
    },
    async saveSettings() {
      this.isSaving = true
      try {
        const attachments: Partial<AttachmentData> = Object.assign(
          {
            signedDocument: {
              enabled: this.settings.autoAttachment.checkboxes?.attachDocument ?? false,
              customisable: this.settings.autoAttachment.allowMemberOverride ?? false,
            },
          },
          // The backend will complain when sending data that a regular business
          // shouldn't be able to send (enterprise feature)
          this.isFeatureSignatureProtocolUsable
            ? {
                signatureProtocol: {
                  enabled: this.settings.autoAttachment.checkboxes?.attachProtocol ?? false,
                  customisable: this.settings.autoAttachment.allowMemberOverride ?? false,
                },
              }
            : {}
        )

        await this.updateSettings({
          mailService: { attachments },
          signatureRequests: {
            retention: {
              enabled: this.settings.autoDelete.isEnabled,
              // The backend expects a value here even if the setting is disabled
              deleteAfter: (this.settings.autoDelete.value ?? 30) as number,
            },
            signatures: {
              reminder: this.reminderInterval,
            },
          },
        })

        await this.loadUserBusinessData()

        this.updateSettingsReference()

        this.widgetStore.createSnackbar({
          message: this.$t('business.settings.save_success'),
        })
      } catch {
        this.widgetStore.createSnackbar({
          message: this.$t('business.settings.error'),
        })
      }
      this.isSaving = false
    },
  },
})
</script>

<style lang="sass" scoped>
$save-element-height: 88px

.business-settings
  padding-bottom: $save-element-height + 12px
  &:deep(.settings-header:not(:first-of-type))
    margin-top: 3.5rem

  .member-override
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 2rem
    padding: 0.75rem 1.5rem
    background-color: rgba(181, 213, 247, 0.15)
    border-radius: 4px 4px 0 0
    border-bottom: 1px solid #DADEE6
    &:deep(.v-selection-control)
      margin-top: 0
</style>
