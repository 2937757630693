<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M11.7,0.5c-4.4,0-8,3.6-8,8c0,2,0.8,3.9,2,5.3v8.7c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.2,1,0l4.5-2.7l4.5,2.7c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.5,0.5-0.9v-8.7c1.2-1.4,2-3.3,2-5.3C19.7,4.1,16.1,0.5,11.7,0.5zM15.7,20.7l-3.5-2.1c-0.3-0.2-0.7-0.2-1,0l-3.5,2.1v-5.3c1.2,0.7,2.5,1.1,4,1.1s2.8-0.4,4-1.1V20.7z M11.7,14.5c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S15,14.5,11.7,14.5z"
    />
  </svg>
</template>
