<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M21.2,2.8c-0.5-0.5-1.2-0.8-2-0.8c-0.6,0-1,0.4-1,1s0.4,1,1,1c0.2,0,0.4,0.1,0.6,0.2C19.9,4.4,20,4.6,20,4.8v14.4
  c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.4,0.2-0.6,0.2H4.8c-0.2,0-0.4-0.1-0.6-0.2C4.1,19.6,4,19.4,4,19.2V4.8c0-0.2,0.1-0.4,0.2-0.6
  C4.4,4.1,4.6,4,4.8,4c0.6,0,1-0.4,1-1s-0.4-1-1-1c-0.7,0-1.5,0.3-2,0.8C2.3,3.3,2,4.1,2,4.8v14.4c0,0.7,0.3,1.5,0.8,2
  c0.5,0.5,1.2,0.8,2,0.8h14.4c0.7,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2V4.8C22,4.1,21.7,3.3,21.2,2.8z M8,12.9
  C8.1,13,8.2,13,8.4,13c0.2,0,0.4-0.1,0.6-0.2l3-2.2l3,2.2c0.3,0.2,0.7,0.3,1,0.1c0.3-0.2,0.6-0.5,0.6-0.9V3c0-0.6-0.4-1-1-1H8.4
  c-0.6,0-1,0.4-1,1v9C7.4,12.4,7.6,12.7,8,12.9z M9.4,4h5.2v6l-2-1.5c-0.2-0.1-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2l-2,1.5V4z"
    />
  </svg>
</template>
