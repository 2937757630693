<script setup></script>

<template>
  <svg
    fill="#eef0f3"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    xml:space="preserve"
  >
    <circle cx="28" cy="28" fill="#eef0f3" r="28" />
    <g fill="#a1aaba">
      <path d="m16 25.87h8.17a1.16 1.16 0 1 0 0-2.31h-8.17a1.16 1.16 0 1 0 0 2.31z" />
      <path d="m16 32.79h8.17a1.16 1.16 0 1 0 0-2.31h-8.17a1.16 1.16 0 1 0 0 2.31z" />
      <path d="m33.54 37.42h-17.54a1.16 1.16 0 1 0 0 2.31h17.5a1.16 1.16 0 1 0 0-2.31z" />
      <path d="m16 18.93h17.5a1.16 1.16 0 1 0 0-2.31h-17.5a1.16 1.16 0 1 0 0 2.31z" />
      <path
        d="m38.4 31.35 4.13 4.09a1.15 1.15 0 0 1 0 1.64 1.18 1.18 0 0 1 -.82.34 1.21 1.21 0 0 1 -.83-.34l-4.13-4.08a5.76 5.76 0 1 1 2.62-4.81 5.68 5.68 0 0 1 -.97 3.16zm-8.4-3.17a3.5 3.5 0 1 0 3.5-3.47 3.48 3.48 0 0 0 -3.5 3.47z"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>
