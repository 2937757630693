<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M7,8h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H7C6.4,6,6,6.4,6,7S6.4,8,7,8z M7,13h7c0.6,0,1-0.4,1-1s-0.4-1-1-1H7c-0.6,0-1,0.4-1,1
  S6.4,13,7,13z M23,1H1C0.4,1,0,1.4,0,2v15c0,0.6,0.4,1,1,1h4v4c0,0.4,0.2,0.7,0.5,0.9C5.7,23,5.8,23,6,23c0.2,0,0.4-0.1,0.6-0.2
  l6.7-4.8H23c0.6,0,1-0.4,1-1V2C24,1.4,23.6,1,23,1z M22,16h-9c-0.2,0-0.4,0.1-0.6,0.2L7,20.1V17c0-0.6-0.4-1-1-1H2V3h20V16z"
    />
  </svg>
</template>
