import type { RouteLocationNormalized } from 'vue-router'

export default defineNuxtPlugin(() => {
  const router = useRouter()

  const getRouteTitle = (route: RouteLocationNormalized) => {
    if (route.meta.title) {
      return route.meta.title
    }

    return route.matched[0].path
      .split('/') // Split the route string into an array
      .filter(segment => !segment.startsWith(':')) // Filter out dynamic segments
      .slice(-1) // Select last element
      .map(word => {
        // Capitalize words
        if (word.includes('-')) {
          return word.split('-').map(capitalize).join(' ')
        } else {
          return capitalize(word)
        }
      })
  }

  router.beforeEach(to => {
    const { getMatomoTracker } = useMatomo()
    const tracker = getMatomoTracker()

    tracker.push(['setDocumentTitle', getRouteTitle(to)])

    useHead({
      title() {
        return `${getRouteTitle(to)} - Skribble`
      },
    })
  })
})
