<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M23,6h-2V1c0-0.6-0.4-1-1-1H6C5.4,0,5,0.4,5,1v1H3C1.3,2,0,3.3,0,5v15c0,2.2,1.8,4,4,4h19c0.6,0,1-0.4,1-1V7
      C24,6.4,23.6,6,23,6z M7,2h12v4H7V2z M3,4h2v2H3C2.4,6,2,5.6,2,5S2.4,4,3,4z M22,22H4c-1.1,0-2-0.9-2-2V7.8c0.1,0,0.2,0,0.3,0.1
      c0.1,0,0.1,0,0.2,0C2.6,8,2.8,8,3,8h19V22z M17,18c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S15.3,18,17,18z M17,14c0.6,0,1,0.4,1,1
      s-0.4,1-1,1s-1-0.4-1-1S16.4,14,17,14z"
    />
  </svg>
</template>
