<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M16,10c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18,9.1,17.1,10,16,10z M18,19H6c-0.6,0-1-0.4-1-1c0-0.2,0-0.3,0.1-0.4l4-8C9.2,9.3,9.5,9.1,9.8,9c0.3-0.1,0.6,0.1,0.9,0.3l8,8c0.4,0.4,0.4,1,0,1.4C18.5,18.9,18.3,19,18,19z M2,1h20c0.6,0,1,0.4,1,1v20c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1V2C1,1.4,1.4,1,2,1z M3,21h18V3H3V21z"
    />
  </svg>
</template>
