<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M23.2,16.3l-3-3c-0.4-0.4-1-0.4-1.4,0l-6,6c-0.2,0.2-0.3,0.4-0.3,0.7v3c0,0.6,0.4,1,1,1h3c0.3,0,0.5-0.1,0.7-0.3l6-6
  C23.6,17.3,23.6,16.7,23.2,16.3z M16.1,22h-1.6v-1.6l5-5l1.6,1.6L16.1,22z M3.8,15.1c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7V6
  c0-3.3-2.7-6-6-6s-6,2.7-6,6v1c0,1.9,0.9,3.9,2.3,5.3c-1.4,0.2-2.7,0.6-3.6,0.9c-1.6,0.5-2.7,2.1-2.7,3.8v4c0,0.6,0.4,1,1,1h8
  c0.6,0,1-0.4,1-1s-0.4-1-1-1h-7v-3C2.5,16.2,3,15.4,3.8,15.1z M6.5,7V6c0-2.2,1.8-4,4-4s4,1.8,4,4v1c0,2.3-1.8,5-4,5S6.5,9.3,6.5,7z
  "
    />
  </svg>
</template>
