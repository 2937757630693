<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,8c2.2,0,4-1.8,4-4s-1.8-4-4-4S8,1.8,8,4S9.8,8,12,8z M12,2c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S10.9,2,12,2z M20,8
  c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S18.3,8,20,8z M20,4c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S19.4,4,20,4z M21,9h-1
  c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1,0.4,1,1v4h-1c-0.6,0-1,0.4-1,1v3h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h3c0.6,0,1-0.4,1-1v-3h1
  c0.6,0,1-0.4,1-1v-5C24,10.3,22.7,9,21,9z M18,18v-6c0-1.7-1.3-3-3-3H9c-1.7,0-3,1.3-3,3v5c0,0.6,0.4,1,1,1h1v5c0,0.6,0.4,1,1,1h6
  c0.6,0,1-0.4,1-1v-4h1C17.6,19,18,18.6,18,18z M16,17h-1c-0.6,0-1,0.4-1,1v4h-4v-5c0-0.6-0.4-1-1-1H8v-4c0-0.6,0.4-1,1-1h6
  c0.6,0,1,0.4,1,1V17z M4,8c1.7,0,3-1.3,3-3S5.7,2,4,2S1,3.3,1,5S2.3,8,4,8z M4,4c0.6,0,1,0.4,1,1S4.6,6,4,6S3,5.6,3,5S3.4,4,4,4z
   M6,20H4v-3c0-0.6-0.4-1-1-1H2v-4c0-0.6,0.4-1,1-1h1c0.6,0,1-0.4,1-1S4.6,9,4,9H3c-1.7,0-3,1.3-3,3v5c0,0.6,0.4,1,1,1h1v3
  c0,0.6,0.4,1,1,1h3c0.6,0,1-0.4,1-1S6.6,20,6,20z"
    />
  </svg>
</template>
