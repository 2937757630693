<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      fill="#ED0000"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22,0H2C0.9,0,0,0.9,0,2v20c0,1.1,0.9,2,2,2h20c1.1,0,2-0.9,2-2V2
  C24,0.9,23.1,0,22,0z M6,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C8,13.1,7.1,14,6,14z M12,20c-1.1,0-2-0.9-2-2
  c0-1.1,0.9-2,2-2s2,0.9,2,2C14,19.1,13.1,20,12,20z M12,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C14,13.1,13.1,14,12,14z
   M12,8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C14,7.1,13.1,8,12,8z M18,14c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2
  C20,13.1,19.1,14,18,14z"
    />
  </svg>
</template>
