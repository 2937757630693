type Platform = 'CH' | 'EU'

interface StatusPageResponse {
  page: {
    name: string
    url: string
    status: string
  }
  activeIncidents?: {
    id: string
    name: string
    started: string
    status: string
    impact: string
    url: string
    updatedAt: string
  }[]
  activeMaintenances?: ActiveMaintenance[]
}

export const useSystemStore = defineStore('system', () => {
  const { $config } = useNuxtApp()

  const domainName = computed<string>(() => $config.public.domainName)

  const platform = computed<Platform>(() => {
    if (/\.(de|eu)$/.test(domainName.value)) {
      return 'EU'
    } else return 'CH'
  })

  const emails = reactive({
    support: 'support@' + domainName.value,
    contact: 'contact@' + domainName.value,
    success: 'success@' + domainName.value,
  })

  const redirectUrls = reactive({
    homepage: 'https://' + domainName.value,
  })

  const time = reactive({
    now: '',
    lastUpdated: 0,
  })

  const updateTime = async () => {
    if ((Date.now() - time.lastUpdated) * 0.001 < 2) return

    const { systemRepository } = useApi()

    const { now } = await systemRepository.getDateTime()
    time.now = now
    time.lastUpdated = Date.now()
  }

  const roleHierarchy: AppRole[] = ['unauthenticated', 'nas', 'user', 'member', 'admin']

  const activeMaintenance = shallowRef<ActiveMaintenance>()

  const { instatus } = useRuntimeConfig().public

  const { logError } = useErrorHandling()

  const maintenanceInterval = ref(0)

  watch(activeMaintenance, maintenance => {
    if (maintenance) {
      maintenanceInterval.value = maintenanceInterval.value || window.setInterval(checkMaintenance, 60000)
    } else {
      window.clearInterval(maintenanceInterval.value)
      maintenanceInterval.value = 0
    }
  })

  const checkMaintenance = async () => {
    if (!instatus?.url) return

    try {
      const { activeMaintenances } = await $fetch<StatusPageResponse>(`${instatus.url}/summary.json`)
      activeMaintenance.value = activeMaintenances?.filter(maintenance => maintenance.status === 'INPROGRESS')[0]
    } catch (error) {
      logError('Failed to fetch status page info', error)
    }
  }

  return {
    emails,
    redirectUrls,
    platform,
    time,
    roleHierarchy,
    activeMaintenance,
    checkMaintenance,
    updateTime,
  }
})
