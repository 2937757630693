<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M13,8c0-0.6-0.4-1-1-1H5C4.4,7,4,7.4,4,8s0.4,1,1,1h7C12.6,9,13,8.6,13,8z M24,11.5c0-1.6-0.8-3.1-2-4v-4c0-0.6-0.4-1-1-1H1
  c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h11c0.6,0,1-0.4,1-1s-0.4-1-1-1H2v-12h18v2.1c-0.3-0.1-0.7-0.1-1-0.1c-2.8,0-5,2.2-5,5
  c0,1.1,0.4,2.1,1,3c0,0,0,0,0,0v6c0,0.3,0.2,0.6,0.4,0.8c0.3,0.2,0.6,0.2,0.9,0.1l2.7-0.8l2.7,0.8c0.1,0,0.2,0,0.3,0
  c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.8v-6c0,0,0,0,0,0C23.6,13.6,24,12.6,24,11.5z M21,19.1l-1.7-0.5
  c-0.2-0.1-0.4-0.1-0.6,0L17,19.1v-3.1c0.6,0.3,1.3,0.4,2,0.4s1.4-0.2,2-0.4V19.1z M19,14.5c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3
  S20.7,14.5,19,14.5z M5,11c-0.6,0-1,0.4-1,1s0.4,1,1,1h4c0.6,0,1-0.4,1-1s-0.4-1-1-1H5z"
    />
  </svg>
</template>
