interface CookieConsentData {
  consent: {
    marketing: boolean
    method: string
    necessary: boolean
    preferences: boolean
    stamp: string
    statistics: boolean
  }
  consented: boolean
  declined: boolean
  hasResponse: boolean
  doNotTrack: boolean
  regulations: {
    gdprApplies: boolean
    ccpaApplies: boolean
    lgpdApplies: boolean
  }
}

declare global {
  interface Window {
    Cookiebot?: CookieConsentData
  }
}

export default function useCookieConsent() {
  const cookieConsentData = ref<CookieConsentData | null>(null)

  const setCookieConsentDataValue = () => {
    const cookiebot: CookieConsentData | undefined = window.Cookiebot
    if (cookiebot !== undefined) {
      cookieConsentData.value = {
        consent: cookiebot.consent,
        consented: cookiebot.consented,
        declined: cookiebot.declined,
        hasResponse: cookiebot.hasResponse,
        doNotTrack: cookiebot.doNotTrack,
        regulations: cookiebot.regulations,
      }
    }
  }

  const cookiebotEventTypes = [
    'CookiebotOnDialogInit',
    'CookiebotOnConsentReady',
    'CookiebotOnAccept',
    'CookiebotOnDecline',
  ]
  cookiebotEventTypes.forEach(eventType => window.addEventListener(eventType, () => setCookieConsentDataValue()))

  return {
    cookieConsentData,
  }
}
