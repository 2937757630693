<script lang="ts" setup>
const widgetStore = useWidgetStore()

const activeDialog = computed(() => widgetStore.activeDialog)

const isLoading = ref(false)

watch(activeDialog, overlay => {
  if (overlay) {
    document.body.classList.add('overflow-clip')
  } else {
    document.body.classList.remove('overflow-clip')
  }
})

const dialogComponent = computed(() => {
  if (!activeDialog.value) return null

  const name = activeDialog.value.name
  const namespace = activeDialog.value.namespace

  switch (namespace) {
    case 'document':
      return defineAsyncComponent(() => import(`./dialogs/document/${name}.vue`))
    case 'business':
      return defineAsyncComponent(() => import(`./dialogs/business/${name}.vue`))
    case 'profile':
      return defineAsyncComponent(() => import(`./dialogs/profile/${name}.vue`))
    case 'visual-signature':
      return defineAsyncComponent(() => import(`./dialogs/visual-signature/${name}.vue`))
    default:
      return defineAsyncComponent(() => import(`./dialogs/${name}.vue`))
  }
})

const onAction = async <T extends DialogName>(data?: Dialogs[T]['contextData']) => {
  isLoading.value = true
  try {
    const initialDialogId = activeDialog.value?.id
    await activeDialog.value?.onAction?.(data)
    if (initialDialogId === activeDialog.value?.id) {
      // Close the active dialog if it wasn't exchanged during the action
      onClose()
    }
  } finally {
    isLoading.value = false
  }
}

const onClose = () => {
  widgetStore.activeDialog = null
}
</script>

<template>
  <div v-if="dialogComponent">
    <component
      :is="dialogComponent"
      :key="activeDialog?.id"
      v-bind="activeDialog?.props ?? {}"
      :loading="isLoading"
      @action="onAction"
      @close="onClose"
    />
  </div>
</template>
