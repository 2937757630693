<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M13,16.8c-0.1,0.5,0.2,1.1,0.7,1.2c0.1,0,0.2,0,0.2,0c0.4,0,0.9-0.3,1-0.8c0.3-1.3,1.5-2.2,2.9-2.2c0.8,0,1.6,0.3,2.1,0.9
  l-1.6,1.2l4.3,0.9l0.4-4.4l-1.5,1.1c-0.9-1.1-2.3-1.7-3.8-1.7C15.6,13,13.6,14.5,13,16.8z M10.9,20h-8v-3c0-0.9,0.5-1.6,1.3-1.9
  c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7V6c0-3.3-2.7-6-6-6s-6,2.7-6,6v1c0,1.9,0.9,3.9,2.3,5.3c-1.4,0.2-2.7,0.6-3.6,0.9
  C2,13.8,0.9,15.3,0.9,17v4c0,0.6,0.4,1,1,1h9c0.6,0,1-0.4,1-1S11.4,20,10.9,20z M6.9,7V6c0-2.2,1.8-4,4-4s4,1.8,4,4v1
  c0,2.3-1.8,5-4,5S6.9,9.3,6.9,7z M22,19c-0.5-0.1-1.1,0.2-1.2,0.7c-0.3,1.3-1.5,2.2-2.9,2.2c-0.8,0-1.6-0.3-2.1-0.9l1.6-1.2L13.1,19
  l-0.4,4.4l1.5-1.1c0.9,1.1,2.3,1.7,3.8,1.7c2.3,0,4.3-1.5,4.8-3.8C22.9,19.7,22.5,19.2,22,19z"
    />
  </svg>
</template>
