<script setup></script>

<template>
  <svg
    fill="currentColor"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M0,9.6v4.8h4.8V9.6H0z M0,24h4.8v-4.8H0V24z M4.8,14.4v4.8h4.8v-4.8H4.8z M19.2,0v4.8h-4.8v4.8h4.8v4.8h-4.8v4.8H9.6V24H24
  V0H19.2z M9.6,4.8h4.8V0H0v4.8h4.8v4.8h4.8V4.8z"
    />
  </svg>
</template>
