export interface GetUserResponse {
  email: string
  firstName: string
  lastName: string
  entireName: string
  attributes: Camelized<UserAttributes>
  permissions: { [index: string]: boolean }
  emailVerified: boolean
  username: string
  retention: number
  disabledQualities: string[]
  hasSso: boolean
}

/*
const attributesSchema = object({
  stripeSubscriptionId: string().optional(),
  lang: string(),
  defaultLegislation: string().optional(),
  defaultQuality: string().optional(),
  paidBy: string().optional(),
  immutableEidFields: array(string()).optional(),
  acceptedGtc: boolean(),
})

function parseAttributes(attributes: Camelized<GetUserResponse>['attributes']): Partial<UserAttributes> {
  return attributesSchema.cast({
    stripeSubscriptionId: attributes.stripeSubscriptionId?.[0],
    lang: attributes.lang[0],
    defaultLegislation: attributes.defaultLegislation?.[0],
    defaultQuality: attributes.defaultQuality?.[0],
    paidBy: attributes.paidBy?.[0],
    immutableEidFields: attributes.immutableEidFields,
    acceptedGtc: Boolean(attributes.acceptedGtc?.[0]),
  })
}
*/

const userRepository = (fetch: CustomFetch) => ({
  async register(registerData: RegisterUserPayload) {
    await fetch('/v1/register', {
      method: 'POST',
      body: {
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        password: registerData.password,
        token: registerData.token,
        gtc: registerData.isGtcAccepted,
        lang: registerData.language,
        mobile: '', // FIXME: The backend requires a mobile number, but we actually never ask for it
        avatar: 'f2', // TODO: No idea what this is, and whether we still need it
        celloUCC: registerData.celloUCC || '',
      },
    })
  },
  async get(): Promise<IUser> {
    const userData = await fetch<GetUserResponse>('/v1/user')

    // FIXME: We are still using snake_case for attributes
    const baseData = { ...userData, attributes: objectToSnakeCase(userData.attributes) }

    const signatureQualityData = await fetch<SignatureQualities>('/v3/user/signature-qualities-detail')

    return {
      id: baseData.username,
      email: baseData.email,
      emailVerified: baseData.emailVerified,
      firstName: baseData.firstName,
      lastName: baseData.lastName,
      attributes: baseData.attributes,
      signatureQualities: {
        ...signatureQualityData,
        // FIXME: We are still using snake_case for seals
        seal: objectToSnakeCase(signatureQualityData.seal),
      },
      disabledQualities: baseData.disabledQualities,
      retention: baseData.retention,
      hasSso: baseData.hasSso,
      signatures: [
        {
          name: baseData.attributes.visual_signature_name?.[0],
          company: baseData.attributes.visual_signature_company?.[0],
          language: baseData.attributes.lang?.[0] ?? 'en',
          location: baseData.attributes.visual_signature_location?.[0],
          displayClaim: baseData.attributes.visual_signature_display_claim?.[0] === 'true',
          displayStandards: baseData.attributes.visual_signature_display_standards?.[0] === 'true',
          displayQr: baseData.attributes.visual_signature_display_qr?.[0] === 'true',
          displayDate: baseData.attributes.visual_signature_display_date?.[0] === 'true',
        },
      ],
      permissions: baseData.permissions,
    }
  },
  async update(updateData: Partial<IUser> & { currentPassword?: string }) {
    const payload = {
      ...objectToSnakeCase(updateData),
      // FIXME: There is a casing mismatch here (backend expects mixed case in payload 🙄)
      firstName: updateData.firstName,
      lastName: updateData.lastName,
    }

    return fetch<{ status: string; message: string }>('/v1/user', {
      method: 'PUT',
      body: payload,
    })
  },
  // FIXME: Should be DELETE with base path, but the backend uses POST
  async delete(password?: string) {
    await fetch('/v1/user/delete', {
      method: 'POST',
      body: { password },
    })
  },
  async updateAttributes(attributes: Partial<Record<keyof UserAttributes, unknown>>) {
    return fetch<{ status: string; message: string }>('/v1/user', {
      method: 'PUT',
      body: { attributes },
    })
  },
  async login(email: string, password: string, isFirstLogin = false) {
    const { token } = await fetch<{ token: string }>('/v1/login', {
      method: 'POST',
      body: {
        email,
        password,
        ...(isFirstLogin && { first_login: isFirstLogin }),
      },
    })
    return token
  },
  async logout() {
    await fetch('/v1/logout', { method: 'POST' })
  },
  async requestConfirmationEmail(email: string, language: string, flow: string = 'free', confirm = false) {
    await fetch('/v1/request-confirmation', {
      method: 'POST',
      body: {
        email,
        flow,
        lang: language,
        ...(confirm && { confirm }),
      },
    })
  },
  async verifyConfirmationToken(token: string, language: string) {
    return fetch<{ email: string; flow: string; status: string }>('/v1/valid-confirmation', {
      method: 'POST',
      body: { token, language },
    })
  },
  async validatePassword(password: string, token: string | null = null) {
    const { validPassword: isValid, error: errorCode } = await fetch<{
      validPassword: boolean
      error?: PasswordErrorCode
    }>('/v1/check-password', {
      method: 'POST',
      body: { password, ...(token && { token }) },
    })

    return { isValid, errorCode }
  },
  /**
   * Changes the password for the logged-in user.
   */
  async changePassword(newPassword: string, oldPassword: string) {
    return fetch<{ status: string; message: string }>('/v1/change-password', {
      method: 'POST',
      body: { new: newPassword, old: oldPassword },
    })
  },
  /**
   * Resets the password for a logged-out user.
   */
  async resetPassword(token: string, newPassword: string) {
    await fetch('/v1/reset-password', {
      method: 'POST',
      body: { token, new: newPassword },
    })
  },
  async requestPasswordReset(email: string) {
    await fetch('/v1/request-reset', {
      method: 'POST',
      body: { email },
    })
  },
  async acceptBusinessInvitation() {
    await fetch('/v1/user/accept', {
      method: 'POST',
      body: { business_invite: true },
    })
  },
  async acceptGeneralTerms() {
    await fetch('/v1/user/accept', {
      method: 'POST',
      body: { gtc: true },
    })
  },
  async acceptSwisscomTerms() {
    await fetch('/v1/user/accept', {
      method: 'POST',
      body: { tou_swisscom: true },
    })
  },
  async retrieveCelloToken() {
    return fetch<{ token: string }>('/v1/user/cello', {
      method: 'POST',
      body: {},
    })
  },
})

export default userRepository
