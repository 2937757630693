<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.5,3.5c-4.7-4.7-12.3-4.7-17,0s-4.7,12.3,0,17c4.7,4.7,12.3,4.7,17,0S25.2,8.2,20.5,3.5z M19.1,19.1
  C15.2,23,8.8,23,4.9,19.1C1,15.2,1,8.8,4.9,4.9s10.2-3.9,14.1,0C23,8.8,23,15.2,19.1,19.1z M13.4,12l2.8-2.8c0.4-0.4,0.4-1,0-1.4
  c-0.4-0.4-1-0.4-1.4,0L12,10.6L9.2,7.8c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.8,2.8l-2.8,2.8c-0.4,0.4-0.4,1,0,1.4
  c0.4,0.4,1,0.4,1.4,0l2.8-2.8l2.8,2.8c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L13.4,12z"
    />
  </svg>
</template>
