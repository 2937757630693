<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M17.7,14.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L17.7,14.3z
   M6.3,14.3l-2,2c-0.4,0.4-0.4,1,0,1.4C4.5,17.9,4.7,18,5,18s0.5-0.1,0.7-0.3l2-2c0.4-0.4,0.4-1,0-1.4S6.7,13.9,6.3,14.3z M18.3,6.3
  L12,12.6L5.7,6.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l7,7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l7-7c0.4-0.4,0.4-1,0-1.4
  S18.7,5.9,18.3,6.3z M21,2H3C1.3,2,0,3.3,0,5v14c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V5C24,3.3,22.7,2,21,2z M22,19c0,0.6-0.4,1-1,1
  H3c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1V19z"
    />
  </svg>
</template>
