<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M4.3,15.6c1.5-0.5,4-1.1,6.7-1.1c3.4,0,6-3.8,6-7v-1c0-3.3-2.7-6-6-6s-6,2.7-6,6v1c0,1.9,0.9,3.9,2.3,5.3
    c-1.4,0.2-2.7,0.6-3.6,0.9C2.1,14.3,1,15.8,1,17.5v4c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H3v-3C3,16.7,3.5,15.9,4.3,15.6
    z M7,7.5v-1c0-2.2,1.8-4,4-4s4,1.8,4,4v1c0,2.3-1.8,5-4,5S7,9.8,7,7.5z"
    />
    <path
      d="M20.4,19.5l2.3-2.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L19,18.1l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.3,2.3
    l-2.3,2.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.3-2.3l2.3,2.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
    c0.4-0.4,0.4-1,0-1.4L20.4,19.5z"
    />
  </svg>
</template>
