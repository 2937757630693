<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M20.2,1H5.6C4.1,1,2.8,2.2,2.8,3.8v16C2.8,21.6,4.3,23,6,23h14.2c0.5,0,0.9-0.4,0.9-0.9V1.9C21.2,1.4,20.8,1,20.2,1z
   M12,2.8h3.7v5.6l-1.3-0.9c-0.3-0.2-0.7-0.2-1,0L12,8.5V2.8z M19.3,21.2H6c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h13.3V21.2z
   M19.3,16.6H6c-0.5,0-1,0.1-1.4,0.3V3.8c0-0.5,0.4-0.9,0.9-0.9h4.6v7.3c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.7,0.1,0.9,0l2.2-1.5
  l2.2,1.5c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1c0.3-0.2,0.5-0.5,0.5-0.8V2.8h1.8V16.6z"
    />
  </svg>
</template>
