<script setup></script>

<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M24.45 0H0.450012V24H24.45V0Z" fill="white" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <path
          d="M22.45 0H2.45001C1.34544 0 0.450012 0.89543 0.450012 2V22C0.450012 23.1046 1.34544 24 2.45001 24H22.45C23.5546 24 24.45 23.1046 24.45 22V2C24.45 0.89543 23.5546 0 22.45 0Z"
          fill="white"
        />
        <path
          d="M22.45 0.5H2.45001C1.62159 0.5 0.950012 1.17157 0.950012 2V22C0.950012 22.8284 1.62159 23.5 2.45001 23.5H22.45C23.2784 23.5 23.95 22.8284 23.95 22V2C23.95 1.17157 23.2784 0.5 22.45 0.5Z"
          stroke="black"
          stroke-opacity="0.0520105"
        />
      </mask>
      <g mask="url(#mask0)">
        <path d="M24.45 0H0.450012V24H24.45V0Z" fill="url(#paint0_linear)" />
        <path d="M24.45 0H0.450012V24H24.45V0Z" fill="url(#paint1_linear)" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.14992 11.228C5.09081 11.345 5.07458 11.4704 5.07458 11.5795H6.40983C6.40983 11.4704 6.3936 11.345 6.33449 11.228C6.25104 11.0365 6.0679 10.8777 5.7422 10.8777C5.4165 10.8777 5.23337 11.0365 5.14992 11.228ZM5.74225 9.75C7.04389 9.75 7.76251 10.7024 7.76251 11.8799V12.4901H5.07462C5.07462 12.8579 5.3586 13.1918 5.86743 13.1918C6.34265 13.1918 6.55128 13.0573 6.79353 12.816L7.61183 13.6339C7.15284 14.0933 6.70196 14.3438 5.86743 14.3438C4.8741 14.3438 3.73126 13.9853 3.73126 12.0469C3.73126 10.5771 4.56579 9.75 5.74225 9.75ZM8.4422 11.0156V9.77344H10.95L10.0034 11.0156H8.4422ZM10.1615 13.0312L9.21563 14.2734H11.8172V13.0312H10.1615ZM8.32501 13.9973L11.5533 9.79688H11.8172V10.0777L8.59239 14.2734H8.32501V13.9973ZM15.1107 9.79688V12.4889C15.1107 13.0066 14.744 13.1076 14.5594 13.1076C14.3761 13.1076 14.0082 13.0066 14.0082 12.4889V9.79688H12.6375V12.6723C12.6375 13.1493 12.7038 13.5754 13.0891 13.9596C13.3316 14.2022 13.674 14.3438 14.0999 14.3438C14.4596 14.3438 14.8693 14.2022 15.1443 13.926V14.294H16.4812V9.79688H15.1107ZM18.7379 11.8797C18.7379 12.339 18.7715 12.774 19.2711 12.774C19.7707 12.774 19.8031 12.339 19.8031 11.8797C19.8031 11.4203 19.7707 10.9865 19.2711 10.9865C18.7715 10.9865 18.7379 11.4203 18.7379 11.8797ZM19.8445 10.1676V9.79988H21.1688V13.9676C21.1688 15.0289 20.3696 15.7969 19.146 15.7969C18.4463 15.7969 17.9212 15.6715 17.4632 15.2123L18.3133 14.3608C18.5215 14.5695 18.7713 14.645 19.0789 14.645C19.6537 14.645 19.8029 14.2517 19.8029 13.9595V13.6335C19.553 13.884 19.2535 14.0093 18.8372 14.0093C18.4047 14.0093 18.0461 13.8679 17.7882 13.6092C17.3799 13.1997 17.3719 12.6986 17.3719 11.8797C17.3719 11.0606 17.3799 10.5597 17.7882 10.1513C18.0461 9.89152 18.4127 9.75 18.8453 9.75C19.3033 9.75 19.5704 9.89152 19.8445 10.1676Z"
          fill="black"
        />
        <path
          d="M22.45 0.5H2.45001C1.62159 0.5 0.950012 1.17157 0.950012 2V22C0.950012 22.8284 1.62159 23.5 2.45001 23.5H22.45C23.2784 23.5 23.95 22.8284 23.95 22V2C23.95 1.17157 23.2784 0.5 22.45 0.5Z"
          stroke="black"
          stroke-opacity="0.0520105"
        />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="0.450012" y1="0" x2="0.450012" y2="24" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="0.450012" y1="0" x2="0.450012" y2="24" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="#F9FAFB" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(0.450012)" />
      </clipPath>
    </defs>
  </svg>
</template>
