/**
 * Cello is a third-party service used for generating and sending referral codes for signing up to the platform.
 * This plugin is used to load the Cello attribution script which handles tracking and rewarding referrers.
 */

export default defineNuxtPlugin(nuxtApp => {
  const { $config } = useNuxtApp()
  const { cookieConsentData } = useCookieConsent()
  const { logError } = useErrorHandling()

  // Watch cookie consent data to enable cello attribution cookies
  watch(cookieConsentData, () => {
    if (cookieConsentData.value?.consented && window.CelloAttribution) {
      window.CelloAttribution('allowCookies')
    }
  })

  const loadScript = (): Promise<void> => {
    const scriptAlreadyExists = document.querySelector(`script[src="${$config.public.cello.attributionScript}"]`)

    if (scriptAlreadyExists) {
      return Promise.resolve()
    }

    const script = document.createElement('script')
    script.src = $config.public.cello.attributionScript
    script.type = 'module'
    script.async = true
    return new Promise((resolve, reject) => {
      const firstScript = document.getElementsByTagName('script')[0]
      if (!firstScript?.parentNode) {
        reject(new Error('No parent node found for script insertion'))
        return
      }

      script.onload = () => {
        resolve()
        // After script is loaded, we need to wait until CelloAttribution is available on window and allow cookies
        const checkScriptLoaded = setInterval(() => {
          if (window.CelloAttribution) {
            if (cookieConsentData.value?.consented) {
              window.CelloAttribution('allowCookies')
            }
            clearInterval(checkScriptLoaded)
          }
        }, 100)
      }
      script.onerror = () => reject(new Error('Failed to load'))

      firstScript.parentNode.insertBefore(script, firstScript)
    })
  }

  nuxtApp.hook('app:mounted', () => {
    void loadScript().catch((error: Error) => {
      logError('Cello attribution: ', error)
    })
  })
})
