<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 160"
    width="140"
    height="160"
    enable-background="new 0 0 140 160"
    xml:space="preserve"
  >
    <defs>
      <path id="b" d="M0 0h79.1L100 19.843V120H0z" />
      <filter id="a" x="-31%" y="-24.2%" width="162%" height="151.7%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.156862745 0 0 0 0 0.243137255 0 0 0 0 0.4 0 0 0 0.2 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(20 18)">
        <use fill="#000" filter="url(#a)" xlink:href="#b" />
        <use fill="#FFF" xlink:href="#b" />
      </g>
      <path fill="#A2AABB" opacity=".3" d="M99 18l21 20H99z" />
      <path fill="#64728D" opacity=".8" d="M120 48L99 38h21z" />
      <path
        fill="#6E7B95"
        opacity=".5"
        d="M84.95 62v3h-49v-3zM84.95 93v3h-49v-3zM65.95 70v3h-30v-3zM65.95 101v3h-30v-3zM65.95 86v3h-30v-3zM84.95 78v3h-49v-3z"
      />
    </g>
  </svg>
</template>
