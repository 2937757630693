<script setup></script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4068_14587)">
      <path
        d="M15.4666 10.8667L13.4666 8.86667C13.2 8.6 12.8 8.6 12.5333 8.86667L8.53331 12.8667C8.39998 13 8.33331 13.1333 8.33331 13.3333V15.3333C8.33331 15.7333 8.59998 16 8.99998 16H11C11.2 16 11.3333 15.9333 11.4666 15.8L15.4666 11.8C15.7333 11.5333 15.7333 11.1333 15.4666 10.8667ZM10.7333 14.6667H9.66665V13.6L13 10.2667L14.0666 11.3333L10.7333 14.6667ZM2.53331 10.0667C3.53331 9.73333 5.19998 9.33333 6.99998 9.33333C9.26665 9.33333 11 6.8 11 4.66667V4C11 1.8 9.19998 0 6.99998 0C4.79998 0 2.99998 1.8 2.99998 4V4.66667C2.99998 5.93333 3.59998 7.26667 4.53331 8.2C3.59998 8.33333 2.73331 8.6 2.13331 8.8C1.06665 9.13333 0.333313 10.2 0.333313 11.3333V14C0.333313 14.4 0.59998 14.6667 0.99998 14.6667H6.33331C6.73331 14.6667 6.99998 14.4 6.99998 14C6.99998 13.6 6.73331 13.3333 6.33331 13.3333H1.66665V11.3333C1.66665 10.8 1.99998 10.2667 2.53331 10.0667ZM4.33331 4.66667V4C4.33331 2.53333 5.53331 1.33333 6.99998 1.33333C8.46665 1.33333 9.66665 2.53333 9.66665 4V4.66667C9.66665 6.2 8.46665 8 6.99998 8C5.53331 8 4.33331 6.2 4.33331 4.66667Z"
        fill="#65728E"
      />
    </g>
    <defs>
      <clipPath id="clip0_4068_14587">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
