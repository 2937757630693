<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M6,11H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S6.6,11,6,11z M12,7c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1s-1,0.4-1,1v1
  C11,6.6,11.4,7,12,7z M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2
  s10,4.5,10,10S17.5,22,12,22z M19,11h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1c0.6,0,1-0.4,1-1S19.6,11,19,11z M12,9c-0.5,0-0.9,0.1-1.3,0.3
  l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3,3C9.1,11.1,9,11.5,9,12c0,1.7,1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z M12,13c-0.6,0-1-0.4-1-1
  c0-0.3,0.1-0.5,0.3-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.2,0.4-0.3,0.7-0.3c0.6,0,1,0.4,1,1S12.6,13,12,13z M17.7,6.3
  c-0.4-0.4-1-0.4-1.4,0l-0.7,0.7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l0.7-0.7C18,7.4,18,6.7,17.7,6.3z"
    />
  </svg>
</template>
