const srRepository = (fetch: CustomFetch) => ({
  async get(id: string) {
    return fetch<SignatureRequestData>(`/v3/direct/signature-requests/${id}`)
  },
  async getEvents(id: string) {
    return fetch<SignatureRequestEvent[]>(`/v3/direct/signature-requests/${id}/events`)
  },
  async getDocumentsZip(signatureRequestId: string, token: string, isSigned?: boolean) {
    const params: Record<string, string> = {}
    params.token = token

    if (isSigned) params.signed = 'true'

    const response = await fetch.raw<Blob>(`/v3/direct/signature-requests/${signatureRequestId}/zip`, {
      query: params,
    })

    const data = response._data!
    const name = extractFilename(response)

    return {
      data,
      name: name ?? `${signatureRequestId}.zip`,
    }
  },
  async checkLock(signatureRequestId: string) {
    return fetch<{ locked: boolean }>(`/v3/direct/signature-requests/${signatureRequestId}/lock-status`)
  },
  async decline(id: string, message?: string) {
    return fetch<{ status: DocumentActionResult }>(`/v3/direct/signature-requests/${id}/decline`, {
      method: 'POST',
      body: { message },
    })
  },
})

export default srRepository
