<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,16.4c2.4,0,4.4-2,4.4-4.4s-2-4.4-4.4-4.4s-4.4,2-4.4,4.4S9.6,16.4,12,16.4z M14.8,12c0,1.6-1.3,2.8-2.8,2.8c-0.5,0-0.9-0.1-1.3-0.4l3.8-3.8C14.7,11.1,14.8,11.5,14.8,12z M12,9.2c0.5,0,0.9,0.1,1.3,0.4l-3.8,3.8c-0.2-0.4-0.4-0.9-0.4-1.3C9.2,10.4,10.4,9.2,12,9.2z M18.5,3.2H5.5C5,3.2,4.7,3.6,4.7,4v16c0,0.4,0.4,0.8,0.8,0.8h13.1c0.4,0,0.8-0.4,0.8-0.8V4C19.3,3.6,19,3.2,18.5,3.2z M17.7,19.2H6.3V4.8h11.5V19.2z"
    />
  </svg>
</template>
