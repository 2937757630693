<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header />
    <layout-3
      name="ssoerror"
      :title="$t('sso.error.title')"
      :subtitle="errorMessage"
      :image="{ src: 'sso-error', width: 688, height: 426 }"
    >
      <v-btn color="info" class="ma-0" size="x-large" :to="{ name: 'login' }">
        {{ $t('sso.error.button') }}
      </v-btn>
    </layout-3>

    <site-footer />
  </v-row>
</template>

<script lang="ts">
import Layout3 from '@/components/layouts/Layout3.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import SiteHeader from '@/components/SiteHeader.vue'

export default defineComponent({
  components: {
    SiteFooter,
    SiteHeader,
    Layout3,
  },
  provide() {
    return {
      headingBottomPaddingClass: 'pb-8',
    }
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['unauthenticated'],
      },
    })

    const { resetWidgets } = useWidgetStore()

    onBeforeMount(resetWidgets)
  },
  head() {
    return {
      title: 'SSO Error',
    }
  },
  computed: {
    errorMessage(): string {
      if ('public' in this.$route.query) {
        return this.$t('sso.error.subtitle.public_sso_new')
      } else {
        return this.$t('sso.error.subtitle.private_sso')
      }
    },
  },
})
</script>

<style scoped lang="sass"></style>
