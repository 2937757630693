<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22,18H7c-0.6,0-1,0.4-1,1v1.5c0,1.2-1.2,1.4-1.5,1.5C3.7,22,3,21.3,3,20.5V2h15v14c0,0.6,0.4,1,1,1s1-0.4,1-1V1
  c0-0.6-0.4-1-1-1H2C1.4,0,1,0.4,1,1v19.5C1,22.4,2.6,24,4.5,24h15c1.9,0,3.5-1.6,3.5-3.5V19C23,18.4,22.6,18,22,18z M21,20.5
  c0,0.8-0.7,1.5-1.5,1.5H7.7C7.9,21.6,8,21.1,8,20.5V20h13V20.5z M14,5H7C6.4,5,6,5.4,6,6s0.4,1,1,1h7c0.6,0,1-0.4,1-1S14.6,5,14,5z
   M14,13H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c0.6,0,1-0.4,1-1S14.6,13,14,13z M14,9H7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c0.6,0,1-0.4,1-1
  S14.6,9,14,9z"
    />
  </svg>
</template>
