type LegacyPlanId = 'ent_v2' | 'sme_v2'

// TODO: Casing is inconsistent, we should probably fix that
type FreePlanId = 'free_legacy' | 'free_v1' | 'free_migrated'

// This is a temporary convenience composable to help us determine what features
// the current user should be allowed to see, for both legacy and new users

export default function useUserPlanInfo() {
  const businessStore = useBusinessStore()
  const userStore = useUserStore()

  const businessPlanId = computed(() => businessStore.pricePlan.current.base?.type)

  const activePricePlanId = computed<PricePlanId | LegacyPlanId | FreePlanId | null>(() => {
    // We need to check for legacy stuff during pricing transition
    const legacyPlanId = (businessStore.pricePlan as { name?: LegacyPlanId }).name
    if (legacyPlanId) {
      return legacyPlanId
    }

    if (businessPlanId.value && businessPlanId.value !== 'CANCELED') {
      return businessPlanId.value
    }

    const freePlanId = userStore.attributes.free_account_type?.[0] as FreePlanId | undefined
    if (freePlanId) {
      return freePlanId
    }

    return null
  })

  const isLegacyPlan = computed(() => {
    return activePricePlanId.value === 'ent_v2' || activePricePlanId.value === 'sme_v2'
  })

  const isFreePlan = computed(() => {
    // FIXME: Currently, we cannot rely on the backend to consistently handle the `free_account_type` attribute
    return !businessPlanId.value && !userStore.isBusinessMember // && Boolean(activePricePlanId.value?.toLowerCase().startsWith('free_'))
  })

  const isNewFreePlan = computed(() => {
    // FIXME: Currently, we cannot rely on the backend to consistently handle the `free_account_type` attribute
    return (
      !businessPlanId.value && (activePricePlanId.value === 'free_v1' || activePricePlanId.value === 'free_migrated')
    )
  })

  const planStart = computed(() => {
    return businessStore.pricePlan.current.base?.startAt
  })

  return {
    activePricePlanId,
    isFreePlan,
    isLegacyPlan,
    isNewFreePlan,
    planStart,
  }
}
