<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 56"
    width="56"
    height="56"
    enable-background="new 0 0 56 56"
    xml:space="preserve"
  >
    <path
      d="M13.8,47.3H8.7v-7.5c0-1.1-0.9-2-2-2H4V28c0-1.5,1.2-2.7,2.7-2.7h2.4c1.1,0,2-0.9,2-2s-0.9-2-2-2H6.7C3,21.3,0,24.3,0,28v11.8c0,1.1,0.9,2,2,2h2.7v7.5c0,1.1,0.9,2,2,2h7.1c1.1,0,2-0.9,2-2S14.9,47.3,13.8,47.3z M49.3,21.3h-2.4c-1.1,0-2,0.9-2,2s0.9,2,2,2h2.4c1.5,0,2.7,1.2,2.7,2.7v9.8h-2.7c-1.1,0-2,0.9-2,2v7.5h-5.1c-1.1,0-2,0.9-2,2s0.9,2,2,2h7.1c1.1,0,2-0.9,2-2v-7.5H54c1.1,0,2-0.9,2-2V28C56,24.3,53,21.3,49.3,21.3z M46.9,18.2c3.7,0,6.7-3,6.7-6.7s-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7S43.2,18.2,46.9,18.2z M46.9,8.7c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7S45.4,8.7,46.9,8.7z M9.1,18.2c3.7,0,6.7-3,6.7-6.7s-3-6.7-6.7-6.7s-6.7,3-6.7,6.7S5.4,18.2,9.1,18.2z M9.1,8.7c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7S6.4,13,6.4,11.5S7.6,8.7,9.1,8.7z M41.8,42.2V28c0-3.7-3-6.7-6.7-6.7H20.9c-3.7,0-6.7,3-6.7,6.7v11.8c0,1.1,0.9,2,2,2h2.7V54c0,1.1,0.9,2,2,2h14.2c1.1,0,2-0.9,2-2v-9.8h2.7C40.9,44.2,41.8,43.3,41.8,42.2z M37.8,40.2h-2.7c-1.1,0-2,0.9-2,2V52H22.9V39.8c0-1.1-0.9-2-2-2h-2.7V28c0-1.5,1.2-2.7,2.7-2.7h14.2c1.5,0,2.7,1.2,2.7,2.7V40.2z M28,18.2c5,0,9.1-4.1,9.1-9.1S33,0,28,0c-5,0-9.1,4.1-9.1,9.1S23,18.2,28,18.2z M28,4c2.8,0,5.1,2.3,5.1,5.1s-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1S25.2,4,28,4z"
    />
  </svg>
</template>
