<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M23.1,5.9C22.6,5.3,21.8,5,21,5H3C2.2,5,1.4,5.3,0.9,5.9C0.3,6.4,0,7.2,0,8v12c0,0.8,0.3,1.6,0.9,2.1C1.4,22.7,2.2,23,3,23
  h18c0.8,0,1.6-0.3,2.1-0.9S24,20.8,24,20V8C24,7.2,23.7,6.4,23.1,5.9z M22,20c0,0.3-0.1,0.5-0.3,0.7S21.3,21,21,21H3
  c-0.3,0-0.5-0.1-0.7-0.3C2.1,20.5,2,20.3,2,20V8c0-0.3,0.1-0.5,0.3-0.7C2.5,7.1,2.7,7,3,7h18c0.3,0,0.5,0.1,0.7,0.3S22,7.7,22,8V20z
   M15.5,13.1l-5-3c-0.3-0.2-0.7-0.2-1,0S9,10.6,9,11v6c0,0.4,0.2,0.7,0.5,0.9C9.7,18,9.8,18,10,18c0.2,0,0.4,0,0.5-0.1l5-3
  c0.3-0.2,0.5-0.5,0.5-0.9S15.8,13.3,15.5,13.1z M11,15.2v-2.5l2.1,1.2L11,15.2z M5,3h14c0.6,0,1-0.4,1-1s-0.4-1-1-1H5
  C4.4,1,4,1.4,4,2S4.4,3,5,3z"
    />
  </svg>
</template>
