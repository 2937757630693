<template>
  <div class="mb-10">
    <skr-heading level="1">{{ $t('business.activity.title') }}</skr-heading>
    <skr-heading level="2">{{ $t('business.activity.subtitle') }}</skr-heading>
  </div>
  <v-tabs v-model="selectedTab" class="tabs">
    <v-tab class="tab" value="account" selected-class="tab--is-selected" :ripple="false">
      <span class="d-flex align-center space-x-2">
        <v-icon>custom:business_account</v-icon>
        <span>{{ $t('business.activity.tabs.account') }}</span>
      </span>
    </v-tab>
    <v-tab class="tab" value="signatures" selected-class="tab--is-selected" :ripple="false">
      <span class="d-flex align-center space-x-2">
        <v-icon color="currentColor">custom:signature</v-icon>
        <span>{{ $t('business.activity.tabs.signatures') }}</span>
      </span>
    </v-tab>
    <v-tab
      v-if="isIdentificationActive"
      class="tab"
      value="identifications"
      selected-class="tab--is-selected"
      :ripple="false"
      data-cy="identification_tab"
    >
      <span class="d-flex align-center space-x-2">
        <v-icon color="currentColor">custom:signature_standards</v-icon>
        <span>{{ $t('business.activity.tabs.identification') }}</span>
      </span>
    </v-tab>
  </v-tabs>
  <v-tabs-window v-model="selectedTab">
    <v-tabs-window-item value="account" :transition="false" :reverse-transition="false">
      <account-activity-table class="my-6" />
    </v-tabs-window-item>
    <v-tabs-window-item value="signatures" :transition="false" :reverse-transition="false">
      <div class="mt-6 mb-16 pa-3">
        <signature-usage-table />
      </div>
    </v-tabs-window-item>
    <v-tabs-window-item
      v-if="isIdentificationActive"
      value="identifications"
      :transition="false"
      :reverse-transition="false"
      ><div class="mt-6 mb-16 pa-3">
        <identification-usage-table />
      </div>
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script lang="ts">
import SignatureUsageTable from '@/components/billing/SignatureUsageTable.vue'
import AccountActivityTable from '@/components/activity/AccountActivityTable.vue'
import IdentificationUsageTable from '@/components/activity/IdentificationUsageTable.vue'
import SkrHeading from '@/components/SkrHeading.vue'

type Tab = 'account' | 'signatures' | 'identifications'

export default defineComponent({
  components: {
    IdentificationUsageTable,
    SkrHeading,
    AccountActivityTable,
    SignatureUsageTable,
  },
  setup() {
    definePageMeta({
      layout: 'admin',
      accessControl: {
        roles: ['admin'],
      },
    })

    const { isIdentificationActive } = storeToRefs(useBusinessStore())

    const selectedTab = ref<Tab>('account')

    const router = useRouter()
    const route = useRoute()

    watch(
      () => selectedTab.value,
      tab => {
        if (route.query.tab === tab) return
        void router.replace({ query: { tab } })
      }
    )

    onMounted(() => {
      let tab = route.query.tab
      if (!Number.isNaN(Number(tab))) {
        /* in case we have tabs like '0', '1', or '2' */
        tab = ['account', 'signatures', 'identifications'][Number(tab)]
      }
      selectedTab.value = (tab ?? 'account') as Tab
    })

    return {
      selectedTab,
      isIdentificationActive,
    }
  },
  head() {
    return {
      title: 'Business - Activity',
    }
  },
})
</script>

<style lang="sass" scoped>
.tabs
  --v-tabs-height: 56px
  :deep(.v-slide-group__container)
    .v-slide-group__content
      box-shadow: unset
      border-bottom: 1px solid $c-grey-fine
    .v-tab__slider
      border-radius: 4px 4px 0 0
      height: 4px
      color: $c-primary !important
    .tab
      font-size: 1rem
      border-top-left-radius: 8px
      border-top-right-radius: 8px
      color: $c-grey-dark !important
      &::after
        border-style: none !important
      &--is-selected
        color: $c-primary !important
        .v-tab__slider
          opacity: 1
      .v-icon
        color: inherit !important
      &:hover
        box-shadow: unset
        background: transparent !important
        &:not(.tab--is-selected)
          color: $c-grey-darker !important
        &::after
          background-color: $c-grey-light
          opacity: 0.12
</style>
