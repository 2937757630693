<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M22.1,15c-0.4-0.4-1-0.4-1.4,0l-2.3,2.3L16.1,15c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2.3,2.3L14.7,21c-0.4,0.4-0.4,1,0,1.4
  c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.3-2.3l2.3,2.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-2.3-2.3
  l2.3-2.3C22.5,16.1,22.5,15.4,22.1,15z M21,1.2H3c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h8c0.6,0,1-0.4,1-1s-0.4-1-1-1H3
  c-0.6,0-1-0.4-1-1v-5h21c0.6,0,1-0.4,1-1v-7C24,2.6,22.7,1.2,21,1.2z M22,10.2H2v-3h20V10.2z M22,5.2H2v-1c0-0.6,0.4-1,1-1h18
  c0.6,0,1,0.4,1,1V5.2z"
    />
  </svg>
</template>
