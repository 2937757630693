<script setup></script>

<template>
  <!-- Color hard coded to prevent opacity overwrites -->
  <svg
    fill="#DADEE6"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8
      s8,3.6,8,8S16.4,20,12,20z"
    />
  </svg>
</template>
