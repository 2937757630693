<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M13.7,12l4-4c0.5-0.5,0.5-1.2,0-1.7s-1.2-0.5-1.7,0l-4,4l-4-4c-0.5-0.5-1.2-0.5-1.7,0S5.9,7.6,6.4,8l4,4l-4,4
  c-0.5,0.5-0.5,1.2,0,1.7C6.6,17.9,6.9,18,7.2,18c0.3,0,0.6-0.1,0.8-0.4l4-4l4,4c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4
  c0.5-0.5,0.5-1.2,0-1.7L13.7,12z"
    />
  </svg>
</template>
