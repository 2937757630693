export function getIdentPath(legislation: Legislation, type: 'video' | 'self', isDirectSign = false) {
  const provider = legislation === 'eidas' ? 'srs' : 'intrum'
  let identifier = ''

  if (provider === 'srs') {
    if (type === 'video') {
      identifier = 'video'
    } else if (type === 'self') {
      identifier = 'robo'
    }
  } else if (provider === 'intrum') {
    if (type === 'video') {
      identifier = 'video'
    } else if (type === 'self') {
      identifier = 'autoident'
    }
  }

  return `/v1/${isDirectSign ? 'direct/' : ''}user/identification/${provider}/${identifier}`
}
