<script setup></script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5032_1849)">
      <path
        d="M18.4376 18.4376L13.2988 13.2988"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.4375 15.3125C12.2345 15.3125 15.3125 12.2345 15.3125 8.4375C15.3125 4.64054 12.2345 1.5625 8.4375 1.5625C4.64054 1.5625 1.5625 4.64054 1.5625 8.4375C1.5625 12.2345 4.64054 15.3125 8.4375 15.3125Z"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5032_1849">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
