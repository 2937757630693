<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 140 160"
    width="140"
    height="160"
    enable-background="new 0 0 140 160"
    xml:space="preserve"
  >
    <defs>
      <path id="b" d="M0 0h79.1L100 19.843V120H0z" />
      <filter id="a" x="-31%" y="-24.2%" width="162%" height="151.7%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.156862745 0 0 0 0 0.243137255 0 0 0 0 0.4 0 0 0 0.2 0" in="shadowBlurOuter1" />
      </filter>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(20 18)">
        <use fill="#000" filter="url(#a)" xlink:href="#b" />
        <use fill="#FFF" xlink:href="#b" />
      </g>
      <path fill="#A2AABB" opacity=".3" d="M99 18l21 20H99z" />
      <path fill="#64728D" opacity=".8" d="M120 48L99 38h21z" />
      <g fill="#B6BDCA" fill-rule="nonzero">
        <path
          d="M50 78h14a2 2 0 0 0 0-4H50a2 2 0 1 0 0 4zM50 90h14a2 2 0 0 0 0-4H50a2 2 0 1 0 0 4zM80 98H50a2 2 0 1 0 0 4h30a2 2 0 1 0 0-4zM50 66h30a2 2 0 1 0 0-4H50a2 2 0 1 0 0 4zM95.414 94.586l-7.08-7.08A9.937 9.937 0 0 0 90 82c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10a9.937 9.937 0 0 0 5.506-1.666l7.08 7.08c.39.39.902.586 1.414.586a1.998 1.998 0 0 0 1.414-3.414zM80 88a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
        />
      </g>
    </g>
  </svg>
</template>
