<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <path
      d="M5,18c0.6,0,1-0.4,1-1v-6c0-1.7,1.3-3,3-3h6.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5
    c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.5,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.3l-5-5c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L15.6,6H9
    c-2.8,0-5,2.2-5,5v6C4,17.6,4.4,18,5,18z"
    />
    <path d="M22,16c-0.6,0-1,0.4-1,1v4H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h20c0.6,0,1-0.4,1-1v-5C23,16.4,22.6,16,22,16z" />
  </svg>
</template>
