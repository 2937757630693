<template>
  <v-container class="fill-height">
    <v-row>
      <v-col cols="12" md="5">
        <div class="mb-12">
          <skr-heading level="1" semantic>{{ t('unlock_qes.legislation.title') }}</skr-heading>
          <skr-heading level="2">{{ t('unlock_qes.legislation.text_new') }}</skr-heading>
        </div>
        <div>
          <v-radio-group v-model="region" class="pt-0 mt-0 | sisale__radios" mandatory hide-details>
            <v-radio
              true-icon="custom:radio_button_active"
              false-icon="custom:radio_button_inactive"
              value="EU"
              class="mb-5 px-6 py-4 rounded-lg | sisale__radio"
            >
              <template #label>
                <div class="d-flex align-center justify-space-between w-100">
                  <div class="sisale__label">
                    <div :class="[{ 'mb-2': smAndUp }, 'sisale__radio-title']">
                      {{ t('unlock_qes.legislation.eu_label') }}
                    </div>
                    <div class="my-1 pr-lg-12 | sisale__radio-text">
                      {{ t('unlock_qes.legislation.eu_text_new') }}
                    </div>
                  </div>
                  <nuxt-img width="40" height="40" class="sisale__flag" src="/img/flags/EU.svg" />
                </div>
              </template>
            </v-radio>
            <v-radio
              true-icon="custom:radio_button_active"
              false-icon="custom:radio_button_inactive"
              value="CH"
              class="px-6 py-4 rounded-lg | sisale__radio"
            >
              <template #label>
                <div class="d-flex align-center justify-space-between w-100">
                  <div class="sisale__label">
                    <div :class="[{ 'mb-2': smAndUp }, 'sisale__radio-title']">
                      {{ t('unlock_qes.legislation.ch_label') }}
                    </div>
                    <div class="my-1 pr-lg-12 | sisale__radio-text">
                      {{ t('unlock_qes.legislation.ch_text_new') }}
                    </div>
                  </div>
                  <nuxt-img width="40" height="40" class="sisale__flag" src="/img/flags/CH.svg" />
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row class="mt-4 mb-0">
            <v-col cols="auto" class="flex-grow-1">
              <a class="link fwn" :href="t('unlock_qes.legislation.help_neither.url')" target="_blank" rel="noopener">
                <small>
                  {{ t('unlock_qes.legislation.help_neither.text') }}
                </small>
              </a>
            </v-col>
            <v-col cols="auto">
              <a class="link fwn" :href="t('unlock_qes.legislation.help.url')" target="_blank" rel="noopener">
                <small>{{ t('unlock_qes.legislation.help.text') }}</small>
              </a>
            </v-col>
          </v-row>
        </div>
        <skr-button-group size="xl" :stacked="xs" :reversed="xs" filled>
          <skr-button data-cy="back_button" type="secondary" @click="router.back()">
            {{ t('global.button.go_back') }}
          </skr-button>
          <skr-button :disabled="!region" @click="goToSelectedIdentification">
            {{ t('global.button.next') }}
          </skr-button>
        </skr-button-group>
      </v-col>
      <v-col v-if="mdAndUp" cols="12" md="7">
        <responsive-image source="holding-pen-and-signing" :width="621" :height="504" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrButton from '@/components/button/SkrButton.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrHeading from '@/components/SkrHeading.vue'

export default defineComponent({
  components: {
    ResponsiveImage,
    SkrButton,
    SkrButtonGroup,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const { smAndUp, mdAndUp, xs } = useDisplay()

    const router = useRouter()

    return {
      t,
      smAndUp,
      mdAndUp,
      xs,
      router,
    }
  },
  data() {
    return {
      region: '',
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Legislation',
    }
  },
  methods: {
    goToSelectedIdentification() {
      if (this.region === 'EU') {
        void navigateTo({
          name: 'profile-signature-standards-eidas',
        })
      } else if (this.region === 'CH') {
        void navigateTo({
          name: 'profile-signature-standards-zertes',
        })
      }
    },
  },
})
</script>

<style lang="sass">
.sisale
  &__radio
    +skribble-box-shadow
    width: 100%
    border: 2px solid transparent
    &.v-selection-control--dirty
      border-color: $c-primary
    > .v-selection-control__wrapper
      padding-right: 15px
    > .v-label
      flex: 1

    &-title
      font-size: 1.25rem
      font-weight: bold
      color: $c-skribbleu
      +maw(xs)
        font-size: 1.125rem

    &-text
      font-size: 1rem
      +maw(xs)
        font-size: 0.875rem

  &__flag
    width: 40px
    height: 40px
    border-radius: 3px
    object-fit: cover
</style>
