<template>
  <v-container class="fill-height">
    <v-row v-if="data.loading" align="center" justify="center">
      <v-progress-circular :size="70" color="text" indeterminate />
    </v-row>
    <v-row v-else-if="data.verificationFailed" align="center">
      <two-cols-block class="fill-height" split="5/7">
        <template #left>
          <div class="mb-12">
            <skr-heading level="1" semantic>{{ $t('signup.confirm.error.title') }}</skr-heading>
            <skr-heading level="2">{{ $t('signup.confirm.error.subtitle') }}</skr-heading>
          </div>
          <v-btn :to="data.ctaRoute" class="ma-0" color="info" size="large" block>
            {{ $t('signup.confirm.error.cta') }}
          </v-btn>
        </template>
        <template v-if="$vuetify.display.mdAndUp" #right>
          <responsive-image source="email-link-expired" :width="340" :height="440" />
        </template>
      </two-cols-block>
    </v-row>
    <v-row v-else class="fill-height" align="center">
      <two-cols-block class="fill-height" split="5/7">
        <template #left>
          <div class="mb-12">
            <skr-heading level="1" semantic>{{ $t('signup.fairflat.confirm.title') }}</skr-heading>
            <skr-heading level="2" class="whitespace-pre-wrap">{{ $t('signup.fairflat.confirm.text') }}</skr-heading>
          </div>
          <v-btn data-cy="complete_signup_button" :to="data.ctaRoute" class="ma-0" color="info" size="x-large" block>
            {{ $t('signup.confirm.complete') }}
          </v-btn>
        </template>
        <template v-if="$vuetify.display.mdAndUp" #right>
          <responsive-image source="welcome" :width="450" :height="389" />
        </template>
      </two-cols-block>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'

export default defineComponent({
  components: {
    SkrHeading,
    ResponsiveImage,
    TwoColsBlock,
  },
  setup() {
    definePageMeta({
      layout: 'unauthenticated',
      unauthenticated: {
        login: false,
      },
      accessControl: {
        roles: ['unauthenticated'],
      },
    })

    const route = useRoute()

    const { language } = storeToRefs(useLanguageStore())

    const { userRepository } = useApi()

    const data = reactive({
      loading: true,
      ctaRoute: { name: '' },
      verificationFailed: false,
    })
    const confirm = async () => {
      // we saw some issues with undefined tokens at end of signup which was most
      // likely because we set the value based on query parameter s. it might
      // have happened that this parameter was only read after the user clicked
      // on continue, changed the route and therefore had no longer access to s.
      // we now store it in a variable and won't display anything until
      // everything is properly loaded.
      const token = route.query.s

      // verify specified token
      try {
        if (!token || typeof token !== 'string') throw new Error('No token provided')
        localStorage.setItem('signup-token', token.toString())

        const result = await userRepository.verifyConfirmationToken(token, language.value)
        // token was already stored before checking its validity
        localStorage.setItem('signup-email', result.email)
        if (result.flow === 'business') localStorage.setItem('business-invite', 'true')
        data.ctaRoute = { name: 'signup-steps' }
        data.loading = false
      } catch (error: unknown) {
        const e = error as { response?: { status: number } }
        // there was a problem, most likely the token timed out
        // or an invalid token was sent, so display an error
        // remove previously set token in error case
        localStorage.removeItem('signup-token')
        if (e.response?.status === 410) {
          await navigateTo({ name: 'expired' })
        } else {
          data.verificationFailed = true
          data.ctaRoute = { name: 'signup' }
        }
        data.loading = false
      }
    }

    onMounted(() => {
      void confirm()
    })

    return { data }
  },
})
</script>

<style scoped lang="sass">
.confirm
  +center-content
  +add-focussed-form-layout
  +add-lateral-site-padding
  +add-vertical-main-padding
</style>
