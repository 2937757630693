<script setup></script>

<template>
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    enable-background="new 0 0 32 32"
    xml:space="preserve"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16,1C7.7,1,1,7.7,1,16c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C31,7.7,24.3,1,16,1
  z M23.6,13.4l-7.9,8.2c-0.3,0.3-0.8,0.5-1.3,0.5h0h0c-0.5,0-0.9-0.2-1.3-0.5l-4.1-4.1c-0.7-0.7-0.7-1.8,0-2.5
  c0.3-0.3,0.8-0.5,1.3-0.5h0c0.5,0,0.9,0.2,1.3,0.5l2.8,2.8L21,11c0.7-0.7,1.8-0.7,2.5,0C24.3,11.6,24.3,12.7,23.6,13.4z"
    />
  </svg>
</template>
