const signingRepository = (fetch: CustomFetch) => ({
  async sign(signData: SignData, language: Language = 'en', tan?: string) {
    return this.batchSign([signData], language, tan)
  },
  async batchSign(signData: SignData[], language: Language = 'en', tan?: string) {
    const payload = signData.map(({ id, documentSignatures, signingName, signingReason }) => ({
      signatureRequestId: id,
      signingName,
      signingReason,
      documentSignatures,
    }))

    return fetch<SignResponse>('/v3/sign', {
      method: 'POST',
      body: objectToSnakeCase({
        signatureRequests: payload,
        language,
        ...(Boolean(tan) && { tan }),
      }),
    })
  },
  async continueSign(continuationId: string, isPwdotp = false) {
    return fetch<SignResponse>(`/v3/swisscom/proceed/${continuationId}`, {
      method: 'POST',
      body: { pwdotp: isPwdotp },
    })
  },
  async seal(sealData: SignData, sealAccountName: string) {
    return this.batchSeal([sealData], sealAccountName)
  },
  async batchSeal(sealData: SignData[], sealAccountName: string) {
    const payload = sealData.map(({ id, documentSignatures }) => ({
      signatureRequestId: id,
      documentSignatures,
    }))

    return fetch<SignResponse>('/v3/sign', {
      method: 'POST',
      body: objectToSnakeCase({
        signatureRequests: payload,
        sealAccountName,
      }),
    })
  },
})

export default signingRepository
