type AuthenticationType = 'Bearer' | 'Basic'

interface AuthData {
  token: {
    value: string
    expiration: number
  }
  authenticationType: AuthenticationType
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthData => ({
    token: {
      value: '',
      expiration: 0,
    },
    authenticationType: 'Bearer',
  }),
  actions: {
    /**
     * Stores the token in the session storage.
     *
     * @param token
     * @param type
     * @param stayLoggedIn
     */
    storeToken(token: string, type: AuthenticationType = 'Bearer', stayLoggedIn = false) {
      const lifetime = stayLoggedIn ? 172800000 : 86400000

      const tokenData = {
        value: token,
        expiration: Date.now() + lifetime,
      }

      this.token = tokenData
      this.authenticationType = type

      const stringified = JSON.stringify(tokenData)

      sessionStorage.setItem('accessToken', stringified)
    },
    getStoredToken() {
      const stringified = sessionStorage.getItem('accessToken')

      if (!stringified) {
        // We may still be logged in in another tab
        const { $auth } = useNuxtApp()
        $auth.surveySession()
        return null
      }

      const token = JSON.parse(stringified) as { expiration: number; value: string }

      if (token.expiration > Date.now()) {
        this.token = token
        return token.value
      } else {
        return null
      }
    },
    removeStoredToken() {
      sessionStorage.removeItem('accessToken')

      this.$reset()
    },
  },
})
